import React from "react";
import { Badge } from "@/components/Common/Badge";
import { ScrollArea } from "@/components/Common/Scroll";

import { Tag } from "lucide-react";

interface TagsListProps {
  tags: Array<{
    name: string;
    count: number;
  }>;
  selectedTag: string | null;
  onSelectTag: (tag: string | null) => void;
}

const TagsList: React.FC<TagsListProps> = ({
  tags,
  selectedTag,
  onSelectTag,
}) => {
  console.log("tags", tags);
  return (
    <ScrollArea className="h-[calc(100vh-12rem)]">
      <div className="pr-4 space-y-2">
        <div className="flex items-center gap-2 text-sm text-gray-500 mb-4">
          <Tag className="w-4 h-4" />
          <div className="flex items-center justify-between flex-1">
            <span>Tags</span>
            <span className="text-xs bg-gray-100 dark:bg-gray-800 px-2 py-0.5 rounded-full">
              {tags.length}
            </span>
          </div>
        </div>

        {selectedTag && (
          <button
            onClick={() => onSelectTag(null)}
            className="w-full text-left px-3 py-2 rounded-md text-sm text-blue-600 hover:bg-blue-50 dark:hover:bg-blue-900/30 transition-colors mb-2"
          >
            Clear selection
          </button>
        )}

        {tags.map(({ name, count }) => (
          <button
            key={name}
            onClick={() => onSelectTag(name)}
            className={`w-full text-left px-3 py-2 rounded-md text-sm transition-colors ${
              selectedTag === name
                ? "bg-blue-50 text-blue-700 dark:bg-blue-900/30 dark:text-blue-300"
                : "hover:bg-gray-100 dark:hover:bg-gray-800"
            }`}
          >
            <div className="flex items-center justify-between">
              <span className="truncate">{name}</span>
              <Badge
                variant="secondary"
                className={`text-xs ml-2 ${
                  selectedTag === name
                    ? "bg-blue-100 text-blue-700"
                    : "bg-gray-100 text-gray-600"
                }`}
              >
                {count}
              </Badge>
            </div>
          </button>
        ))}
      </div>
    </ScrollArea>
  );
};

export default TagsList;
