import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { CheckCircle, XCircle, Loader2, User, ArrowRight } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/Common/Card";
import { Button } from "@/components/Common/Button";
import { Input } from "@/components/Common/Input";
import { Alert, AlertTitle, AlertDescription } from "@/components/Common/Alert";
import { authService } from "@/services/authService";
import { Progress } from "@/components/Common/ProgressBar";

interface InvitationDetails {
  organization: {
    name: string;
    id: string;
  };
  role: string;
  email: string;
}

const InvitationPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<
    "validating" | "valid" | "error" | "accepted"
  >("validating");
  const [username, setUsername] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [inviteDetails, setInviteDetails] = useState<InvitationDetails | null>(
    null
  );
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    validateInvitation();
  }, [token]);

  // Progress bar effect after acceptance
  useEffect(() => {
    let interval: NodeJS.Timer;
    if (status === "accepted") {
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            navigate("/login");
            return prev;
          }
          return prev + 1;
        });
      }, 100); // 10 seconds total (100 * 100ms = 10000ms)
    }
    return () => clearInterval(interval);
  }, [status, navigate]);

  const validateInvitation = async () => {
    try {
      const response = await authService.validateInvitation(token!);
      setInviteDetails(response);
      setStatus("valid");
      setUsername(response.email.split("@")[0]);
    } catch (error: any) {
      setStatus("error");
      setError(error.message || "Invalid or expired invitation");
    }
  };

  const handleAccept = async () => {
    try {
      setIsSubmitting(true);
      await authService.acceptInvitation(token!, username);
      setStatus("accepted");
      setProgress(0);
    } catch (error: any) {
      setError(error.message || "Failed to accept invitation");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="w-full max-w-md"
      >
        <Card>
          <CardHeader>
            <CardTitle className="text-2xl text-center">
              {status === "validating" && "Validating Invitation"}
              {status === "valid" && "Accept Invitation"}
              {status === "error" && "Invalid Invitation"}
              {status === "accepted" && "Account Created Successfully!"}
            </CardTitle>
          </CardHeader>

          <CardContent className="space-y-4">
            {status === "validating" && (
              <div className="flex flex-col items-center py-8">
                <Loader2 className="w-12 h-12 text-blue-500 animate-spin mb-4" />
                <p className="text-muted-foreground">
                  Validating your invitation...
                </p>
              </div>
            )}

            {status === "valid" && inviteDetails && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="space-y-6"
              >
                <div className="text-center space-y-2">
                  <p className="text-xl font-medium">
                    You've been invited to join
                  </p>
                  <p className="text-2xl font-bold text-blue-600">
                    {inviteDetails.organization.name}
                  </p>
                  <p className="text-muted-foreground">
                    as a {inviteDetails.role}
                  </p>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium">
                    Choose your username
                  </label>
                  <div className="relative">
                    <User className="absolute left-3 top-2.5 h-5 w-5 text-muted-foreground" />
                    <Input
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="pl-10"
                    />
                  </div>
                </div>
              </motion.div>
            )}

            {status === "error" && (
              <Alert variant="destructive">
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {status === "accepted" && (
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                className="flex flex-col items-center py-8 space-y-6"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <CheckCircle className="w-16 h-16 text-green-500" />
                </motion.div>
                <div className="text-center space-y-2">
                  <p className="text-lg font-medium">Your account is ready!</p>
                  <p className="text-sm text-muted-foreground">
                    Redirecting to login page in a few seconds...
                  </p>
                </div>

                {/* Progress bar */}
                <div className="w-full space-y-2">
                  <Progress value={progress} className="w-full" />
                  <p className="text-center text-sm text-muted-foreground">
                    {Math.round((10 - progress / 10) * 10) / 10}s
                  </p>
                </div>

                <Button
                  variant="outline"
                  onClick={() => navigate("/login")}
                  className="mt-4"
                >
                  Go to Login <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </motion.div>
            )}
          </CardContent>

          <CardFooter className="flex justify-end">
            {status === "valid" && (
              <Button
                onClick={handleAccept}
                disabled={isSubmitting || !username}
                className="w-full sm:w-auto"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Accepting...
                  </>
                ) : (
                  "Accept Invitation"
                )}
              </Button>
            )}
            {status === "error" && (
              <Button
                variant="outline"
                onClick={() => navigate("/login")}
                className="w-full sm:w-auto"
              >
                Back to Login
              </Button>
            )}
          </CardFooter>
        </Card>
      </motion.div>
    </div>
  );
};

export default InvitationPage;
