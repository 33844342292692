import React, { useState } from "react";
import { RelationshipGraph } from "./RelationshipGraph";
import { RelationshipPanel } from "./RelationshipPanel";
import { ProgressTracker } from "./ProgressTracker";
import { useRelationshipState } from "../../hooks/useRelationshipState";
import { Table, Relationship } from "../../types/types";
import { Card, CardContent } from "../Common/Card";
import { Button } from "../Common/Button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../Common/Tabs";
import { Input } from "../Common/Input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "../Common/Dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../Common/Tooltip";
import {
  AlertCircle,
  Database,
  GitMerge,
  Search,
  Settings,
  ZoomIn,
} from "lucide-react";

interface RelationshipMappingStepProps {
  tables: Table[];
  relationships: Relationship[];
  onAcceptRelationship: (relationship: Relationship) => void;
  onNext: () => void;
  onBack: () => void;
}

const RelationshipMappingStep: React.FC<
  RelationshipMappingStepProps
> = ({
  tables = [],
  relationships = [],
  onAcceptRelationship,
  onNext,
  onBack,
}) => {
  const {
    nodes,
    edges,
    currentTable,
    currentRelationships,
    reviewedRelationships,
    handleRelationshipAction,
    moveToNextTable,
    isAllCurrentRelationshipsReviewed,
    isAllReviewed,
    proceedWithoutReview,
  } = useRelationshipState(tables, relationships);

  const [searchTerm, setSearchTerm] = useState("");

  //TODO: Do we need this at somepoint?
  // const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const filteredRelationships = currentRelationships.filter((rel) =>
    rel.targetTable.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleProceedWithoutReview = () => {
    proceedWithoutReview();
    setShowConfirmDialog(false);
    handleNext();
  };

  const handleNext = () => {
    const acceptedRelationships = relationships.filter(
      (rel) => reviewedRelationships[`${rel.sourceTable}-${rel.targetTable}`] !== false
    );
    onAcceptRelationship(acceptedRelationships);
    onNext();
  };

  return (
    <div className="h-screen flex flex-col bg-gray-100">
      <Card className="m-4">
        <CardContent className="p-4">
          <ProgressTracker
            currentTable={tables.indexOf(currentTable) + 1}
            totalTables={tables.length}
            reviewedRelationships={Object.keys(reviewedRelationships).length}
            totalRelationships={relationships.length}
          />
        </CardContent>
      </Card>
      <div className="flex-grow flex p-4 space-x-4 overflow-hidden">
        <Card className="w-3/4">
          <CardContent className="p-4 h-full">
            <Tabs defaultValue="graph" className="h-full flex flex-col">
              <div className="flex justify-between items-center mb-4">
                <TabsList>
                  <TabsTrigger value="graph">Graph View</TabsTrigger>
                  <TabsTrigger value="erd">ERD View</TabsTrigger>
                </TabsList>
              </div>
              <TabsContent value="graph" className="flex-grow">
                <RelationshipGraph nodes={nodes} edges={edges} />
              </TabsContent>
              <TabsContent value="erd" className="flex-grow">
                {/* Implement ERD view here */}
                <div className="h-full flex items-center justify-center text-gray-500">
                  ERD View (Coming Soon)
                </div>
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
        <Card className="w-1/4 flex flex-col">
          <CardContent className="p-4 flex-grow overflow-hidden flex flex-col">
            <div className="mb-4 flex items-center space-x-2">
              <Database className="h-5 w-5 text-blue-500" />
              <h2 className="text-xl font-bold text-gray-800">
                {currentTable.name}
              </h2>
            </div>
            <div className="mb-4 relative">
              <Input
                type="text"
                placeholder="Search relationships..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
            <div className="flex-grow overflow-y-auto">
              <RelationshipPanel
                currentTable={currentTable}
                relationships={filteredRelationships}
                reviewedRelationships={reviewedRelationships}
                onAccept={(rel) => handleRelationshipAction(rel, true)}
                onReject={(rel) => handleRelationshipAction(rel, false)}
              />
            </div>
            <div className="mt-4 flex justify-between items-center">
              {isAllCurrentRelationshipsReviewed && !isAllReviewed && (
                <Button onClick={moveToNextTable}>Next Table</Button>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      <Card className="m-4">
        <CardContent className="p-4 flex justify-between">
          <Button onClick={onBack} variant="outline">
            Back
          </Button>
          <Dialog
            open={showConfirmDialog}
            onOpenChange={setShowConfirmDialog}
            className="bg-red-500"
          >
            <DialogTrigger asChild>
              <Button variant="outline">Proceed without review</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Confirm Action</DialogTitle>
              </DialogHeader>
              <div className="py-4">
                <div className="flex items-center text-amber-500 mb-4">
                  <AlertCircle className="mr-2 h-5 w-5" />
                  <p className="text-sm font-medium">
                    Are you sure you want to proceed without reviewing all
                    relationships?
                  </p>
                </div>
                <p className="text-sm text-gray-500">
                  It's recommended to review all relationships to ensure the
                  accuracy of your database schema.
                </p>
              </div>
              <DialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setShowConfirmDialog(false)}
                >
                  Cancel
                </Button>
                <Button onClick={handleProceedWithoutReview}>Proceed</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <span>
                  <Button onClick={handleNext} disabled={!isAllReviewed}>
                    Next: Review and Submit
                  </Button>
                </span>
              </TooltipTrigger>
              {!isAllReviewed && (
                <TooltipContent>
                  <AlertCircle className="h-4 w-4 inline-block mr-2" />
                  Review all relationships before proceeding
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        </CardContent>
      </Card>
    </div>
  );
};

export default RelationshipMappingStep;