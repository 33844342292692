import { ChatContext } from "@/components/Dashboard/Chat/useChat";
import { API_URL, axiosInstance } from "../config";
import { QueryMetadata } from "@/components/Dashboard/Insights/types";

interface Message {
  role: "system" | "user" | "assistant";
  content: string;
}

export const dataService = {
  chat: async (payload: {
    message: string;
    teamId: string;
    datasetId: string;
    conversationHistory: Message[];
    chatContext: ChatContext;
  }) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/api/v0/chat`, {
        ...payload,
      });
      return response.data;
    } catch (error) {
      const link = error?.response?.data?.authUrl;
      if (link) {
        window.location.href = link;
      }
      console.error("Error generating query:", error);
      throw error;
    }
  },
  generateQuery: async ({
    question,
    teamId,
    datasetId,
  }: {
    question: string;
    teamId: string;
    datasetId: string;
  }) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/generate`,
        { question }
      );
      return response.data;
    } catch (error) {
      console.error("Error generating query:", error);
      throw error;
    }
  },
  saveQuery: async ({
    teamId,
    datasetId,
    queryData,
  }: {
    teamId: string;
    datasetId: string;
    queryData: {
      name: string;
      query: string;
      tags: string[];
      relatedTables: string[];
      metadata: QueryMetadata | null;
    };
  }) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/save`,
        queryData
      );
      return response.data;
    } catch (error) {
      console.error("Error saving query:", error);
      throw error;
    }
  },
  getSavedQueries: async ({
    teamId,
    datasetId,
    page = 1,
    pageSize = 10,
  }: {
    teamId: string;
    datasetId: string;
    page?: number;
    pageSize?: number;
  }) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries`,
        {
          params: { page, pageSize },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching saved queries:", error);
      throw error;
    }
  },
  deleteSavedQuery: async ({
    teamId,
    queryId,
  }: {
    teamId: string;
    queryId: string;
  }) => {
    try {
      const response = await axiosInstance.delete(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/${queryId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting query:", error);
      throw error;
    }
  },
  duplicateSavedQuery: async ({
    teamId,
    queryId,
  }: {
    teamId: string;
    queryId: string;
  }) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/${queryId}/duplicate`
      );
      return response.data;
    } catch (error) {
      console.error("Error duplicating query:", error);
      throw error;
    }
  },
  executeQuery: async ({
    teamId,
    datasetId,
    queryId,
    query,
  }: {
    teamId: string;
    datasetId: string;
    queryId: string;
    query: string;
  }) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/${queryId}/execute`,
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Error executing query:", error);
      throw error;
    }
  },
};

// getTables: async () => {
//   try {
//     const response = await axios.get(`${API_URL}/api/v0/tables`, {
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error getting tables:", error);
//     throw error;
//   }
// },

// getDataSources: async () => {
//   try {
//     const response = await axios.get(`${API_URL}/api/v1/data-sources`, {
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error getting data sources:", error);
//     throw error;
//   }
// },

// addDataSource: async (dataSourceData: any) => {
//   try {
//     const response = await axios.post(
//       `${API_URL}/api/v1/data-sources`,
//       dataSourceData,
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error adding data source:", error);
//     throw error;
//   }
// },

// getModelSettings: async () => {
//   try {
//     const response = await axios.get(`${API_URL}/api/v1/model-settings`, {
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error getting model settings:", error);
//     throw error;
//   }
// },

// updateModelSettings: async (settingsData: any) => {
//   try {
//     const response = await axios.put(
//       `${API_URL}/api/v1/model-settings`,
//       settingsData,
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error updating model settings:", error);
//     throw error;
//   }
// },
