import React from "react";
import { LogOut, Settings } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import ThemeToggle from "../Common/ThemeToggle";
import logo from "@/assets/logo.png";

import { authService } from "@/services/authService";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/Common/DropDown";
import { useTeam } from "@/context/TeamContext";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { userId, clearUserDataFromStore } = useTeam();

  const handleLogout = async () => {
    try {
      await authService.logout();
      clearUserDataFromStore();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <header className="bg-white dark:bg-gray-800 shadow-md">
      <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="WaveQuery Logo" className="h-6 w-auto mr-2" />
        </Link>
        <div className="flex items-center gap-4">
          <ThemeToggle />
          {userId && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Settings color="#000000" className="h-4 w-4 cursor-pointer" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="bg-white">
                <DropdownMenuItem
                  className="text-red-600  dark:text-red-400 cursor-pointer"
                  onClick={handleLogout}
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Logout</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
