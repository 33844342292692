import React, { useState, useEffect, useMemo } from "react";
import { Search, Filter, Tag, ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "@/components/Common/Button";
import { Input } from "@/components/Common/Input";
import { dataService } from "@/services/dataService";
import QueryCard from "./QueryCard";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import type { SavedQuery, QueryCategory } from "./types";
import AutomationBanner from './Banner';
import TagsList from "./TagsList";
import {getPaginationRange} from '../../../utils/formatters';

const pageSize = 20;

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex items-center justify-start space-x-1  dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 p-1">
    <Button
      variant="ghost"
      size="sm"
      onClick={() => onPageChange(1)}
      disabled={currentPage === 1}
      className="hidden sm:flex"
    >
      First
    </Button>
    <Button
      variant="ghost"
      size="sm"
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
    >
      <ChevronLeft className="w-4 h-4" />
    </Button>
    <div className="flex items-center gap-1">
      {[...Array(Math.min(5, totalPages))].map((_, i) => {
        const page = getPaginationRange(currentPage, totalPages)[i];
        return (
          <Button
            key={page}
            variant={currentPage === page ? "default" : "ghost"}
            size="sm"
            onClick={() => onPageChange(page)}
            className="w-8"
          >
            {page}
          </Button>
        );
      })}
    </div>
    <Button
      variant="ghost"
      size="sm"
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
    >
      <ChevronRight className="w-4 h-4" />
    </Button>
    <Button
      variant="ghost"
      size="sm"
      onClick={() => onPageChange(totalPages)}
      disabled={currentPage === totalPages}
      className="hidden sm:flex"
    >
      Last
    </Button>
  </div>
);

const Insights = ({ teamId, datasetId }) => {
  const [queries, setQueries] = useState<SavedQuery[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [activeQuery, setActiveQuery] = useState<string | null>(null);
  const [deleteDialog, setDeleteDialog] = useState<{
    isOpen: boolean;
    query: SavedQuery | null;
  }>({
    isOpen: false,
    query: null,
  });
  const [duplicateDialog, setDuplicateDialog] = useState<{
    isOpen: boolean;
    query: SavedQuery | null;
  }>({
    isOpen: false,
    query: null,
  });

  // Compute categories based on tags
  const categories = useMemo(() => {
    const tagGroups = queries.reduce((acc, query) => {
      query.tags.forEach((tag) => {
        if (!acc[tag]) acc[tag] = 0;
        acc[tag]++;
      });
      return acc;
    }, {} as Record<string, number>);

    return Object.entries(tagGroups)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 8)
      .map(([tag, count]) => ({
        name: tag,
        tags: [tag],
        count,
      }));
  }, [queries]);

  // Filter queries based on search and category
  const filteredQueries = useMemo(() => {
    return queries.filter((query) => {
      const matchesSearch =
        searchTerm === "" ||
        query.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        query.tags.some((tag) =>
          tag.toLowerCase().includes(searchTerm.toLowerCase())
        );

      const matchesCategory =
        !selectedCategory || query.tags.includes(selectedCategory);

      return matchesSearch && matchesCategory;
    });
  }, [queries, searchTerm, selectedCategory]);

  // Handlers
  const handleRunQuery = async (query: SavedQuery) => {
    setActiveQuery(query.id);
    // Implement query execution
    setActiveQuery(null);
  };

  const handleDeleteQuery = async (query: SavedQuery) => {
    setDeleteDialog({ isOpen: true, query });
  };

  const handleConfirmDelete = async () => {
    if (!deleteDialog.query) return;
    await dataService.deleteSavedQuery({
      teamId,
      queryId: deleteDialog.query.id,
    });
    fetchQueries();
  };

  const handleDuplicateQuery = async (query: SavedQuery) => {
    setDuplicateDialog({ isOpen: true, query });
  };

  const handleConfirmDuplicate = async () => {
    if (!duplicateDialog.query) return;
    await dataService.duplicateSavedQuery({
      teamId,
      queryId: duplicateDialog.query.id,
    });
    fetchQueries();
  };

  const handleTagSelect = (tag: string | null) => {
    // If clicking the same tag, clear the selection
    if (tag === selectedCategory) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(tag);
    }
    // Reset to first page when changing tags
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchQueries();
  }, [currentPage, searchTerm, selectedCategory]);

  const fetchQueries = async () => {
    try {
      setIsLoading(true);
      const response = await dataService.getSavedQueries({
        teamId,
        datasetId,
        page: currentPage,
        pageSize,
      });
      setQueries(response.queries);
      setTotalPages(response.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching queries:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-full">
      <AutomationBanner />
      
      <div className="flex gap-6">
        {/* Left Sidebar */}
        <div className="w-56 flex-shrink-0">
          <TagsList
            tags={categories}
            selectedTag={selectedCategory}
            onSelectTag={handleTagSelect}
            counts={categories}
          />
        </div>

        {/* Main Content */}
        <div className="flex-1 space-y-4">
          <div className="flex items-center justify-between">
            <div className="relative flex-1 max-w-md">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
              <Input
                placeholder="Search queries..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-9"
              />
            </div>
          </div>

          <div className="space-y-3">
            {isLoading ? (
              [...Array(6)].map((_, i) => (
                <div key={i} className="animate-pulse">
                  <div className="h-20 bg-gray-200 dark:bg-gray-700 rounded-lg" />
                </div>
              ))
            ) : (
              filteredQueries.map((query) => (
                <QueryCard
                  key={query.id}
                  query={query}
                  onRun={handleRunQuery}
                  onTagClick={handleTagSelect}
                  onDelete={handleDeleteQuery}
                  onDuplicate={handleDuplicateQuery}
                  isActive={activeQuery === query.id}
                />
              ))
            )}
          </div>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>

      <ConfirmationDialog
        isOpen={deleteDialog.isOpen}
        onClose={() => setDeleteDialog({ isOpen: false, query: null })}
        onConfirm={handleConfirmDelete}
        title="Delete Query"
        message="Are you sure you want to delete this query? This action cannot be undone."
        confirmText="Delete"
        type="danger"
      />

      <ConfirmationDialog
        isOpen={duplicateDialog.isOpen}
        onClose={() => setDuplicateDialog({ isOpen: false, query: null })}
        onConfirm={handleConfirmDuplicate}
        title="Duplicate Query"
        message="Would you like to create a copy of this query?"
        confirmText="Duplicate"
        type="info"
      />

 
    </div>
  );
};

export default Insights;
