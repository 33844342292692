import React from "react";
import { cn } from "../utils";

const Label = React.forwardRef<
  HTMLLabelElement,
  React.LabelHTMLAttributes<HTMLLabelElement>
>(({ className, ...props }, ref) => (
  <label
    ref={ref}
    className={cn(
      "text-sm font-medium leading-none text-gray-700 dark:text-gray-300",
      className
    )}
    {...props}
  />
));

Label.displayName = "Label";

export { Label };