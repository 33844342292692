import { API_URL, axiosInstance } from "../config";

interface ConnectionConfig {
  type: "postgres";
  host: string;
  port: number;
  database: string;
  username: string;
  password: string;
  sslMode: string;
}

interface SchemaAnalysisResult {
  tables: Array<{
    name: string;
    columns: Array<{
      name: string;
      type: string;
      isPrimary: boolean;
      isForeign: boolean;
      isNullable: boolean;
      defaultValue?: string;
    }>;
  }>;
  relationships: Array<{
    sourceTable: string;
    targetTable: string;
    sourceColumn: string;
    targetColumn: string;
  }>;
  junctionTables: string[];
  views: Array<{
    name: string;
    definition: string;
  }>;
}

export const playgroundService = {
  analyzeSchema: async (config: ConnectionConfig) => {
    try {
      const response = await axiosInstance.post<SchemaAnalysisResult>(
        `${API_URL}/api/v0/playground/analyze`,
        config
      );

      return response.data;
    } catch (error) {
      // Handle specific error cases that might occur during schema analysis
      if (error.response?.status === 401) {
        throw new Error(
          "Authentication failed. Please check your database credentials."
        );
      }

      if (error.response?.status === 403) {
        throw new Error("You don't have permission to access this database.");
      }

      if (error.response?.status === 429) {
        throw new Error("Too many requests. Please try again later.");
      }

      // Log the error for debugging purposes
      console.error("Error analyzing database schema:", error);

      // Throw a more user-friendly error
      throw new Error(
        error.response?.data?.message ||
          "Failed to analyze database schema. Please try again or contact support."
      );
    }
  },
  validateConnection: async (config: ConnectionConfig) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/playground/validate-connection`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("Error validating connection:", error);
      throw error;
    }
  },
  getSampleDatabases: async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/api/v0/playground/sample-databases`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching sample databases:", error);
      throw error;
    }
  },
};
