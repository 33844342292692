import React from "react";
import {
  Toaster as ToasterComponent,
  toast as hotToast,
} from "react-hot-toast";
import { CheckCircle2, XCircle, AlertCircle } from "lucide-react";

import { useTheme } from "@/hooks/useTheme";

export const Toaster = () => {
  return (
    <ToasterComponent
      position="top-right"
      // containerStyle={"bg-red"}
      containerClassName={"bg-red"}
      toastOptions={{
        duration: 2000,
        className: "!bg-transparent !border-0 !shadow-none !p-0",
        style: {
          background: "transparent",
          boxShadow: "none",
          padding: 0,
          border: "none",
        },
        success: {
          iconTheme: {
            primary: "rgb(34, 197, 94)",
            secondary: "white",
          },
        },
        error: {
          iconTheme: {
            primary: "rgb(239, 68, 68)",
            secondary: "white",
          },
          duration: 6000,
        },
        loading: {
          iconTheme: {
            primary: "rgb(59, 130, 246)",
            secondary: "white",
          },
        },
      }}
    />
  );
};

export const toast = {
  success: (message: string) =>
    hotToast.custom((t) => (
      <div
        className={`${
          t.visible
            ? "animate-[slide-in_0.3s_ease-out]"
            : "animate-[slide-out_0.3s_ease-in]"
        } flex items-center gap-2 bg-slate-100 dark:bg-gray-800 p-4 rounded-lg shadow-md border border-gray-200 dark:border-gray-700`}
      >
        <CheckCircle2 className="w-5 h-5 text-green-500" />
        <span className="text-gray-800 dark:text-gray-200">{message}</span>
      </div>
    )),
  error: (message: string) =>
    hotToast.custom((t) => (
      <div
        className={`${
          t.visible
            ? "animate-[slide-in_0.3s_ease-out]"
            : "animate-[slide-out_0.3s_ease-in]"
        } flex items-center gap-2 bg-slate-100 dark:bg-gray-800 p-4 rounded-lg shadow-md border border-gray-200 dark:border-gray-700`}
      >
        <XCircle className="w-5 h-5 text-red-500" />
        <span className="text-gray-800 dark:text-gray-200">{message}</span>
      </div>
    )),
  warning: (message: string) =>
    hotToast.custom((t) => (
      <div
        className={`${
          t.visible
            ? "animate-[slide-in_0.3s_ease-out]"
            : "animate-[slide-out_0.3s_ease-in]"
        } flex items-center gap-2 bg-slate-100 dark:bg-gray-800 p-4 rounded-lg shadow-md border border-gray-200 dark:border-gray-700`}
      >
        <AlertCircle className="w-5 h-5 text-yellow-500" />
        <span className="text-gray-800 dark:text-gray-200">{message}</span>
      </div>
    )),
  promise: async <T,>(
    promise: Promise<T>,
    messages: {
      loading: string;
      success: string;
      error: string;
    }
  ) => {
    return hotToast.promise(promise, {
      loading: messages.loading,
      success: messages.success,
      error: messages.error,
    });
  },
};
