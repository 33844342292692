import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Common/Dialog";
import { Button } from "@/components/Common/Button";
import { CodeEditor } from "@/components/Common/CodeEditor";
import { Loader2, AlertCircle, CheckCircle2, XCircle } from "lucide-react";
import {
  formatBytes,
  formatCurrency,
  formatDuration,
} from "../../../utils/formatters";
import { useTheme } from "@/hooks/useTheme";
import { dataService } from "@/services/dataService";

interface QueryExecutionDialogProps {
  query: any;
  isOpen: boolean;
  onClose: () => void;
  teamId: string;
  datasetId: string;
}

const QueryExecutionDialog: React.FC<QueryExecutionDialogProps> = ({
  query,
  isOpen,
  onClose,
  teamId,
  datasetId,
}) => {
  const theme = useTheme();

  const [executionState, setExecutionState] = useState<
    "idle" | "executing" | "completed" | "error"
  >("idle");
  const [executionResult, setExecutionResult] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      handleExecuteQuery();
    }
  }, [isOpen]);

  const handleExecuteQuery = async () => {
    try {
      setExecutionState("executing");
      setError(null);

      const result = await dataService.executeQuery({
        teamId,
        datasetId,
        queryId: query.id,
        query: query.query,
      });

      setExecutionResult(result);
      setExecutionState("completed");
    } catch (err: any) {
      setError(err.message || "Failed to execute query");
      setExecutionState("error");
    }
  };

  const renderExecutionStatus = () => {
    switch (executionState) {
      case "executing":
        return (
          <div className="flex items-center gap-2 text-blue-500">
            <Loader2 className="w-5 h-5 animate-spin" />
            <span>Executing query...</span>
          </div>
        );
      case "completed":
        return (
          <div className="flex items-center gap-2 text-green-500">
            <CheckCircle2 className="w-5 h-5" />
            <span>Query executed successfully</span>
          </div>
        );
      case "error":
        return (
          <div className="flex items-center gap-2 text-red-500">
            <XCircle className="w-5 h-5" />
            <span>{error}</span>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[920px] w-[90vw]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            Execute Query: {query.name}
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          {/* Query Preview */}
          <div className="rounded-lg border border-gray-200 dark:border-gray-700 p-4">
            <h3 className="text-sm font-medium mb-2">Query</h3>
            <CodeEditor
              value={query.query}
              language="sql"
              sqlDialect="bigquery"
              theme={theme.isDarkMode ? "vs-dark" : "light"}
              readOnly={true}
              height="200px"
            />
          </div>

          {/* Execution Status */}
          <div className="rounded-lg border border-gray-200 dark:border-gray-700 p-4">
            {renderExecutionStatus()}
          </div>

          {/* Execution Results */}
          {executionState === "completed" && executionResult && (
            <div className="space-y-4">
              {/* Metrics */}
              <div className="grid grid-cols-3 gap-4">
                <div className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                  <div className="text-sm text-gray-500 mb-1">
                    Execution Time
                  </div>
                  <div className="font-medium">
                    {formatDuration(executionResult.metadata.executionTime)}
                  </div>
                </div>
                <div className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                  <div className="text-sm text-gray-500 mb-1">
                    Data Processed
                  </div>
                  <div className="font-medium">
                    {formatBytes(
                      executionResult.metadata.estimatedBytesProcessed
                    )}
                  </div>
                </div>
                <div className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                  <div className="text-sm text-gray-500 mb-1">Cost</div>
                  <div className="font-medium">
                    {formatCurrency(executionResult.metadata.estimatedCost)}
                  </div>
                </div>
              </div>

              {/* Results Preview */}
              <div className="rounded-lg border border-gray-200 dark:border-gray-700 p-4">
                <h3 className="text-sm font-medium mb-2">Results Preview</h3>
                <div className="max-h-[300px] overflow-auto">
                  <pre className="text-sm">
                    {JSON.stringify(executionResult.result, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
          )}

          {/* Footer */}
          <div className="flex justify-end gap-2 pt-4">
            <Button onClick={onClose}>
              Close
            </Button>
            {executionState !== "executing" && (
              <Button variant="outline" onClick={handleExecuteQuery}>Run Again</Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default QueryExecutionDialog;
