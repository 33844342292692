import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaNpm, FaBook, FaBeer } from "react-icons/fa";
import { AlertCircle } from "lucide-react";

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../components/Common/Card";
import { Button } from "../components/Common/Button";

import { useSetup, SetupStep } from "../context/SetupContext";
import { useTeam } from "../context/TeamContext";

import { setupService } from "../services/setupService";

import { warehouses } from "../constants/warehouses";

const DatasetInfo: React.FC = () => {
  const { teamId, updateWarehouseDetails } = useTeam();

  const [projectId, setProjectId] = useState("");
  const [datasetId, setDatasetId] = useState("");
  const [localError, setLocalError] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState<string | null>(
    null
  );

  const navigate = useNavigate();
  const {
    currentStep,
    updateStep,
    isLoading,
    error: contextError,
  } = useSetup();

  useEffect(() => {
    if (currentStep !== "dataset_info") {
      navigate(`/setup/${currentStep}`);
    }
  }, [currentStep, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLocalError("");

    try {
      const response = await setupService.submitDatasetInfo(teamId as string, {
        projectId,
        datasetId,
      });
      // TODO: I have this to figure out how to get the datasetId from the response in case there's sth wrong with it so I get the value from BE
      updateWarehouseDetails({
        projectId: response.projectId,
        datasetId: response.datasetId,
      });

      await updateStep(response.nextStep as SetupStep, {
        projectId: response.projectId,
        datasetId: response.datasetId,
      });
    } catch (err) {
      setLocalError(
        "Failed to submit dataset information. Please try again. Might be your projectId or datasetId are not valid."
      );
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-semibold">
            Choose Your Data Warehouse
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {warehouses.map((warehouse) => (
              <div
                key={warehouse.id}
                onClick={() =>
                  warehouse.available && setSelectedWarehouse(warehouse.id)
                }
                className={`rounded-lg p-4 transition-all duration-200 ${
                  selectedWarehouse === warehouse.id
                    ? "border-2 border-blue-500 bg-blue-50"
                    : warehouse.available
                    ? "border border-gray-200 hover:border-blue-300 hover:bg-blue-50 cursor-pointer"
                    : "border border-gray-200 opacity-50"
                }`}
              >
                <div className="flex items-center mb-3">
                  <warehouse.icon className="text-2xl mr-3" />
                  <h3 className="font-semibold">{warehouse.name}</h3>
                </div>
                {!warehouse.available && (
                  <span className="text-sm text-gray-500">Coming Soon</span>
                )}
                {selectedWarehouse === warehouse.id && (
                  <form onSubmit={handleSubmit} className="mt-3 space-y-2">
                    <input
                      type="text"
                      placeholder="Project ID"
                      value={projectId}
                      onChange={(e) => setProjectId(e.target.value)}
                      className="w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                    <input
                      type="text"
                      placeholder="Dataset ID"
                      value={datasetId}
                      onChange={(e) => setDatasetId(e.target.value)}
                      className="w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                    <Button
                      type="submit"
                      disabled={isLoading}
                      className={`w-full bg-blue-500 hover:bg-blue-600 text-white text-sm font-bold py-1 px-2 rounded ${
                        isLoading ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      {isLoading ? "Submitting..." : "Connect"}
                    </Button>
                  </form>
                )}
              </div>
            ))}
          </div>
        </CardContent>
        {(localError || contextError) && (
          <CardFooter>
            <div className="w-full p-3 bg-red-100 border border-red-400 text-red-700 rounded-md flex items-center">
              <AlertCircle className="mr-2 h-5 w-5" />
              <p>{localError || contextError}</p>
            </div>
          </CardFooter>
        )}
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-lg font-semibold">
            Need to migrate your data?
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="mb-4">
            If you haven't registered your data sources in Google BigQuery yet,
            you can use the WaveQuery CLI to do it in less than 5 minutes! 🚀
          </p>
          <div className="flex flex-wrap gap-4">
            <a
              href="https://www.npmjs.com/package/@wavequery/migrator"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center border border-red-500 text-red-500 px-4 py-2 rounded-md hover:bg-red-50 transition-colors"
            >
              <FaNpm className="mr-2" /> npm
            </a>
            <a
              href="https://dashboard.wavequery.com/docs/api/v0/cli"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center border border-blue-500 text-blue-500 px-4 py-2 rounded-md hover:bg-blue-50 transition-colors"
            >
              <FaBook className="mr-2" /> API Docs
            </a>
            <a
              href="https://brew.sh"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center border border-yellow-500 text-yellow-500 px-4 py-2 rounded-md hover:bg-yellow-50 transition-colors"
            >
              <FaBeer className="mr-2" /> Homebrew
            </a>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DatasetInfo;
