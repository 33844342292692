import { useState, useCallback, useMemo, useEffect } from "react";
import { Node, Edge } from "reactflow";
import { Table, Relationship } from "../types/types";

export const useRelationshipState = (
  tables: Table[],
  relationships: Relationship[]
) => {
  const [currentTableIndex, setCurrentTableIndex] = useState(0);
  const [reviewedRelationships, setReviewedRelationships] = useState<
    Record<string, boolean>
  >({});

  // Set all relationships as checked by default
  useEffect(() => {
    const initialReviewedRelationships = relationships.reduce((acc, rel) => {
      acc[`${rel.sourceTable}-${rel.targetTable}`] = true;
      return acc;
    }, {} as Record<string, boolean>);
    setReviewedRelationships(initialReviewedRelationships);
  }, [relationships]);

  const currentTable = tables[currentTableIndex];

  const currentRelationships = useMemo(() => {
    if (!currentTable) return [];
    return relationships.filter(
      (rel) =>
        rel.sourceTable === currentTable.name ||
        rel.targetTable === currentTable.name
    );
  }, [relationships, currentTable]);

  const nodes: Node[] = useMemo(() => {
    if (!currentTable) return [];

    const relatedTableNames = new Set(
      currentRelationships.flatMap((rel) => [rel.sourceTable, rel.targetTable])
    );

    return tables.map((table, index) => {
      const angle = (2 * Math.PI * index) / tables.length;
      const radius = 300; // Increased radius for better spread
      return {
        id: table.name,
        type: "tableNode",
        data: {
          name: table.name,
          isCurrent: table.name === currentTable.name,
          isRelated: relatedTableNames.has(table.name),
        },
        position: {
          x: 400 + Math.cos(angle) * radius,
          y: 300 + Math.sin(angle) * radius,
        },
      };
    });
  }, [tables, currentTable, currentRelationships]);

  const edges: Edge[] = useMemo(() => {
    return relationships.map((rel, index) => ({
      id: `e${index}`,
      source: rel.sourceTable,
      target: rel.targetTable,
      type: "relationshipEdge",
      data: {
        ...rel,
        isAccepted:
          reviewedRelationships[`${rel.sourceTable}-${rel.targetTable}`] !==
          false,
        isCurrent:
          rel.sourceTable === currentTable.name ||
          rel.targetTable === currentTable.name,
      },
    }));
  }, [relationships, reviewedRelationships, currentTable]);

  const handleRelationshipAction = useCallback(
    (relationship: Relationship, isAccepted: boolean) => {
      setReviewedRelationships((prev) => ({
        ...prev,
        [`${relationship.sourceTable}-${relationship.targetTable}`]: isAccepted,
      }));
    },
    []
  );

  const moveToNextTable = useCallback(() => {
    if (currentTableIndex < tables.length - 1) {
      setCurrentTableIndex((prev) => prev + 1);
    }
  }, [currentTableIndex, tables.length]);

  const isAllCurrentRelationshipsReviewed = currentRelationships.every(
    (rel) => [`${rel.sourceTable}-${rel.targetTable}`] in reviewedRelationships
  );

  const isAllReviewed =
    currentTableIndex === tables.length - 1 &&
    isAllCurrentRelationshipsReviewed;

  const proceedWithoutReview = useCallback(() => {
    // Set all remaining unreviewed relationships as accepted
    const updatedReviewedRelationships = relationships.reduce(
      (acc, rel) => {
        const key = `${rel.sourceTable}-${rel.targetTable}`;
        acc[key] = acc[key] ?? true;
        return acc;
      },
      { ...reviewedRelationships }
    );
    setReviewedRelationships(updatedReviewedRelationships);
    setCurrentTableIndex(tables.length - 1);
  }, [relationships, reviewedRelationships, tables.length]);

  return {
    nodes,
    edges,
    currentTable,
    currentRelationships,
    reviewedRelationships,
    handleRelationshipAction,
    moveToNextTable,
    isAllCurrentRelationshipsReviewed,
    isAllReviewed,
    proceedWithoutReview,
  };
};
