import React, { useState } from "react";
import { motion } from "framer-motion";

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/Common/Tooltip";
import { Save, ChevronRight, MessageCircle } from "lucide-react";
import { Button } from "@/components/Common";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@/components/Common/Tabs";
import { CodeEditor } from "@/components/Common/CodeEditor";
import BigQueryResultTable from "../ResultTable";
import QueryActions from "./QueryActions";
import SaveQueryDialog from "./SaveQueryDialog";
import { dataService } from "@/services/dataService";
import { QueryMetadata } from "../../Insights/types";

interface QueryPanelProps {
  generatedQuery: string;
  explanations: string[];
  queryResult: any;
  theme: string;
  onClose: () => void;
  teamId: string;
  datasetId: string;
  queryMetadata: QueryMetadata | null;
}

const QueryPanel: React.FC<QueryPanelProps> = ({
  generatedQuery,
  explanations,
  queryMetadata,
  queryResult,
  theme,
  onClose,
  teamId,
  datasetId,
}) => {
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);

  const handleSaveQuery = async (queryData: {
    name: string;
    query: string;
    tags: string[];
    relatedTables: string[];
  }) => {
    try {
      const queryInfo = { ...queryData, metadata: queryMetadata };
      await dataService.saveQuery({
        teamId,
        datasetId,
        queryData: queryInfo,
      });
    } catch (error) {
      console.error("Error saving query:", error);
    }
  };

  const extractTableNames = (query: string): string[] => {
    const fromRegex = /FROM\s+([^\s,;]+)/gi;
    const joinRegex = /JOIN\s+([^\s,;]+)/gi;

    const tables = new Set<string>();

    let match;
    while ((match = fromRegex.exec(query)) !== null) {
      tables.add(match[1].replace(/[`"]/g, ""));
    }
    while ((match = joinRegex.exec(query)) !== null) {
      tables.add(match[1].replace(/[`"]/g, ""));
    }

    return Array.from(tables);
  };

  return (
    <motion.div
      className="w-5/5 bg-white dark:bg-gray-800 border-l border-gray-200 dark:border-gray-700 flex flex-col"
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-center space-x-2">
          {queryResult && (
            <Tooltip>
              <TooltipTrigger>
                <Button
                  onClick={() => setIsSaveDialogOpen(true)}
                  className="text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
                >
                  <Save className="h-4 w-4" />
                </Button>
              </TooltipTrigger>

              <TooltipContent>Save this query</TooltipContent>
            </Tooltip>
          )}
          {/* <Tooltip>
            <TooltipTrigger>
              <Button
                onClick={() => {
                  
                }}
                className="text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
              >
                <MessageCircle className="h-4 w-4" />
              </Button>
            </TooltipTrigger>

            <TooltipContent>Save this chat</TooltipContent>
          </Tooltip> */}
        </div>
        {/* <h2 className="text-lg font-semibold">Query Results</h2> */}
        <Button
          onClick={onClose}
          className="text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
        >
          <ChevronRight className="h-6 w-6" />
        </Button>
      </div>
      <SaveQueryDialog
        isOpen={isSaveDialogOpen}
        onClose={() => setIsSaveDialogOpen(false)}
        onSave={handleSaveQuery}
        query={generatedQuery}
        relatedTables={extractTableNames(generatedQuery)}
      />

      <Tabs defaultValue="query" className="flex-1 flex flex-col">
        <TabsList className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
          <TabsTrigger value="query">Generated Query</TabsTrigger>
          <TabsTrigger value="explanation">Explanation</TabsTrigger>
        </TabsList>
        <TabsContent value="query" className="flex-1 flex flex-col p-4">
          <QueryActions onCopy={() => {}} />
          <div className="flex-1 overflow-auto">
            <CodeEditor
              value={generatedQuery}
              language="sql"
              sqlDialect="bigquery"
              theme={theme === "dark" ? "vs-dark" : "light"}
              readOnly={true}
            />
          </div>
        </TabsContent>
        <TabsContent value="explanation" className="flex-1 overflow-auto p-4">
          <div className="space-y-4">
            {Array(explanations).length > 1 &&
              explanations.map((explanation, index) => (
                <div
                  key={index}
                  className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg"
                >
                  <p className="text-sm">{explanation}</p>
                </div>
              ))}
            {explanations.length === 0 && (
              <p className="text-sm text-gray-500 dark:text-gray-400 italic">
                No explanations available for this query.
              </p>
            )}
          </div>
        </TabsContent>
        {queryResult && (
          <TabsContent value="result" className="flex-1 overflow-auto p-4">
            <BigQueryResultTable data={queryResult} />
          </TabsContent>
        )}
      </Tabs>
    </motion.div>
  );
};

export default QueryPanel;
