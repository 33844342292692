import React from "react";

interface ProgressTrackerProps {
  currentTable: number;
  totalTables: number;
  reviewedRelationships: number;
  totalRelationships: number;
}

export const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  currentTable,
  totalTables,
  reviewedRelationships,
  totalRelationships,
}) => {
  const tableProgress = (currentTable / totalTables) * 100;
  const relationshipProgress =
    (reviewedRelationships / totalRelationships) * 100;

  return (
    <div className="space-y-4">
      <div>
        <div className="flex justify-between mb-1">
          <span className="text-sm font-medium text-gray-700">
            Tables Progress
          </span>
          <span className="text-sm font-medium text-gray-700">{`${currentTable} / ${totalTables}`}</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
          <div
            className="bg-blue-600 h-full rounded-full transition-all duration-300 ease-in-out"
            style={{ width: `${tableProgress}%` }}
          ></div>
        </div>
      </div>
      <div>
        <div className="flex justify-between mb-1">
          <span className="text-sm font-medium text-gray-700">
            Relationships Reviewed
          </span>
          <span className="text-sm font-medium text-gray-700">{`${reviewedRelationships} / ${totalRelationships}`}</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
          <div
            className="bg-green-500 h-full rounded-full transition-all duration-300 ease-in-out"
            style={{ width: `${relationshipProgress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
