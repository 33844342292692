import React, { useState } from "react";
import { Hash, Info, TestTube2, Loader2 } from "lucide-react";

import { Label } from "@/components/Common/Label";
import { Input } from "@/components/Common/Input";
import { Checkbox } from "@/components/Common/Checkbox";
import { Button } from "@/components/Common/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Common/Select";
import { toast } from "@/components/Common/Toast";
import { TextArea } from "@/components/Common/TextArea";

import { automationService } from "@/services/automationService";

import { useTeam } from "@/context/TeamContext";

interface SlackValidationErrors {
  channelName?: string;
  messageTemplate?: string;
  webhookUrl?: string;
}

interface SlackConfigProps {
  config: {
    channelName: string;
    messageTemplate: string;
    frequency: string;
    mentionUsers: string[];
    threadBehavior: string;
    errorNotification: boolean;
    useCustomWebhook?: boolean;
    webhookUrl: string;
  };
  setConfig: (config: any) => void;
}

export const validateSlackConfig = (
  config: SlackConfigProps["config"]
): SlackValidationErrors => {
  const errors: SlackValidationErrors = {};

  // Channel name validation
  if (!config.channelName) {
    errors.channelName = "Channel name is required";
  } else if (config.channelName.includes(" ")) {
    errors.channelName = "Channel name cannot contain spaces";
  } else if (!config.channelName.match(/^[a-z0-9-_]+$/)) {
    errors.channelName =
      "Channel name can only contain letters, numbers, hyphens, and underscores";
  }

  // Message template validation
  if (!config.messageTemplate) {
    errors.messageTemplate = "Message template is required";
  } else if (config.messageTemplate.length > 1000) {
    errors.messageTemplate =
      "Message template is too long (max 1000 characters)";
  } else if (!config.messageTemplate.includes("{")) {
    errors.messageTemplate =
      "Message template should include at least one variable";
  }

  // Webhook URL validation (if custom webhook is enabled)
  if (config.useCustomWebhook && !config.webhookUrl) {
    errors.webhookUrl = "Webhook URL is required when using custom webhook";
  } else if (config.useCustomWebhook && config.webhookUrl) {
    try {
      new URL(config.webhookUrl);
      if (!config.webhookUrl.startsWith("https://hooks.slack.com/")) {
        errors.webhookUrl = "Invalid Slack webhook URL format";
      }
    } catch {
      errors.webhookUrl = "Invalid URL format";
    }
  }

  return errors;
};

export const SlackConfig: React.FC<SlackConfigProps> = ({
  config,
  query,
  setConfig,
}) => {
  const { teamId, warehouseDetails } = useTeam();
  const [errors, setErrors] = useState<SlackValidationErrors>({});
  const [isTesting, setIsTesting] = useState(false);

  const availableVariables = [
    { name: "{date}", description: "Current date" },
    { name: "{query_name}", description: "Query name" },
    { name: "{time}", description: "Execution time" },
    { name: "{rows_affected}", description: "Number of rows affected" },
    { name: "{total_bytes}", description: "Total bytes processed" },
    { name: "{cost}", description: "Query cost" },
    { name: "{result}", description: "Query results" },
  ];

  const handleTestNotification = async () => {
    // Validate before testing
    const validationErrors = validateSlackConfig(config);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      toast.error("Please fix validation errors before testing");
      return;
    }

    try {
      setIsTesting(true);
      await toast.promise(
        automationService.testSlackNotification({
          teamId,
          datasetId: warehouseDetails.datasetId,
          queryId: querye.id,
          channel: {
            channelName: config.channelName,
            messageTemplate: "[TEST] " + config.messageTemplate,
            webhookUrl: config.useCustomWebhook ? config.webhookUrl : undefined,
          },
        }),
        {
          loading: "Sending test notification...",
          success: "Test notification sent successfully!",
          error: "Failed to send test notification",
        }
      );
    } catch (error) {
      console.error("Error testing notification:", error);
    } finally {
      setIsTesting(false);
    }
  };

  const handleConfigChange = (newConfig: any) => {
    setConfig(newConfig);
    setErrors(validateSlackConfig(newConfig));
  };

  return (
    <div className="space-y-6">
      {/* Info Banner */}
      <div className="rounded-lg bg-blue-50 dark:bg-blue-900/20 p-4">
        <div className="flex items-start gap-3">
          <Info className="w-5 h-5 text-blue-500 mt-0.5" />
          <div className="flex-1">
            <p className="text-sm text-blue-900 dark:text-blue-200">
              Configure how and when query results will be sent to Slack. Use
              variables in your message template to include dynamic data.
            </p>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <Label>Webhook Configuration</Label>
          <div className="flex items-center gap-2">
            <Checkbox
              checked={config.useCustomWebhook}
              onCheckedChange={(checked) =>
                handleConfigChange({ ...config, useCustomWebhook: checked })
              }
            />
            <span className="text-sm text-gray-500">Use custom webhook</span>
          </div>
        </div>

        {config.useCustomWebhook && (
          <div className="space-y-2">
            <Input
              placeholder="Webhook URL"
              value={config.webhookUrl}
              onChange={(e) =>
                handleConfigChange({ ...config, webhookUrl: e.target.value })
              }
              className={errors.webhookUrl ? "border-red-500" : ""}
            />
            {errors.webhookUrl && (
              <p className="text-xs text-red-500">{errors.webhookUrl}</p>
            )}
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-x-8 gap-y-6">
        {/* Left Column */}
        <div className="space-y-6">
          <div className="space-y-2">
            <Label>Channel Name</Label>
            <div className="relative">
              <Hash className="absolute left-2 top-2.5 w-4 h-4 text-gray-500" />
              <Input
                placeholder="analytics-alerts"
                className="pl-8"
                value={config.channelName}
                onChange={(e) =>
                  handleConfigChange({ ...config, channelName: e.target.value })
                }
              />
            </div>
            {errors.channelName && (
              <p className="text-xs text-red-500">{errors.channelName}</p>
            )}
            <p className="text-xs text-gray-500">
              Channel where notifications will be sent
            </p>
          </div>

          <div className="space-y-2">
            <Label>Notification Frequency</Label>
            <Select
              value={config.frequency}
              onValueChange={(val) => setConfig({ ...config, frequency: val })}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select frequency" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="always">Every Run</SelectItem>
                <SelectItem value="success">On Success Only</SelectItem>
                <SelectItem value="failure">On Failure Only</SelectItem>
                <SelectItem value="changes">When Results Change</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label>Thread Behavior</Label>
            <Select
              value={config.threadBehavior}
              onValueChange={(val) =>
                setConfig({ ...config, threadBehavior: val })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select thread behavior" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="new">Create New Thread</SelectItem>
                <SelectItem value="continue">
                  Continue Existing Thread
                </SelectItem>
                <SelectItem value="none">No Threading</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-6">
          <div className="space-y-2">
            <Label>Message Template</Label>
            <TextArea
              placeholder="Query results for {date}: {rows_affected} rows affected"
              value={config.messageTemplate}
              onChange={(e) =>
                handleConfigChange({
                  ...config,
                  messageTemplate: e.target.value,
                })
              }
            />
            {errors.messageTemplate && (
              <p className="text-xs text-red-500">{errors.messageTemplate}</p>
            )}
            <div className="pt-2">
              <Label className="text-xs text-gray-500">
                Available Variables:
              </Label>
              <div className="mt-1 flex flex-wrap gap-2">
                {availableVariables.map((variable) => (
                  <div
                    key={variable.name}
                    className="inline-flex items-center text-xs bg-gray-100 dark:bg-gray-800 rounded px-2 py-1"
                  >
                    <code className="text-blue-600 dark:text-blue-400">
                      {variable.name}
                    </code>
                    <span className="ml-1 text-gray-500">
                      - {variable.description}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center space-x-12">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="error-notification"
                  checked={config.errorNotification}
                  onCheckedChange={(checked) =>
                    setConfig({ ...config, errorNotification: checked })
                  }
                />
                <Label htmlFor="error-notification">
                  Send error notifications
                </Label>
              </div>
              {/* Test Button */}
              <div>
                <Button
                  onClick={handleTestNotification}
                  disabled={isTesting || Object.keys(errors).length > 0}
                  variant="outline"
                  className="flex items-center gap-2"
                >
                  {isTesting ? (
                    <Loader2 className="w-4 h-4 animate-spin" />
                  ) : (
                    <TestTube2 className="w-4 h-4" />
                  )}
                  Test Notification
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
