import { useRef, useEffect } from "react";

/**
 * A custom hook that stores and returns the previous value of a variable.
 * This is particularly useful for comparing current and previous states,
 * especially in animations and state transitions.
 *
 * @template T The type of the value to track
 * @param {T} value The current value to track
 * @returns {T | undefined} The previous value (undefined on first render)
 *
 * @example
 * const [count, setCount] = useState(0);
 * const previousCount = usePrevious(count);
 * 
 * // Now you can compare count with previousCount
 * useEffect(() => {
 *   if (previousCount !== undefined && count !== previousCount) {
 *     console.log(`Count changed from ${previousCount} to ${count}`);
 *   }
 * }, [count, previousCount]);
 */
export function usePrevious<T>(value: T): T | undefined {
  // Create a ref to store the previous value
  const ref = useRef<T>();

  // Update the ref value after each render
  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return the value stored before the current render
  return ref.current;
}

// Additional utilities that complement the usePrevious hook

/**
 * Compares the previous and current values to detect changes.
 * Useful for tracking specific state transitions.
 */
export function useValueChange<T>(
  value: T,
  onChange?: (newValue: T, previousValue: T | undefined) => void
) {
  const previousValue = usePrevious(value);

  useEffect(() => {
    if (onChange && previousValue !== value) {
      onChange(value, previousValue);
    }
  }, [value, previousValue, onChange]);

  return previousValue;
}

/**
 * A specialized version of usePrevious that only updates when the value
 * meets certain criteria, defined by a predicate function.
 */
export function useConditionalPrevious<T>(
  value: T,
  shouldUpdate: (current: T, previous: T | undefined) => boolean
): T | undefined {
  const ref = useRef<T>();
  
  useEffect(() => {
    if (shouldUpdate(value, ref.current)) {
      ref.current = value;
    }
  }, [value, shouldUpdate]);

  return ref.current;
}