import React from "react";
import { Label } from "@/components/Common/Label";
import { TextArea } from "@/components/Common";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Common/Select";
// import { Input } from "@/components/Common/Input";

import { Brain, Info } from "lucide-react";

interface FineTuningConfigProps {
  config: {
    description: string;
    toBeUsed: boolean;
  };
  setConfig: (config: any) => void;
}

export const FineTuningConfig: React.FC<FineTuningConfigProps> = ({
  config,
  setConfig,
}) => {
  return (
    <div className="space-y-6">
      {/* Info Banner */}
      <div className="rounded-lg bg-purple-50 dark:bg-purple-900/20 p-4">
        <div className="flex items-start gap-3">
          <Brain className="w-5 h-5 text-purple-500 mt-0.5" />
          <div className="flex-1">
            <h4 className="text-sm font-medium text-purple-900 dark:text-purple-200 mb-1">
              Improve Model Understanding
            </h4>
            <p className="text-sm text-purple-900/90 dark:text-purple-200/90">
              This query and its metadata will be used to improve our model's understanding
              of your data patterns and business context. The model will learn from:
            </p>
            <ul className="mt-2 text-sm text-purple-900/90 dark:text-purple-200/90 space-y-1">
              <li className="flex items-center gap-2">
                • Query structure and patterns
              </li>
              <li className="flex items-center gap-2">
                • Business context from your description
              </li>
              <li className="flex items-center gap-2">
                • Common data relationships
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <div className="space-y-2">
          <Label>Description</Label>
          <TextArea
            placeholder="Describe what this query does and its business context..."
            value={config.description}
            onChange={(e) =>
              setConfig({ ...config, description: e.target.value })
            }
            className="min-h-[120px] resize-y"
          />
          <p className="text-xs text-gray-500">
            Provide context about the query's purpose, business logic, and expected results.
            This helps the model better understand your data patterns.
          </p>
        </div>

        <div className="rounded-lg bg-gray-50 dark:bg-gray-800 p-4">
          <h4 className="text-sm font-medium mb-2">Benefits of Fine-Tuning</h4>
          <ul className="space-y-2">
            <li className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
              ✓ More accurate query suggestions
            </li>
            <li className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
              ✓ Better understanding of your business logic
            </li>
            <li className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
              ✓ Improved natural language processing
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};