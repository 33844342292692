import React, { useState } from "react";
import { Button } from "@/components/Common";
import { Copy, CheckCircle, PlayCircle } from "lucide-react";

interface QueryActionsProps {
  onCopy: () => void;
  onExecute: () => void;
}

const QueryActions: React.FC<QueryActionsProps> = ({ onCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    onCopy();
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className="flex justify-end space-x-2 mb-2">
      <Button
        onClick={handleCopy}
        className="text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
        title="Copy to Clipboard"
      >
        {isCopied ? (
          <CheckCircle className="h-5 w-5 text-green-500" />
        ) : (
          <Copy className="h-5 w-5" />
        )}
      </Button>
      {/* <Button
        onClick={onExecute}
        className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2"
        title="Execute Query"
      >
        <PlayCircle className="h-5 w-5" />
      </Button> */}
    </div>
  );
};

export default QueryActions;
