import React, { useState, useCallback, useEffect, useRef } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import { Search, ZoomIn, ZoomOut, RotateCcw, AlertCircle } from "lucide-react";
import { Button } from "../Common/Button";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../Common/Tooltip";

const DatabaseSchemaVisualizer = ({ schemaData }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  useEffect(() => {
    if (schemaData) {
      const newNodes = schemaData.tables.map((table, index) => ({
        id: table.name,
        data: { label: table.name, ...table },
        position: { x: index * 200, y: index * 100 },
      }));

      const newEdges = schemaData.relationships.map((rel, index) => ({
        id: `e${index}`,
        source: rel.sourceTable,
        target: rel.targetTable,
        label: `${rel.sourceColumn} → ${rel.targetColumn}`,
      }));

      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [schemaData]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );


  // TODO: Hook up search functionality
  //
  // const handleSearch = useCallback((event) => {
  //   const term = event.target.value.toLowerCase();
  //   setSearchTerm(term);

  //   setNodes((nds) =>
  //     nds.map((node) => ({
  //       ...node,
  //       className: node.data.name.toLowerCase().includes(term)
  //         ? "highlight"
  //         : "dimmed",
  //     }))
  //   );

  //   setEdges((eds) =>
  //     eds.map((edge) => ({
  //       ...edge,
  //       className:
  //         edge.source.toLowerCase().includes(term) ||
  //         edge.target.toLowerCase().includes(term)
  //           ? "highlight"
  //           : "dimmed",
  //     }))
  //   );
  // }, []);

  // const clearSearch = useCallback(() => {
  //   setSearchTerm("");
  //   setNodes((nds) => nds.map((node) => ({ ...node, className: "" })));
  //   setEdges((eds) => eds.map((edge) => ({ ...edge, className: "" })));
  // }, []);

  // const [searchTerm, setSearchTerm] = useState("");

  const onZoomIn = () => {
    reactFlowInstance.zoomIn();
  };

  const onZoomOut = () => {
    reactFlowInstance.zoomOut();
  };

  const onResetView = () => {
    reactFlowInstance.fitView();
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex bg-gray-100 justify-end space-x-2 p-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button onClick={onZoomIn} variant="outline" size="icon">
                <ZoomIn className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Zoom In</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button onClick={onZoomOut} variant="outline" size="icon">
                <ZoomOut className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Zoom Out</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button onClick={onResetView} variant="outline" size="icon">
                <RotateCcw className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Reset View</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex-grow" ref={reactFlowWrapper}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            fitView
            className="bg-gray-100 rounded-lg"
          >
            <Background />
            <Controls />
            <MiniMap />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    </div>
  );
};

export default DatabaseSchemaVisualizer;
