import React, { useState, useMemo } from "react";
import {
  Search,
  ChevronLeft,
  ChevronRight,
  PanelRightClose,
  PanelRightOpen,
} from "lucide-react";

import { Input } from "../Common/Input";
import { Button } from "../Common/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../Common/Card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../Common/Tabs";

import DatabaseSchemaVisualizer from "./DatabaseSchemaVisualizer";

import { AnalyzedTableData, Relationship } from "../../state/types/graphTypes";

interface FinalReviewStepProps {
  tables: AnalyzedTableData[];
  relationships: Relationship[];
  onSubmit: () => void;
  onBack: () => void;
}

const FinalReviewStep: React.FC<FinalReviewStepProps> = ({
  tables,
  relationships,
  onSubmit,
  onBack,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const itemsPerPage = 10;

  const filteredTables = useMemo(
    () =>
      tables.filter(
        (table) =>
          table.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (table.description &&
            table.description.toLowerCase().includes(searchTerm.toLowerCase()))
      ),
    [tables, searchTerm]
  );

  const filteredRelationships = useMemo(
    () =>
      relationships.filter(
        (rel) =>
          rel.sourceTable.toLowerCase().includes(searchTerm.toLowerCase()) ||
          rel.targetTable.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [relationships, searchTerm]
  );

  const paginatedTables = useMemo(
    () =>
      filteredTables.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      ),
    [filteredTables, currentPage]
  );

  const totalPages = Math.ceil(filteredTables.length / itemsPerPage);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="flex flex-col h-screen">
      <header className="flex justify-between items-center p-4 border-b">
        <h1 className="text-2xl font-bold">Review and Submit</h1>
        <Button onClick={toggleSidebar} variant="outline" size="icon">
          {isSidebarOpen ? (
            <PanelRightClose className="h-4 w-4" />
          ) : (
            <PanelRightOpen className="h-4 w-4" />
          )}
        </Button>
      </header>
      <main className="flex flex-grow overflow-hidden">
        <div className="flex-grow overflow-auto">
          <DatabaseSchemaVisualizer schemaData={{ tables, relationships }} />
        </div>
        {isSidebarOpen && (
          <aside className="w-80 border-l flex flex-col">
            <div className="p-4">
              <Input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full"
              />
            </div>
            <Tabs defaultValue="tables" className="flex-grow flex flex-col">
              <TabsList className="px-4">
                <TabsTrigger value="tables">Tables</TabsTrigger>
                <TabsTrigger value="relationships">Relationships</TabsTrigger>
              </TabsList>
              <TabsContent
                value="tables"
                className="flex-grow flex flex-col p-4"
              >
                <div className="space-y-4 overflow-scroll h-96">
                  {paginatedTables.map((table) => (
                    <Card key={table.id}>
                      <CardContent className="p-4">
                        <h3 className="font-semibold">{table.name}</h3>
                        <p className="text-sm text-gray-500">
                          {table.description ||
                            table.analysis?.suggestedDescription ||
                            "No description available."}
                        </p>
                      </CardContent>
                    </Card>
                  ))}
                </div>

                <div className="flex justify-between items-center mt-4">
                  <Button
                    onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
                    disabled={currentPage === 1}
                    variant="outline"
                    size="sm"
                  >
                    <ChevronLeft className="h-4 w-4 mr-2" /> Previous
                  </Button>
                  <span>
                    {currentPage} / {totalPages}
                  </span>
                  <Button
                    onClick={() =>
                      setCurrentPage((p) => Math.min(totalPages, p + 1))
                    }
                    disabled={currentPage === totalPages}
                    variant="outline"
                    size="sm"
                  >
                    Next <ChevronRight className="h-4 w-4 ml-2" />
                  </Button>
                </div>
              </TabsContent>
              <TabsContent value="relationships" className="flex-grow p-4">
                <div className="space-y-2 overflow-scroll h-5/6">
                  {filteredRelationships.map((relationship, index) => (
                    <Card key={index}>
                      <CardContent className="p-2 text-sm">
                        {`${relationship.sourceTable} → ${relationship.targetTable}`}
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </TabsContent>
            </Tabs>
          </aside>
        )}
      </main>
      <footer className="flex justify-between p-4 border-t">
        <Button onClick={onBack} variant="outline">
          Back
        </Button>
        <Button onClick={onSubmit}>Submit and Finalize</Button>
      </footer>
    </div>
  );
};

export default FinalReviewStep;
