import { API_URL, axiosInstance } from "../config";

interface AutomationConfig {
  isSlackEnabled: boolean;
  isBigQueryEnabled: boolean;
  isFineTuningEnabled: boolean;
  isEmailEnabled: boolean;
  slackChannels?: {
    channelName: string;
    messageTemplate: string;
    frequency: string;
    threadBehavior?: string;
    errorNotification?: boolean;
  }[];
  bigQueryConfig?: {
    scheduleType: string;
    startDate: string;
    timeZone: string;
    retryCount: number;
    timeout: string;
    destination: string;
  };
  fineTuningConfig?: {
    description: string;
    toBeUsed: boolean;
  };
  emailConfig?:  {
    recipients: string[];
    subject: string;
    template: string;
    frequency: string;
    attachCsv: boolean;
    errorNotification: boolean;
  }
}

export const automationService = {
  getQueryAutomation: async ({
    teamId,
    datasetId,
    queryId,
  }: {
    teamId: string;
    datasetId: string;
    queryId: string;
  }) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/${queryId}/automation`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching automation config:", error);
      throw error;
    }
  },
  updateQueryAutomation: async ({
    teamId,
    datasetId,
    queryId,
    config,
  }: {
    teamId: string;
    datasetId: string;
    queryId: string;
    config: AutomationConfig;
  }) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/${queryId}/automation`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("Error updating automation config:", error);
      throw error;
    }
  },
  getAutomationStatus: async ({
    teamId,
    datasetId,
    queryId,
  }: {
    teamId: string;
    datasetId: string;
    queryId: string;
  }) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/${queryId}/automation/status`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching automation status:", error);
      throw error;
    }
  },
  testSlackNotification: async ({
    teamId,
    datasetId,
    queryId,
    channel,
  }: {
    teamId: string;
    datasetId: string;
    queryId: string;
    channel: {
      channelName: string;
      messageTemplate: string;
    };
  }) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/${queryId}/automation/slack/test`,
        channel
      );
      return response.data;
    } catch (error) {
      console.error("Error testing slack notification:", error);
      throw error;
    }
  },
  deleteAutomation: async ({
    teamId,
    datasetId,
    queryId,
  }: {
    teamId: string;
    datasetId: string;
    queryId: string;
  }) => {
    try {
      const response = await axiosInstance.delete(
        `${API_URL}/api/v0/teams/${teamId}/datasets/${datasetId}/queries/${queryId}/automation`
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting automation config:", error);
      throw error;
    }
  },
};
