import React, { useState } from "react";
import {
  Database,
  FileSpreadsheet,
  Plus,
  FileText,
  HelpCircle,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/Common/DropDown";
import { Button } from "@/components/Common/Button";
import SetupDialog from "./SetupDialog";

interface DashboardHeaderProps {
  activeTab: string;
  teamId: string;
  warehouseDetails: any;
  onOpenFilePanel: () => void;
  WarehouseLogo: React.ComponentType<{ className?: string }>;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  activeTab,
  teamId,
  warehouseDetails,
  onOpenFilePanel,
  WarehouseLogo,
}) => {
  const [isSetupDialogOpen, setIsSetupDialogOpen] = useState(false);
  const [isCustomDataSourceOpen, setIsCustomDataSourceOpen] = useState(false);

  const handleConnectDatabase = () => {
    setIsSetupDialogOpen(true);
  };

  const handleConnectCurrentWarehouse = () => {
    setIsSetupDialogOpen(false);
    setIsCustomDataSourceOpen(false);
    // TODO:
    // Now turn the panel closed and just get back to current warehouse
  };

  const handleConnectCustomData = () => {
    setIsCustomDataSourceOpen(true);
    onOpenFilePanel();
  };

  return (
    <>
      <header className="bg-white/30 dark:bg-gray-800/30 backdrop-blur-md shadow-sm p-4 flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h1 className="text-xl font-bold text-gray-800 dark:text-white">
            {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
          </h1>
        </div>

        <div className="flex items-center space-x-6">
          <span className="flex items-center" title="Team ID">
            <span className="mr-2">👥</span>
            <span className="text-sm font-semibold">{teamId || "Not set"}</span>
          </span>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center gap-2">
                {isCustomDataSourceOpen ? (
                  <>
                    <span className="text-sm">Custom data source</span>
                  </>
                ) : warehouseDetails ? (
                  <>
                    <WarehouseLogo className="w-6 h-6" />
                    <span className="text-sm">
                      {warehouseDetails.datasetId}
                    </span>
                  </>
                ) : (
                  <>
                    <Database className="w-4 h-4" />
                    <span className="text-sm">Select Data Source</span>
                  </>
                )}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="bg-white pointer-cursor w-56"
            >
              {warehouseDetails && (
                <>
                  <DropdownMenuItem
                    onClick={handleConnectCurrentWarehouse}
                    className="text-gray-500"
                  >
                    <Database className="w-4 h-4 mr-2" />
                    Connected to {warehouseDetails.warehouse}:{" "}
                    {warehouseDetails.datasetId}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                </>
              )}
              <DropdownMenuItem onClick={handleConnectCustomData}>
                <FileSpreadsheet className="w-4 h-4 mr-2" />
                Chat with custom data
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleConnectDatabase}>
                <Plus className="w-4 h-4 mr-2" />
                Connect to Warehouse
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>

      <SetupDialog
        isOpen={isSetupDialogOpen}
        onClose={() => setIsSetupDialogOpen(false)}
      />
    </>
  );
};

export default DashboardHeader;
