import React, { useState } from 'react';
import { Eye, EyeOff, Key, Loader2 } from 'lucide-react';
import { Label } from '@/components/Common/Label';
import { Input } from '@/components/Common/Input';
import { Button } from '@/components/Common/Button';

interface KeyInputProps {
  value: string;
  onChange: (value: string) => void;
  onTest: () => void;
  isLoading?: boolean;
}

const KeyInput: React.FC<KeyInputProps> = ({
  value,
  onChange,
  onTest,
  isLoading,
}) => {
  const [showKey, setShowKey] = useState(false);

  return (
    <div className="space-y-2">
      <Label>API Key</Label>
      <div className="relative flex gap-2">
        <div className="relative flex-1">
          <div className="absolute left-3 top-2.5 text-gray-500">
            <Key className="w-4 h-4" />
          </div>
          <Input
            type={showKey ? "text" : "password"}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="pl-9 pr-10 font-mono text-sm"
            placeholder="Enter your API key"
          />
          <button
            type="button"
            onClick={() => setShowKey(!showKey)}
            className="absolute right-3 top-2.5 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            {showKey ? (
              <EyeOff className="w-4 h-4" />
            ) : (
              <Eye className="w-4 h-4" />
            )}
          </button>
        </div>
        <Button
          type="button"
          variant="outline"
          size="default"
          onClick={onTest}
          disabled={!value || isLoading}
          className="min-w-[100px]"
        >
          {isLoading ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Testing...
            </>
          ) : (
            'Test Key'
          )}
        </Button>
      </div>
      <p className="text-sm text-muted-foreground">
        Your API key is encrypted and stored securely. We never share your keys with third parties.
      </p>
    </div>
  );
};

export default KeyInput;