import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ReactFlow, {
  Background,
  Controls,
  ReactFlowProvider,
  MiniMap,
  Panel,
  useReactFlow,
  applyNodeChanges,
  applyEdgeChanges,
  Node,
  Edge,
  TableProperties,
  Viewport,
  ConnectionMode,
  BackgroundVariant,
} from "reactflow";
import { motion, AnimatePresence } from "framer-motion";
import debounce from "lodash/debounce";
import {
  Search,
  ZoomIn,
  ZoomOut,
  Maximize2,
  Database,
  Brain,
  Share2,
  FileJson,
  Settings,
  Loader2,
} from "lucide-react";

import { PlaygroundProvider, usePlayground } from "@/context/PlaygroundContext";
import { PanelContainer } from "@/components/Playground/PanelContainer";
import { Button } from "@/components/Common/Button";
import { Input } from "@/components/Common/Input";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@/components/Common/Tooltip";
import { nodeTypes, edgeTypes } from "@/components/Playground/FlowNodes";
import { usePrevious } from "@/hooks/usePrevious";
import { LoadingOverlay } from "@/components/Playground/LoadingOverlay";

// Configuration for performance optimizations
const VIEWPORT_PADDING = 50;
const DEBOUNCE_TIME = 100;
const MIN_ZOOM = 0.1;
const MAX_ZOOM = 4;
const EDGE_STROKE_WIDTH = 2;

interface LoadingState {
  phase: "connecting" | "analyzing" | "layouting" | "ready" | "error";
  progress?: number;
  message?: string;
  error?: string;
}

const Playground = () => {
  return (
    <PlaygroundProvider>
      <ReactFlowProvider>
        <TooltipProvider>
          <div className="h-lvh w-full bg-background flex flex-col">
            <Header />
            <main className="w-full h-full flex-1 relative overflow-hidden">
              <PanelContainer />
              <VisualizationWrapper />
            </main>
          </div>
        </TooltipProvider>
      </ReactFlowProvider>
    </PlaygroundProvider>
  );
};

const Header = () => {
  const { panels, setPanels } = usePlayground();

  return (
    <header className="h-14 w-full border-b flex items-center justify-between px-4 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 z-50">
      <div className="flex items-center gap-4">
        <h1 className="text-xl font-semibold">Playground</h1>
        <div className="flex items-center gap-2">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                onClick={() =>
                  setPanels((prev) => ({
                    ...prev,
                    isLeftPanelOpen: !prev.isLeftPanelOpen,
                  }))
                }
              >
                <Database className="h-4 w-4 mr-2" />
                Data Source
              </Button>
            </TooltipTrigger>
            <TooltipContent>Toggle data source panel</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                onClick={() =>
                  setPanels((prev) => ({
                    ...prev,
                    isRightPanelOpen: !prev.isRightPanelOpen,
                  }))
                }
              >
                <Brain className="h-4 w-4 mr-2" />
                AI Assistant
              </Button>
            </TooltipTrigger>
            <TooltipContent>Toggle AI configuration</TooltipContent>
          </Tooltip>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Button variant="ghost" size="sm">
          <Share2 className="h-4 w-4 mr-2" />
          Share
        </Button>
      </div>
    </header>
  );
};

const VisualizationWrapper = () => {
  const { canvasState, schema, setSchema } = usePlayground();
  const [loadingState, setLoadingState] = React.useState<LoadingState | null>(
    null
  );
  const { fitView, zoomIn, zoomOut } = useReactFlow();
  const prevNodes = usePrevious(schema?.nodes);
  const viewportRef = useRef<Viewport>();

  // This method handles changes to nodes (position, selection, deletion)
  const onNodesChange = useCallback(
    (changes) => {
      // We apply the changes to the nodes and add a smooth transition
      setSchema((prev) => ({
        ...prev,
        nodes: applyNodeChanges(changes, prev.nodes).map((node) => ({
          ...node,
          // Adding animation for position changes
          transition: {
            duration: 0.3,
            easing: "easeInOut",
          },
        })),
      }));
    },
    [setSchema]
  );

  // This method handles changes to edges (creation, deletion, selection)
  const onEdgesChange = useCallback(
    (changes) => {
      // We apply the changes to the edges without additional processing
      setSchema((prev) => ({
        ...prev,
        edges: applyEdgeChanges(changes, prev.edges),
      }));
    },
    [setSchema]
  );

  // This method saves the viewport state when the user finishes moving
  const onMoveEnd = useCallback((viewport: Viewport) => {
    // Save the current viewport to our ref for future use
    viewportRef.current = viewport;

    // Store viewport in localStorage for persistence between sessions
    try {
      localStorage.setItem("playground-viewport", JSON.stringify(viewport));
    } catch (error) {
      console.warn("Failed to save viewport state:", error);
    }
  }, []);

  // Handle loading states only when actively connecting
  useEffect(() => {
    switch (canvasState) {
      case "initial":
        // Don't show loading state for initial state
        setLoadingState(null);
        break;
      case "loading":
        setLoadingState({ phase: "connecting" });
        break;
      case "analyzing":
        setLoadingState({ phase: "analyzing" });
        break;
      case "ready":
        // Show quick layout calculation before removing overlay
        setLoadingState({ phase: "layouting" });
        const timer = setTimeout(() => setLoadingState(null), 800);
        return () => clearTimeout(timer);
      case "error":
        setLoadingState({
          phase: "error",
          error: "Failed to connect to database",
        });
        break;
    }
  }, [canvasState]);

  return (
    <>
      {/* Only show loading overlay when we have a loading state */}
      {loadingState && <LoadingOverlay state={loadingState} />}

      <div className="w-full h-full relative">
        {/* Empty State - Show when no schema and not loading */}
        {!schema && !loadingState && <EmptyCanvas />}

        {/* Main Visualization - Show when schema is available */}
        {schema && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="w-full h-full border-double border-4 border-sky-900"
          >
            <ReactFlow
              nodes={schema.nodes}
              edges={schema.edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onMoveEnd={onMoveEnd}
              fitView
              minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              defaultViewport={viewportRef.current}
              connectionMode={ConnectionMode.Loose}
              className="w-full h-full"
              style={{ height: "100%", width: "100%" }}
            >
              <AnimatedBackground />
              <OptimizedMinimap />
              <ViewportControls
                onZoomIn={zoomIn}
                onZoomOut={zoomOut}
                onFitView={fitView}
              />
            </ReactFlow>
          </motion.div>
        )}
      </div>
    </>
  );
};

const EmptyCanvas = () => {
  return (
    <div className="w-full h-full flex items-center justify-center bg-background/50">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center max-w-md p-8 space-y-6"
      >
        <div className="relative w-16 h-16 mx-auto">
          <Database className="w-16 h-16 text-muted-foreground/30 absolute inset-0" />
          <motion.div
            className="absolute inset-0"
            animate={{
              scale: [1, 1.1, 1],
              opacity: [0.5, 0.8, 0.5],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <Database className="w-16 h-16 text-muted-foreground/10" />
          </motion.div>
        </div>

        <div className="space-y-3">
          <h3 className="text-xl font-semibold text-foreground">
            Connect to a Database
          </h3>
          <p className="text-sm text-muted-foreground leading-relaxed">
            Start by connecting to your database using the Data Source panel, or
            try one of our sample databases to explore the features.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

const AnimatedBackground = React.memo(() => (
  <Background
    gap={12}
    size={1}
    color="var(--border)"
    variant={BackgroundVariant.Dots}
    className="transition-opacity duration-300"
  />
));

const OptimizedMinimap = React.memo(() => (
  <MiniMap
    nodeColor={(node) => {
      const density = node.data?.relationships?.length || 0;
      return density > 5 ? "var(--primary)" : "var(--muted)";
    }}
    nodeStrokeWidth={3}
    zoomable
    pannable
    className="bg-background/80 backdrop-blur-sm"
  />
));

const ViewportControls = React.memo(({ onZoomIn, onZoomOut, onFitView }) => (
  <Panel
    position="top-right"
    className="flex gap-2 bg-background/50 p-2 rounded-lg backdrop-blur"
  >
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="ghost" size="sm" onClick={onZoomIn}>
          <ZoomIn className="h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Zoom in</TooltipContent>
    </Tooltip>

    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="ghost" size="sm" onClick={onZoomOut}>
          <ZoomOut className="h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Zoom out</TooltipContent>
    </Tooltip>

    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="ghost" size="sm" onClick={onFitView}>
          <Maximize2 className="h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Fit view</TooltipContent>
    </Tooltip>
  </Panel>
));

export default Playground;
