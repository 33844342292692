import { useMemo } from "react";
import { BigQueryValue, BigQueryRow } from "@/types/bigquery";
import { formatValue } from "../../../utils/formatters";

export const useTableColumns = (rows: BigQueryRow[]) => {
  return useMemo(() => {
    if (rows.length === 0) return [];
    return Object.keys(rows[0]).map((key) => ({
      title: key,
      dataIndex: key,
      key: key,
      render: (value: BigQueryValue) => formatValue(value),
    }));
  }, [rows]);
};