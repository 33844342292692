import React from "react";
import {
  Upload,
  Database,
  FileSpreadsheet,
  FileText,
  ArrowRight,
  Table,
} from "lucide-react";
import { Button } from "@/components/Common";
import { motion } from "framer-motion";

interface NoDataSourcePromptProps {
  onImportFiles: () => void;
}

const NoDataSourcePrompt: React.FC<NoDataSourcePromptProps> = ({
  onImportFiles,
}) => {
  const supportedFormats = [
    {
      icon: <FileSpreadsheet className="h-8 w-8 text-green-500" />,
      name: "CSV",
      description: "Import data from CSV files",
    },
    {
      icon: <FileSpreadsheet className="h-8 w-8 text-blue-500" />,
      name: "Excel",
      description: "Load Excel spreadsheets",
    },
    {
      icon: <FileText className="h-8 w-8 text-blue-600" />,
      name: "Word",
      description: "Extract tables from docs",
    },
  ];

  return (
    <div className="h-full flex items-center justify-center p-8">
      <div className="max-w-2xl w-full">
        {/* Header Section */}
        <div className="text-center mb-12">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="mb-6"
          >
            <Database className="h-16 w-16 text-blue-500 mx-auto" />
          </motion.div>
          <h2 className="text-3xl font-bold mb-4">Connect Your Data</h2>
          <p className="text-gray-600 dark:text-gray-300 text-lg max-w-md mx-auto">
            Get started by importing your files or connecting to a database
          </p>
        </div>

        {/* Quick Actions */}
        <div className="grid md:grid-cols-2 gap-4 mb-12">
          <Button
            size="lg"
            className="h-auto py-6 flex items-center justify-center gap-4"
            onClick={onImportFiles}
          >
            <Upload className="w-6 h-6" />
            <div className="text-left">
              <div className="font-semibold">Import Files</div>
              <div className="text-sm opacity-90">Upload your data files</div>
            </div>
            <ArrowRight className="w-5 h-5 ml-auto" />
          </Button>

          <Button
            variant="outline"
            size="lg"
            className="h-auto py-6 flex items-center justify-center gap-4"
          >
            <Database className="w-6 h-6" />
            <div className="text-left">
              <div className="font-semibold">Connect Database</div>
              <div className="text-sm opacity-90">Link your data warehouse</div>
            </div>
            <ArrowRight className="w-5 h-5 ml-auto" />
          </Button>
        </div>

        {/* Supported Formats */}
        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-6">
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
            SUPPORTED FILE FORMATS
          </h3>
          <div className="grid grid-cols-3 gap-4">
            {supportedFormats.map((format, index) => (
              <motion.div
                key={format.name}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: index * 0.1 }}
                className="text-center p-4 bg-white dark:bg-gray-800 rounded-lg"
              >
                <div className="mb-2 flex justify-center">{format.icon}</div>
                <div className="font-medium mb-1">{format.name}</div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  {format.description}
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Help Text */}
        <div className="mt-8 text-center text-sm text-gray-500 dark:text-gray-400">
          Need help getting started?
          <a href="#" className="text-blue-500 hover:text-blue-600 ml-1">
            Check our documentation
          </a>
        </div>
      </div>
    </div>
  );
};

export default NoDataSourcePrompt;
