import React from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/Common/Card";
import { Badge } from "@/components/Common/Badge";
import { Button } from "@/components/Common/Button";
import { CreditCard, Clock } from 'lucide-react';

const BillingSettings = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-2xl font-semibold">
          Billing
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="p-4 rounded-lg border border-gray-200 dark:border-gray-700">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <CreditCard className="w-5 h-5 text-gray-500" />
                <h3 className="font-medium">Current Plan</h3>
              </div>
              <Badge variant="outline">Free Trial</Badge>
            </div>
            <p className="text-sm text-gray-500 mb-4">
              Your free trial will end in 14 days
            </p>
            <Button variant="default">Upgrade Plan</Button>
          </div>

          <div className="text-sm text-gray-500 italic flex items-center gap-2">
            <Clock className="w-4 h-4" />
            More billing features coming soon
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BillingSettings;