import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { authService } from "@/services/authService";

import { UserAuthStatus } from "./constants";
import { useTeam } from "./TeamContext";

interface AuthContextType {
  status: UserAuthStatus;
  error: Error | null;
  teamId: string;
  login: (userData: any) => void;
  logout: () => void;
  updateAuthStatus: (status: UserAuthStatus) => void;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const { updateWarehouseDetails, updateUserDetails, userId = '', teamId = '' } = useTeam();

  const [status, setStatus] = useState<UserAuthStatus | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const shouldCheckAuth = () => {
    const isLoginPage = location.pathname === "/login";

    return !isLoginPage;
  };

  useEffect(() => {
    const fetchAuthProgress = async () => {
      if (!shouldCheckAuth()) return;

      setIsLoading(true);
      try {
        const authResponse = await authService.getAuthProgress(teamId, userId);
        setStatus(authResponse.status);
        updateWarehouseDetails(authResponse.client);
        updateUserDetails(authResponse.user);
        localStorage.setItem("setupProgress", authResponse.status);
      } catch (err) {
        setStatus(UserAuthStatus.Unauthorized);
        setError(err);
        const link = err?.response?.data?.authUrl;
        if (link) {
          window.location.href = link;
        }
        console.error("Error fetching setup progress:", err);
      } finally {
        setIsLoading(false);
      }
    };

    // Check the user's auth status:
    // - In the setup flow
    // - In the dashboard flow
    // If the teamId and and userId already existed on the localStorage [meaning has been put there already]
    // if (teamId && userId) return 
    fetchAuthProgress();
  }, [status, userId, teamId, location.pathname]);

  const login = (userData: any) => {};

  const logout = () => {};

  const updateAuthStatus = (status: AuthContextType) => {
    setStatus(status);
  };

  return (
    <AuthContext.Provider
      value={{
        status,
        updateAuthStatus,
        error,
        login,
        logout,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
