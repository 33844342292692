import React, { useState } from "react";
import { Copy, Download, Info, ChevronDown, ChevronUp } from "lucide-react";
import { Table, Button } from "@/components/Common";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/Common/Tooltip";
import { PopoverWrapper } from "@/components/Common/Popover";
import TableInfo from "@/components/Dashboard/Chat/TableInfo";
import { motion, AnimatePresence } from "framer-motion";

import { useCSVOperations } from "@/hooks/useCSVOperation";
import { useTableColumns } from "./useTableColumns";

import { BigQueryResultTableProps } from "@/types/bigquery";

const BigQueryResultTable: React.FC<
  BigQueryResultTableProps & { isVisible: boolean }
> = ({ data, isVisible }) => {
  const [expanded, setExpanded] = useState(false);

  const columns = useTableColumns(data.result.rows);
  const { copyAsCSV, downloadCSV } = useCSVOperations(
    columns,
    data.result.rows
  );

  const visibleRows = expanded
    ? data.result.rows
    : data.result.rows.slice(0, 5);

  const truncatedColumns = columns.map((column) => ({
    ...column,
    render: (value: any) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="max-w-[150px] truncate">{value?.value ? value?.value : String(value)}</div>
        </TooltipTrigger>
        <TooltipContent>{value?.value ? value?.value : String(value)}</TooltipContent>
      </Tooltip>
    ),
  }));

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
          className="flex items-start space-x-4 overflow-hidden"
        >
          <div
            className="relative border rounded-lg shadow-sm overflow-hidden"
            style={{ width: "600px" }}
          >
            <div className="max-h-[300px] overflow-auto">
              <Table
                columns={truncatedColumns}
                dataSource={visibleRows}
                pagination={false}
                size="small"
              />
            </div>

            {data.result.rows.length > 5 && (
              <div className="text-center py-2">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={() => setExpanded(!expanded)}
                      className="text-blue-500 hover:text-blue-700"
                      variant="ghost"
                    >
                      {expanded ? (
                        <>
                          <ChevronUp size={16} className="inline mr-1" />
                          Show Less
                        </>
                      ) : (
                        <>
                          <ChevronDown size={16} className="inline mr-1" />
                          Show More ({data.result.rows.length - 5} more rows)
                        </>
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {expanded ? "Show fewer rows" : "Show more rows"}
                  </TooltipContent>
                </Tooltip>
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-2">
            <PopoverWrapper
              trigger={
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      className="p-2 hover:bg-gray-100 rounded"
                      variant="ghost"
                      size="sm"
                    >
                      <Info size={16} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>View Query Information</TooltipContent>
                </Tooltip>
              }
              content={<TableInfo data={data} />}
            />
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={copyAsCSV}
                  className="p-2 hover:bg-gray-100 rounded"
                  variant="ghost"
                  size="sm"
                >
                  <Copy size={16} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Copy as CSV</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={downloadCSV}
                  className="p-2 hover:bg-gray-100 rounded"
                  variant="ghost"
                  size="sm"
                >
                  <Download size={16} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Download as CSV</TooltipContent>
            </Tooltip>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BigQueryResultTable;
