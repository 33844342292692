import React from "react";
import { Users, Info, ShieldCheck, Building } from "lucide-react";
import { Label } from "@/components/Common/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Common/Select";
import { Badge } from "@/components/Common/Badge";

export const UserPermissionsConfig = () => {
  return (
    <div className="relative">
      {/* Coming Soon Overlay */}
      <div className="absolute inset-0 bg-white/60 dark:bg-gray-800/60 backdrop-blur-[1px] z-20 rounded-lg flex flex-col items-center justify-center">
        <div className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-4 py-2 rounded-full text-sm font-medium flex items-center gap-2 mb-2 shadow-lg">
          <ShieldCheck className="w-4 h-4" />
          Coming Soon
        </div>
        <p className="text-sm text-gray-600 dark:text-gray-300 max-w-md text-center px-4">
          Advanced user permissions management will be available in the next
          update
        </p>
      </div>

      {/* Content (blurred by overlay) */}
      <div className="space-y-6">
        {/* Info Banner */}
        <div className="rounded-lg bg-blue-50 dark:bg-blue-900/20 p-4">
          <div className="flex items-start gap-3">
            <Users className="w-5 h-5 text-blue-500 mt-0.5" />
            <div className="flex-1">
              <h4 className="text-sm font-medium text-blue-900 dark:text-blue-200 mb-1">
                Query Access Management
              </h4>
              <p className="text-sm text-blue-900/90 dark:text-blue-200/90">
                Control which users and departments can access and execute this
                query. Set granular permissions based on roles and
                organizational structure.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-8 gap-y-6">
          {/* Left Column */}
          <div className="space-y-6">
            <div className="space-y-2">
              <Label>Department Access</Label>
              <Select disabled>
                <SelectTrigger>
                  <SelectValue placeholder="Select departments" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Departments</SelectItem>
                  <SelectItem value="finance">Finance</SelectItem>
                  <SelectItem value="operations">Operations</SelectItem>
                  <SelectItem value="analytics">Analytics</SelectItem>
                </SelectContent>
              </Select>
              <p className="text-xs text-gray-500">
                Specify which departments can access this query
              </p>
            </div>

            <div className="space-y-2">
              <Label>Role-Based Access</Label>
              <Select disabled>
                <SelectTrigger>
                  <SelectValue placeholder="Select roles" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Roles</SelectItem>
                  <SelectItem value="admin">Administrators</SelectItem>
                  <SelectItem value="analyst">Data Analysts</SelectItem>
                  <SelectItem value="viewer">Viewers</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <div className="rounded-lg border border-gray-200 dark:border-gray-700 p-4 space-y-4">
              <h4 className="text-sm font-medium flex items-center gap-2">
                <Building className="w-4 h-4" />
                Organization Structure
              </h4>
              <div className="space-y-2">
                {["Finance", "Operations", "Analytics", "Marketing"].map(
                  (dept) => (
                    <div
                      key={dept}
                      className="flex items-center justify-between"
                    >
                      <span className="text-sm text-gray-600 dark:text-gray-300">
                        {dept}
                      </span>
                      <Badge
                        variant="outline"
                        className="bg-gray-50 text-gray-600 dark:bg-gray-800 dark:text-gray-300"
                      >
                        0 users
                      </Badge>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="rounded-lg border border-gray-200 dark:border-gray-700 p-4">
              <div className="flex justify-between items-center mb-3">
                <h4 className="text-sm font-medium">Access Levels</h4>
                <Badge variant="outline" className="bg-gray-50">
                  Coming Soon
                </Badge>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
                  <span className="w-2 h-2 rounded-full bg-green-500"></span>
                  Full Access
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
                  <span className="w-2 h-2 rounded-full bg-yellow-500"></span>
                  Execute Only
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
                  <span className="w-2 h-2 rounded-full bg-red-500"></span>
                  View Only
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
