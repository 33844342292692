import React from "react";
import { MessageSquare } from "lucide-react";

const blogPosts = [
  {
    title: "Getting Started with BigQuery",
    url: "/blog/getting-started-bigquery",
  },
  {
    title: "10 Tips for Optimizing Your Queries",
    url: "/blog/10-query-optimization-tips",
  },
  {
    title: "Understanding Data Warehousing",
    url: "/blog/understanding-data-warehousing",
  },
  { title: "BigQuery Best Practices", url: "/blog/bigquery-best-practices" },
];

const WelcomeScreen: React.FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-8">
    <div className="flex flex-col items-center justify-center max-w-md">
      <MessageSquare size={64} className="text-blue-500 mb-6" />
      <h2 className="text-2xl font-bold mb-4 text-center">
        Welcome to BigQuery Chat
      </h2>
      <p className="text-gray-600 dark:text-gray-300 mb-8 text-center">
        Ask questions about your data or explore our blog posts to learn more
        about BigQuery and data analysis.
      </p>
      <div className="w-full max-w-md">
        {/* 
        TODO:
        <h3 className="text-lg font-semibold mb-3">Recommended Reading:</h3>
        <ul className="space-y-2">
          {blogPosts.map((post, index) => (
            <li key={index}>
              <a
                href={post.url}
                className="text-blue-500 hover:text-blue-700 dark:hover:text-blue-300"
              >
                {post.title}
              </a>
            </li>
          ))}
        </ul> */}
      </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
