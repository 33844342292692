import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertCircle, Database, Code, BarChart } from "lucide-react";

import slackLogo from "../assets/slack-logo.png";

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../components/Common/Card";
import { Button } from "../components/Common";

import { SetupStep, useSetup } from "../context/SetupContext";
import { useTeam } from "../context/TeamContext";

import { setupService } from "../services/setupService";

interface UsageOptions {
  useApi: boolean;
  runQueries: boolean;
  apiUsage: boolean;
  messenger: string | null;
}

const UsageInformation: React.FC = () => {
  const navigate = useNavigate();
  const { currentStep, updateStep } = useSetup();
  const { teamId } = useTeam();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [usageOptions, setUsageOptions] = useState<UsageOptions>({
    useApi: false,
    runQueries: false,
    apiUsage: false,
    messenger: "slack",
  });

  const handleOptionToggle = (option: keyof UsageOptions) => {
    setUsageOptions((prev) => ({
      ...prev,
      [option]:
        option === "messenger"
          ? prev.messenger === "slack"
            ? null
            : "slack"
          : !prev[option],
    }));
  };

  useEffect(() => {
    if (currentStep !== "usage") {
      navigate(`/setup/${currentStep}`);
    }
  }, [currentStep, navigate]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await setupService.submitUsageInformation(
        teamId as string,
        usageOptions
      );
      await updateStep(response.nextStep as SetupStep, {
        teamId,
        ...response.usageOptions,
      });
    } catch (err) {
      console.error("Failed to submit usage information:", err);
      setError("Failed to submit usage information. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="max-w-4xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-semibold">
          Usage Information
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Button
              disabled
              onClick={() => handleOptionToggle("messenger")}
              variant="outline"
              className={`w-full h-24 flex flex-col items-center justify-center ${
                usageOptions.messenger === "slack"
                  ? "bg-blue-100 border-blue-500"
                  : ""
              }`}
            >
              <img
                src={slackLogo}
                alt="Slack"
                className="h-8 w-8 mb-2 object-contain"
              />
              <span>Slack Integration</span>
            </Button>
            <Button
              onClick={() => handleOptionToggle("runQueries")}
              variant="outline"
              className={`w-full h-24 flex flex-col items-center justify-center ${
                usageOptions.runQueries ? "bg-blue-100 border-blue-500" : ""
              }`}
            >
              <Database className="h-8 w-8 mb-2" />
              <span>Run Queries on Warehouse</span>
            </Button>

            <Button
              onClick={() => handleOptionToggle("useApi")}
              variant="outline"
              className={`w-full h-24 flex flex-col items-center justify-center ${
                usageOptions.useApi ? "bg-blue-100 border-blue-500" : ""
              }`}
            >
              <Code className="h-8 w-8 mb-2" />
              <span>Use API</span>
            </Button>

            <div className="relative">
              <Button
                onClick={() => handleOptionToggle("apiUsage")}
                variant="outline"
                className="w-full h-24 flex flex-col items-center justify-center opacity-50"
                disabled
              >
                <BarChart className="h-8 w-8 mb-2" />
                <span>Performance Reports</span>
              </Button>
              <span className="absolute top-2 right-2 bg-yellow-400 text-yellow-800 text-xs font-semibold px-2 py-1 rounded">
                Coming Soon
              </span>
            </div>
          </div>

          <Button
            onClick={handleSubmit}
            disabled={isLoading || Object.values(usageOptions).every((v) => !v)}
            className="w-full mt-6"
          >
            {isLoading ? "Submitting..." : "Submit Usage Information"}
          </Button>
        </div>

        {error && (
          <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-md flex items-center">
            <AlertCircle className="mr-2 h-5 w-5" />
            <p>{error}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default UsageInformation;
