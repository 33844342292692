import React from 'react';
import { 
  Send, 
  Loader, 
  Code, 
  Database, 
  Upload,
  MoreHorizontal,
  PlusCircle,
  Settings,
  FileSpreadsheet
} from 'lucide-react';
import { Button } from '@/components/Common/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/Common/DropDown";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/Common/Tooltip";

interface ChatActionsProps {
  isLoading: boolean;
  onSend: () => void;
  isQueryPanelOpen: boolean;
  isTablesPanelOpen: boolean;
  onToggleQueryPanel: () => void;
  onToggleTablesPanel: () => void;
  onOpenCsvImport: () => void;
  isCustomDataMode?: boolean;
  hasActiveDataSource?: boolean;
}

const ChatActions: React.FC<ChatActionsProps> = ({
  isLoading,
  onSend,
  isQueryPanelOpen,
  isTablesPanelOpen,
  onToggleQueryPanel,
  onToggleTablesPanel,
  onOpenCsvImport,
  isCustomDataMode = false,
  hasActiveDataSource = false,
}) => {
  const showUploadPrompt = isCustomDataMode && !hasActiveDataSource;

  const primaryActions = [
    {
      icon: Send, //showUploadPrompt ? Upload : Send,
      label: "Send message", // showUploadPrompt ? "Upload File" : "Send message",
      onClick: onSend, //showUploadPrompt ? onOpenCsvImport : onSend,
      isActive: false,
      isLoading: isLoading,
      isPrimary: true,
      // disabled: showUploadPrompt ? false : !hasActiveDataSource
    },
  ];

  const secondaryActions = [
    {
      icon: Database,
      label: "Tables",
      onClick: onToggleTablesPanel,
      isActive: isTablesPanelOpen,
      // disabled: !hasActiveDataSource
    },
    {
      icon: Code,
      label: "Query",
      onClick: onToggleQueryPanel,
      isActive: isQueryPanelOpen,
      // disabled: !hasActiveDataSource
    }
  ];

  const dropdownActions = [
    {
      icon: FileSpreadsheet,
      label: "Upload Files",
      onClick: onOpenCsvImport,
      description: "Import your data files",
      highlight: showUploadPrompt
    },
    {
      icon: PlusCircle,
      label: "New Connection",
      onClick: () => {},
      description: "Connect to a new data source",
      disabled: true
    },
    {
      icon: Settings,
      label: "Settings",
      onClick: () => {},
      description: "Configure chat settings",
      disabled: !hasActiveDataSource
    }
  ];

  return (
    <div className="flex items-center gap-2">
      {/* Primary Actions */}
      {primaryActions.map(({ icon: Icon, label, onClick, isLoading, isPrimary, disabled }) => (
        <Tooltip key={label}>
          <TooltipTrigger>
            <Button
              onClick={onClick}
              disabled={disabled || isLoading}
              className={`
                ${isPrimary 
                  ? "bg-blue-500 hover:bg-blue-600 text-white disabled:bg-gray-300 dark:disabled:bg-gray-700" 
                  : "bg-blue-100 dark:bg-blue-900 text-blue-500 dark:text-blue-300"
                } 
                rounded-full w-10 h-10 flex items-center justify-center transition-all duration-300
              `}
            >
              {isLoading ? (
                <Loader className="h-5 w-5 animate-spin" />
              ) : (
                <Icon className="h-5 w-5" />
              )}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{label}</p>
          </TooltipContent>
        </Tooltip>
      ))}

      {/* Secondary Actions */}
      <div className="flex items-center gap-1 bg-gray-100 dark:bg-gray-800 rounded-full p-1">
        {secondaryActions.map(({ icon: Icon, label, onClick, isActive, disabled }) => (
          <Tooltip key={label}>
            <TooltipTrigger>
              <Button
                variant="ghost"
                size="sm"
                onClick={onClick}
                disabled={disabled}
                className={`
                  rounded-full w-8 h-8 flex items-center justify-center
                  ${isActive 
                    ? "bg-white dark:bg-gray-700 shadow-sm" 
                    : "hover:bg-white dark:hover:bg-gray-700"}
                  ${disabled 
                    ? "opacity-50 cursor-not-allowed" 
                    : ""}
                `}
              >
                <Icon className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{disabled ? "Upload data to enable this feature" : label}</p>
            </TooltipContent>
          </Tooltip>
        ))}

        {/* More Actions Dropdown */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              size="sm"
              className="rounded-full w-8 h-8 flex items-center justify-center hover:bg-white dark:hover:bg-gray-700"
            >
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56 bg-white">
            {dropdownActions.map(({ icon: Icon, label, onClick, description, disabled, highlight }) => (
              <DropdownMenuItem
                key={label}
                onClick={disabled ? undefined : onClick}
                className={`
                  flex items-start p-2 
                  ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                  ${highlight ? 'bg-blue-50 dark:bg-blue-900/20' : ''}
                `}
              >
                <div className="flex items-center gap-2">
                  <Icon className={`h-4 w-4 ${highlight ? 'text-blue-500' : ''}`} />
                  <div>
                    <div className="font-medium">{label}</div>
                    {description && (
                      <div className="text-xs text-gray-500">
                        {disabled ? 'Coming soon' : description}
                      </div>
                    )}
                  </div>
                </div>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default ChatActions;