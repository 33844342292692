import React, { useState } from "react";
import {
  Info,
  Plus,
  X
} from "lucide-react";
import { Input } from "@/components/Common/Input";
import { TextArea } from "@/components/Common/TextArea";
import { Label } from "@/components/Common/Label";
import { Checkbox } from "@/components/Common/Checkbox";
import { Button } from "@/components/Common/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Common/Select";
import { useTeam } from "@/context/TeamContext";

interface EmailConfigProps {
  config: {
    recipients: string[];
    subject: string;
    template: string;
    frequency: string;
    attachCsv: boolean;
    errorNotification: boolean;
  };
  query: any;
  setConfig: (config: any) => void;
}

interface ValidationErrors {
  recipients?: string[];
  subject?: string;
  template?: string;
}

const availableVariables = [
  { name: "{query_name}", description: "Name of the query" },
  { name: "{date}", description: "Current date" },
  { name: "{time}", description: "Query execution time" },
  { name: "{rows_affected}", description: "Number of rows in result" },
  { name: "{processed_bytes}", description: "Amount of data processed" },
  { name: "{cost}", description: "Query execution cost" },
  { name: "{status}", description: "Query execution status" },
  { name: "{result}", description: "Query results summary" },
  { name: "{error_message}", description: "Error message if query failed" },
];

export const validateEmailConfig = (
  config: EmailConfigProps["config"]
): ValidationErrors => {
  const errors: ValidationErrors = {};

  // Recipients validation
  if (config.recipients.length === 0) {
    errors.recipients = ["At least one recipient is required"];
  } else {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const recipientErrors = config.recipients.map((email) =>
      emailRegex.test(email) ? null : "Invalid email address"
    );
    if (recipientErrors.some((error) => error !== null)) {
      errors.recipients = recipientErrors;
    }
  }

  // Subject validation
  if (!config.subject.trim()) {
    errors.subject = "Subject is required";
  } else if (config.subject.length > 200) {
    errors.subject = "Subject must be less than 200 characters";
  } else if (!config.subject.includes("{")) {
    errors.subject = "Subject should include at least one variable";
  }

  // Template validation
  if (!config.template.trim()) {
    errors.template = "Email template is required";
  } else if (config.template.length > 5000) {
    errors.template = "Template is too long (max 5000 characters)";
  } else if (!config.template.includes("{")) {
    errors.template = "Template should include at least one variable";
  }

  return errors;
};

export const EmailConfig: React.FC<EmailConfigProps> = ({
  config,
  setConfig,
  query
}) => {
  const { teamId, warehouseDetails } = useTeam();
  const [isTesting, setIsTesting] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const handleConfigChange = (newConfig: any) => {
    setConfig(newConfig);
    setErrors(validateEmailConfig(newConfig));
  };

  const handleRecipientChange = (index: number, value: string) => {
    const newRecipients = [...config.recipients];
    newRecipients[index] = value;
    handleConfigChange({ ...config, recipients: newRecipients });
  };

  const handleAddRecipient = () => {
    handleConfigChange({
      ...config,
      recipients: [...config.recipients, ""],
    });
  };

  const handleRemoveRecipient = (index: number) => {
    const newRecipients = config.recipients.filter((_, i) => i !== index);
    handleConfigChange({ ...config, recipients: newRecipients });
  };

  const insertVariable = (variable: string) => {
    const textarea = document.getElementById(
      "email-template"
    ) as HTMLTextAreaElement;
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = config.template;
      const before = text.substring(0, start);
      const after = text.substring(end);
      const newTemplate = before + variable + after;
      setConfig({ ...config, template: newTemplate });
    }
  };

  // const handleTestEmail = async () => {
  //   const validationErrors = validateEmailConfig(config);
  //   setErrors(validationErrors);

  //   if (Object.keys(validationErrors).length > 0) {
  //     toast.error("Please fix validation errors before testing");
  //     return;
  //   }

  //   try {
  //     setIsTesting(true);
  //     await toast.promise(
  //       automationService.testEmailNotification({
  //         teamId,
  //         datasetId: warehouseDetails.datasetId,
  //         queryId: query.id,
  //         emailConfig: {
  //           recipients: config.recipients,
  //           subject: `[TEST] ${config.subject}`,
  //           template: config.template,
  //           attachCsv: config.attachCsv,
  //         },
  //       }),
  //       {
  //         loading: "Sending test email...",
  //         success: "Test email sent successfully!",
  //         error: "Failed to send test email",
  //       }
  //     );
  //   } catch (error) {
  //     console.error("Error testing email:", error);
  //   } finally {
  //     setIsTesting(false);
  //   }
  // };

  return (
    <div className="space-y-6">
      {/* Info Banner */}
      <div className="rounded-lg bg-blue-50 dark:bg-blue-900/20 p-4">
        <div className="flex items-start gap-3">
          <Info className="w-5 h-5 text-blue-500 mt-0.5" />
          <div className="flex-1">
            <p className="text-sm text-blue-900 dark:text-blue-200">
              Configure how and when query results will be sent via email. Use
              variables in your templates to include dynamic data.
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-8 gap-y-6">
        {/* Left Column */}
        <div className="space-y-6">
          {/* Recipients */}
          <div className="space-y-2">
            <Label>Recipients</Label>
            <div className="flex flex-col">
            {config.recipients.map((recipient, index) => (
              <div key={index} className="flex gap-2">
                <div className="flex-1">
                  <Input
                    value={recipient}
                    onChange={(e) =>
                      handleRecipientChange(index, e.target.value)
                    }
                    placeholder="email@example.com"
                    className={
                      errors.recipients?.[index] ? "border-red-500" : undefined
                    }
                  />
                  {errors.recipients?.[index] && (
                    <p className="text-xs text-red-500 mt-1">
                      {errors.recipients[index]}
                    </p>
                  )}
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleRemoveRecipient(index)}
                >
                  <X className="w-4 h-4" />
                </Button>
              </div>
            ))}
            <Button
              onClick={handleAddRecipient}
              variant="outline"
              size="sm"
              className="mt-2"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Recipient
            </Button>
            </div>
          </div>
          {/* Notification Frequency */}
          <div className="space-y-2">
            <Label>Notification Frequency</Label>
            <Select
              value={config.frequency}
              onValueChange={(val) =>
                handleConfigChange({ ...config, frequency: val })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select frequency" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="always">Every Run</SelectItem>
                <SelectItem value="success">On Success Only</SelectItem>
                <SelectItem value="failure">On Failure Only</SelectItem>
                <SelectItem value="changes">When Results Change</SelectItem>
              </SelectContent>
            </Select>
          </div>
          {/* Options */}
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="attach-csv"
                checked={config.attachCsv}
                onCheckedChange={(checked) =>
                  handleConfigChange({ ...config, attachCsv: checked })
                }
              />
              <Label htmlFor="attach-csv">Attach results as CSV</Label>
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="error-notification"
                checked={config.errorNotification}
                onCheckedChange={(checked) =>
                  handleConfigChange({ ...config, errorNotification: checked })
                }
              />
              <Label htmlFor="error-notification">
                Send error notifications
              </Label>
            </div>
          </div>
        </div>
        {/* Right Column */}
        <div className="space-y-6">
          {/* Subject */}
          <div className="space-y-2">
            <Label>Subject</Label>
            <Input
              value={config.subject}
              onChange={(e) =>
                handleConfigChange({ ...config, subject: e.target.value })
              }
              placeholder="Query Results: {query_name}"
              className={errors.subject ? "border-red-500" : undefined}
            />
            {errors.subject && (
              <p className="text-xs text-red-500">{errors.subject}</p>
            )}
          </div>
          {/* Email Template */}
          <div className="space-y-2">
            <Label>Email Template</Label>
            <TextArea
              id="email-template"
              placeholder="Query results for {query_name} are ready..."
              value={config.template}
              onChange={(e) =>
                handleConfigChange({ ...config, template: e.target.value })
              }
              rows={4}
            />
            {errors.template && (
              <p className="text-xs text-red-500">{errors.template}</p>
            )}
            <div className="pt-2">
              <Label className="text-xs text-gray-500">
                Click to add to template:
              </Label>
              <div className="mt-1 flex flex-wrap gap-2">
                {availableVariables.map((variable) => (
                  <div
                    key={variable.name}
                    className="inline-flex items-center text-xs bg-gray-100 dark:bg-gray-800 rounded px-2 py-1 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700"
                    onClick={() => insertVariable(variable.name)}
                  >
                    <code className="text-blue-600 dark:text-blue-400">
                      {variable.name}
                    </code>
                    <span className="ml-1 text-gray-500">
                      - {variable.description}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfig;

{
  /* Test Button */
}
{
  /* <div className="flex justify-end">
        <Button
          onClick={handleTestEmail}
          variant="outline"
          disabled={isTesting}
          className="w-full sm:w-auto"
        >
          {isTesting ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Sending Test...
            </>
          ) : (
            <>
              <TestTube2 className="w-4 h-4 mr-2" />
              Send Test Email
            </>
          )}
        </Button>
      </div> */
}
