import React, { useState } from 'react';
import {
  Settings as SettingsIcon,
  Users,
  CreditCard,
  Puzzle,
  Bot,
  ChevronRight
} from 'lucide-react';
import { cn } from '@/components/utils';
import { Separator } from '@/components/Common/Separator';

import ModelSettings from './ModelSettings/ModelSettings';
import Integrations from './Integrations';
import UserManagement from './UserManagement';
import BillingSettings from './BillingSettings';

type SettingTab = {
  id: string;
  label: string;
  icon: React.ElementType;
  component: React.ComponentType;
};

const SETTINGS_TABS: SettingTab[] = [
  {
    id: 'model',
    label: 'Model Settings',
    icon: Bot,
    component: ModelSettings
  },
  {
    id: 'integrations',
    label: 'Integrations',
    icon: Puzzle,
    component: Integrations
  },
  {
    id: 'users',
    label: 'Team Members',
    icon: Users,
    component: UserManagement
  },
  {
    id: 'billing',
    label: 'Billing',
    icon: CreditCard,
    component: BillingSettings
  }
];

const Settings = () => {
  const [activeTab, setActiveTab] = useState<string>(SETTINGS_TABS[0].id);

  const ActiveComponent = SETTINGS_TABS.find(tab => tab.id === activeTab)?.component;

  return (
    <div className="flex gap-6 h-[calc(100vh-4rem)] pt-6">
      {/* Sidebar */}
      <div className="w-64 flex-shrink-0">
        <div className="flex items-center gap-2 px-4 mb-4">
          <SettingsIcon className="w-5 h-5" />
          <h2 className="font-semibold text-lg">Settings</h2>
        </div>
        <Separator className="mb-4" />
        <nav className="space-y-1">
          {SETTINGS_TABS.map((tab) => {
            const Icon = tab.icon;
            return (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={cn(
                  'w-full flex items-center gap-3 px-4 py-2 text-sm font-medium rounded-lg transition-colors',
                  'hover:bg-gray-100 dark:hover:bg-gray-800',
                  'focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500',
                  activeTab === tab.id ? 
                    'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/50' : 
                    'text-gray-700 dark:text-gray-300'
                )}
              >
                <Icon className="w-4 h-4" />
                <span className="flex-1 text-left">{tab.label}</span>
                <ChevronRight 
                  className={cn(
                    "w-4 h-4 transition-transform",
                    activeTab === tab.id ? "opacity-100" : "opacity-0"
                  )} 
                />
              </button>
            );
          })}
        </nav>
      </div>

      {/* Content Area */}
      <div className="flex-1 min-w-0 overflow-auto">
        {ActiveComponent && <ActiveComponent />}
      </div>
    </div>
  );
};

export default Settings;