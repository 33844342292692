import { useQuery } from "@tanstack/react-query";

import { setupService } from "../services/setupService";

export const useFetchTables = (teamId: string, datasetId: string) => {
  return useQuery({
    queryKey: ["tables", teamId, datasetId],
    queryFn: () => setupService.getTablesDataset(teamId, datasetId),
    enabled: !!teamId && !!datasetId,
  });
};
