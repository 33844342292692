import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Loader2, Database, Brain, Layout } from "lucide-react";

interface LoadingState {
  phase: 'connecting' | 'analyzing' | 'layouting' | 'ready' | 'error';
  progress?: number;
  message?: string;
  error?: string;
}

interface LoadingStateConfig {
  icon: React.ComponentType;
  message: string;
  description: string;
  color: string;
}

export const LoadingOverlay: React.FC<{ state: LoadingState }> = ({ state }) => {
  // Define configuration for each loading phase with specific visual and textual feedback
  const phaseConfig: Record<LoadingState['phase'], LoadingStateConfig> = {
    connecting: {
      icon: Database,
      message: "Establishing Connection",
      description: "Setting up secure database connection...",
      color: "text-blue-500",
    },
    analyzing: {
      icon: Brain,
      message: "Analyzing Schema",
      description: "Processing database structure and relationships...",
      color: "text-purple-500",
    },
    layouting: {
      icon: Layout,
      message: "Optimizing Layout",
      description: "Calculating optimal visualization positions...",
      color: "text-green-500",
    },
    ready: {
      icon: Loader2,
      message: "",
      description: "",
      color: "text-primary",
    },
    error: {
      icon: Loader2,
      message: state.error || "An error occurred",
      description: "Please try again or contact support",
      color: "text-red-500",
    },
  };

  // Animation variants for smooth transitions
  const overlayVariants = {
    hidden: { 
      opacity: 0,
      backdropFilter: "blur(0px)",
    },
    visible: { 
      opacity: 1,
      backdropFilter: "blur(4px)",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      }
    },
    exit: { 
      opacity: 0,
      backdropFilter: "blur(0px)",
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      }
    }
  };

  const contentVariants = {
    hidden: { 
      opacity: 0,
      scale: 0.95,
      y: 10,
    },
    visible: { 
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: 0.1,
        ease: "easeOut",
      }
    },
    exit: { 
      opacity: 0,
      scale: 0.95,
      y: -10,
      transition: {
        duration: 0.2,
        ease: "easeIn",
      }
    }
  };

  return (
    <AnimatePresence>
      {state.phase !== 'ready' && (
        <motion.div
          variants={overlayVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="absolute inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50"
        >
          <motion.div
            variants={contentVariants}
            className="text-center space-y-6 p-8 rounded-lg bg-background/50 border shadow-lg max-w-md"
          >
            {/* Loading Icon */}
            <motion.div
              className={`mx-auto ${phaseConfig[state.phase].color}`}
            >
              {React.createElement(phaseConfig[state.phase].icon, {
                className: "h-12 w-12",
              })}
            </motion.div>

            {/* Loading Message */}
            <div className="space-y-2">
              <h3 className="text-lg font-medium">
                {phaseConfig[state.phase].message}
              </h3>
              <p className="text-sm text-muted-foreground">
                {state.message || phaseConfig[state.phase].description}
              </p>
            </div>

            {/* Progress Indicator */}
            {state.progress !== undefined && (
              <motion.div
                className="w-full h-1 bg-muted rounded-full overflow-hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <motion.div
                  className="h-full bg-primary"
                  initial={{ width: "0%" }}
                  animate={{ width: `${state.progress}%` }}
                  transition={{ duration: 0.3 }}
                />
              </motion.div>
            )}

            {/* Additional Context */}
            {state.phase === 'error' && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-sm text-red-400 mt-4"
              >
                <p>Error: {state.error}</p>
                <button
                  onClick={() => window.location.reload()}
                  className="mt-2 text-primary hover:text-primary/80 underline"
                >
                  Reload Page
                </button>
              </motion.div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};