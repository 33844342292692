import React, { memo } from "react";
import { EdgeProps, getBezierPath } from "reactflow";

const RelationshipEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}: EdgeProps) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const color = data.isAccepted ? "green" : data.isRejected ? "red" : "gray";

  return (
    <>
      <path
        id={id}
        style={{ stroke: color, strokeWidth: 2 }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <text>
        <textPath
          href={`#${id}`}
          style={{ fontSize: "12px", fill: color }}
          startOffset="50%"
          textAnchor="middle"
        >
          {data.sourceColumn} → {data.targetColumn}
        </textPath>
      </text>
    </>
  );
};

export default memo(RelationshipEdge);
