import React from "react";
import { Clock, Calendar, Database, RefreshCcw } from "lucide-react";
import {
  formatBytes,
  formatCurrency,
  formatDuration,
} from "../../../../utils/formatters";

interface QueryMetricsProps {
  query: {
    metadata: {
      executionTime: number;
      timestamp: string;
      estimatedBytesProcessed: number;
      estimatedCost: number;
    };
  };
}

export const QueryMetrics: React.FC<QueryMetricsProps> = ({ query }) => {
  const metrics = [
    {
      icon: Clock,
      label: "Execution Time",
      value: formatDuration(query.metadata.executionTime),
    },
    {
      icon: Calendar,
      label: "Last Run",
      value: new Date(query.metadata.timestamp).toLocaleDateString(),
    },
    {
      icon: Database,
      label: "Data Processed",
      value: formatBytes(query.metadata.estimatedBytesProcessed),
    },
    {
      icon: RefreshCcw,
      label: "Estimated Cost",
      value: formatCurrency(query.metadata.estimatedCost),
    },
  ];

  return (
    <div className="grid grid-cols-4 gap-4">
      {metrics.map((metric, idx) => (
        <div key={idx} className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
          <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400 mb-2">
            <metric.icon className="w-4 h-4" />
            <span className="text-sm">{metric.label}</span>
          </div>
          <div className="text-lg font-medium">{metric.value}</div>
        </div>
      ))}
    </div>
  );
};
