export const FILE_TYPE_ICONS = {
  csv: (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M24 2H10C7.79086 2 6 3.79086 6 6V34C6 36.2091 7.79086 38 10 38H30C32.2091 38 34 36.2091 34 34V12L24 2Z" fill="#0F9D58"/>
      <path d="M24 2V12H34L24 2Z" fill="#87CEAC"/>
      <rect x="11" y="18" width="18" height="2" fill="white"/>
      <rect x="11" y="22" width="18" height="2" fill="white"/>
      <rect x="11" y="26" width="18" height="2" fill="white"/>
      <path d="M11 15H19V17H11V15Z" fill="white"/>
    </svg>
  ),
  xlsx: (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M24 2H10C7.79086 2 6 3.79086 6 6V34C6 36.2091 7.79086 38 10 38H30C32.2091 38 34 36.2091 34 34V12L24 2Z" fill="#21A366"/>
      <path d="M24 2V12H34L24 2Z" fill="#95D6A4"/>
      <rect x="11" y="18" width="18" height="2" fill="white"/>
      <rect x="11" y="22" width="18" height="2" fill="white"/>
      <rect x="11" y="26" width="18" height="2" fill="white"/>
      <path d="M11 15H19V17H11V15Z" fill="white"/>
    </svg>
  ),
  docx: (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M24 2H10C7.79086 2 6 3.79086 6 6V34C6 36.2091 7.79086 38 10 38H30C32.2091 38 34 36.2091 34 34V12L24 2Z" fill="#4285F4"/>
      <path d="M24 2V12H34L24 2Z" fill="#9EC2FF"/>
      <rect x="11" y="18" width="18" height="2" fill="white"/>
      <rect x="11" y="22" width="18" height="2" fill="white"/>
      <rect x="11" y="26" width="18" height="2" fill="white"/>
      <path d="M11 15H19V17H11V15Z" fill="white"/>
    </svg>
  ),
  pdf: (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M24 2H10C7.79086 2 6 3.79086 6 6V34C6 36.2091 7.79086 38 10 38H30C32.2091 38 34 36.2091 34 34V12L24 2Z" fill="#EA4335"/>
      <path d="M24 2V12H34L24 2Z" fill="#FF8A80"/>
      <rect x="11" y="18" width="18" height="2" fill="white"/>
      <rect x="11" y="22" width="18" height="2" fill="white"/>
      <rect x="11" y="26" width="18" height="2" fill="white"/>
      <path d="M11 15H19V17H11V15Z" fill="white"/>
    </svg>
  )
};