import React, { useState } from "react";
import {
  Play,
  Trash2,
  Copy,
  Clock,
  Database,
  Sparkles,
  Zap,
  Settings,
  ChevronRight,
} from "lucide-react";
import { formatDistanceToNow } from "date-fns";

import { Button } from "@/components/Common/Button";
import { Badge } from "@/components/Common/Badge";
import QueryAutomationDialog from "./AutomationConfig/QueryAutomationDialog";
import QueryExecutionDialog from "./QueryExecutionDialog";

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/Common/Tooltip";
import {
  formatBytes,
  formatCurrency,
  formatDuration,
} from "../../../utils/formatters";

import { SavedQuery } from "./types";
import { useTeam } from "@/context/TeamContext";

interface QueryCardProps {
  query: SavedQuery;
  onRun: (query: SavedQuery) => void;
  onDelete: (query: SavedQuery) => void;
  onDuplicate: (query: SavedQuery) => void;
  onTagClick: (tag: string) => void;
  isActive: boolean;
}

const QueryCard: React.FC<QueryCardProps> = ({
  query,
  onRun,
  onDelete,
  onDuplicate,
  onTagClick,
  isActive,
}) => {
  const { warehouseDetails, teamId } = useTeam();

  const [isAutomationDialogOpen, setIsAutomationDialogOpen] = useState(false);
  const [isExecutionDialogOpen, setIsExecutionDialogOpen] = useState(false);

  const handleRunQuery = async () => {
    setIsExecutionDialogOpen(true);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-100 dark:border-gray-700 hover:border-blue-200 dark:hover:border-blue-800 transition-all duration-200">
      <div className="p-3">
        {/* Main Row */}
        <div className="flex items-center gap-3">
          {/* Left: Run Button */}

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant={isActive ? "default" : "ghost"}
                size="sm"
                onClick={handleRunQuery}
                disabled={isActive}
                className={`${isActive ? "animate-pulse" : ""} rounded-full`}
              >
                <Play className="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Run query</TooltipContent>
          </Tooltip>

          <QueryExecutionDialog
            query={query}
            isOpen={isExecutionDialogOpen}
            onClose={() => setIsExecutionDialogOpen(false)}
            teamId={teamId}
            datasetId={warehouseDetails.datasetId}
          />

          {/* Middle: Title and Tags */}
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-2">
              <h3 className="font-medium truncate">{query.name}</h3>
              {query.isAutomated && (
                <Badge
                  variant="secondary"
                  className="bg-purple-50 text-purple-700 px-2 py-0.5"
                >
                  <Zap className="w-3 h-3 mr-1" />
                  Auto
                </Badge>
              )}
            </div>
            {/* Metadata Row */}
            <div className="flex items-center gap-3 mt-1 text-xs text-gray-500">
              <span title="Last Run" className="flex items-center">
                <Clock className="w-3 h-3 mr-1" />
                {formatDistanceToNow(new Date(query.metadata.timestamp), {
                  addSuffix: true,
                })}
              </span>
              <span title="Data Processed" className="flex items-center">
                <Database className="w-3 h-3 mr-1" />
                {formatBytes(parseInt(query.metadata.estimatedBytesProcessed))}
              </span>
              <span title="Cost" className="flex items-center">
                <Sparkles className="w-3 h-3 mr-1" />
                {formatCurrency(query.metadata.estimatedCost)}
              </span>
            </div>
          </div>

          {/* Right: Actions */}
          <div className="flex items-center gap-1">
            {/* Tags in a compact dropdown */}
            {query.tags?.length > 0 && (
              <div className="flex items-center gap-1 mr-2">
                {query.tags.slice(0, 2).map((tag) => (
                  <Badge
                    key={tag}
                    variant="secondary"
                    className="bg-blue-50 text-blue-700 cursor-pointer hover:bg-blue-100 text-xs px-2"
                    onClick={() => onTagClick(tag)}
                  >
                    {tag}
                  </Badge>
                ))}
                {query.tags.length > 2 && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Badge
                        variant="secondary"
                        className="bg-gray-50 text-gray-600 text-xs px-2"
                      >
                        +{query.tags.length - 2}
                      </Badge>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="flex flex-wrap gap-1 max-w-xs">
                        {query.tags.slice(2).map((tag) => (
                          <Badge
                            key={tag}
                            variant="secondary"
                            className="bg-blue-50 text-blue-700 text-xs"
                          >
                            {tag}
                          </Badge>
                        ))}
                      </div>
                    </TooltipContent>
                  </Tooltip>
                )}
              </div>
            )}

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => onDuplicate(query)}
                >
                  <Copy className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Duplicate query</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => onDelete(query)}
                >
                  <Trash2 className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Delete query</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsAutomationDialogOpen(true)}
                  className="rounded-full"
                >
                  <Settings className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>View query details</TooltipContent>
            </Tooltip>
          </div>
        </div>
      </div>
      <QueryAutomationDialog
        query={query}
        isOpen={isAutomationDialogOpen}
        onClose={() => setIsAutomationDialogOpen(false)}
      />
    </div>
  );
};

export default QueryCard;
