import React, { memo } from "react";
import { Handle, Position, NodeProps } from "reactflow";

interface TableNodeData {
  name: string;
  isCurrent: boolean;
}

const TableNode: React.FC<NodeProps<TableNodeData>> = ({ data }) => {
  return (
    <div
      className={`px-4 py-2 rounded-md ${
        data.isCurrent
          ? "bg-blue-100 border-2 border-blue-500"
          : "bg-white border border-gray-300"
      }`}
    >
      <Handle type="target" position={Position.Top} />
      <div className="font-bold text-sm">{data.name}</div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default memo(TableNode);
