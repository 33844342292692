import React from "react";
import { Database, Slack, Brain, Users, Mail } from "lucide-react";
import { Checkbox } from "@/components/Common";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/Common/Accordion";
import { BigQueryConfig } from "./Configs/BigQueryConfig.tsx";
import { SlackConfig } from "./Configs/SlackConfig.tsx";
import { FineTuningConfig } from "./Configs/FineTuningConfig.tsx";
import { UserPermissionsConfig } from "./Configs/UserPermissionsConfig.tsx";
import { EmailConfig } from "./Configs/EmailConfig.tsx";

interface AutomationConfigProps {
  query: any;
  config: any; // Replace with proper type
  setConfig: (config: any) => void;
  enabledSections: {
    bigquery: boolean;
    slack: boolean;
    fineTuning: boolean;
    email: boolean;
  };
  setEnabledSections: (sections: any) => void;
}

export const AutomationConfig: React.FC<AutomationConfigProps> = ({
  config,
  query,
  setConfig,
  enabledSections,
  setEnabledSections,
}) => {
  const sections = [
    {
      id: "slack",
      icon: <Slack />,
      title: "Slack Notifications",
      description: "Configure automated notifications",
      isEnabled: enabledSections.slack,
      content: (
        <SlackConfig
          query={query}
          config={config.slack}
          setConfig={(newConfig) =>
            setConfig((prev) => ({ ...prev, slack: newConfig }))
          }
        />
      ),
    },
    {
      id: "email",
      icon: <Mail />,
      title: "Email Notifications",
      description: "Configure automated email reports",
      isEnabled: enabledSections.email,
      content: (
        <EmailConfig
          config={config.email}
          setConfig={(newConfig) =>
            setConfig((prev) => ({ ...prev, email: newConfig }))
          }
        />
      ),
    },
    {
      id: "bigquery",
      icon: <Database />,
      title: "Scheduled Queries",
      description: "Configure automated query execution",
      isEnabled: enabledSections.bigquery,
      isDisabled: true,
      content: (
        <BigQueryConfig
          config={config.bigquery}
          setConfig={(newConfig) =>
            setConfig((prev) => ({ ...prev, bigquery: newConfig }))
          }
        />
      ),
    },
    {
      id: "fineTuning",
      icon: <Brain />,
      title: "Fine Tuning",
      description: "Use this query for model fine-tuning",
      isEnabled: enabledSections.fineTuning,
      content: (
        <FineTuningConfig
          config={config.fineTuning}
          setConfig={(newConfig) =>
            setConfig((prev) => ({ ...prev, fineTuning: newConfig }))
          }
        />
      ),
    },
    {
      id: "permissions",
      icon: <Users />,
      title: "User Permissions",
      description: "Manage query access and permissions",
      isEnabled: false, // enabledSections.permissions,
      isDisabled: true,
      content: <UserPermissionsConfig />,
    },
  ];

  return (
    <Accordion type="single" collapsible className="space-y-4">
      {sections.map((section) => (
        <AccordionItem
          key={section.id}
          value={section.id}
          className={`border rounded-lg transition-all duration-200 ${
            section.isEnabled
              ? "border-blue-200 dark:border-blue-800 bg-blue-50/30 dark:bg-blue-900/10"
              : "border-gray-200 dark:border-gray-700"
          }`}
        >
          <AccordionTrigger className="p-4 hover:no-underline">
            <div className="flex items-center gap-3 w-full">
              <div
                className={`w-5 h-5 mr-2 ${
                  section.isEnabled ? "text-blue-500" : "text-gray-500"
                }`}
              >
                {section.icon}
              </div>
              <div className="flex-1">
                <h3 className="font-medium text-left">{section.title}</h3>
                <p className="text-sm text-gray-500 text-left">
                  {section.description}
                </p>
              </div>
              <div className="mr-4" onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checked={section.isEnabled}
                  onCheckedChange={(checked) =>
                    setEnabledSections((prev) => ({
                      ...prev,
                      [section.id]: !section.isDisabled ? checked : null,
                    }))
                  }
                  className="data-[state=checked]:bg-blue-500 data-[state=checked]:border-blue-500"
                />
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent className="p-4 pt-2">
            <div
              className={
                !section.isEnabled ? "opacity-50 pointer-events-none" : ""
              }
            >
              {section.content}
            </div>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
