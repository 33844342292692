import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { setupService } from "../services/setupService";

import { useTeam } from "./TeamContext";

export type SetupStep =
  | "register"
  | "usage"
  | "connect_warehouse"
  | "dataset_info"
  | "model_selection"
  | "completed";
// | "describe_data"

interface SetupContextType {
  currentStep: SetupStep;
  setCurrentStep: (step: SetupStep) => void;
  updateStep: (step: SetupStep, options: unknown) => Promise<void>;
  isLoading: boolean;
  error: string | null;
}

const SetupContext = createContext<SetupContextType | undefined>(undefined);

export const SetupProvider: React.FC = ({ children }) => {
  const { teamId, setUserId } = useTeam();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<SetupStep>("register");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSetupProgress = async () => {
      setIsLoading(true);
      try {
        // We should not make this call if we dont have jwt token yet
        const step = await setupService.getSetupProgress(teamId);
        setCurrentStep(step);
        localStorage.setItem("setupProgress", step);
        navigate(`/setup/${step}?teamId=${teamId}`);
      } catch (err) {
        setError("Failed to fetch setup progress");
        console.error("Error fetching setup progress:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSetupProgress();
  }, [teamId]);

  const updateStep = async (step: SetupStep, options: unknown) => {
    setIsLoading(true);
    setError(null);
    try {
      // Use a small timeout to ensure the state update has propagated
      await new Promise<void>((resolve) => {
        setCurrentStep(step);
        setTimeout(resolve, 0);
      });

      const queryParams = new URLSearchParams({
        teamId: teamId || "",
        userId: options?.userId || "",
      });
      if (options) {
        Object.entries(options).forEach(([key, value]) => {
          queryParams.append(key, value);
        });
      }
      navigate(`/setup/${step}?${queryParams.toString()}`);
      localStorage.setItem("setupProgress", step);
      if (options?.userId) {
        setUserId(options?.userId);
      }
    } catch (err) {
      setError("Failed to update setup progress");
      console.error("Error updating setup progress:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SetupContext.Provider
      value={{ currentStep, updateStep, isLoading, error }}
    >
      {children}
    </SetupContext.Provider>
  );
};

export const useSetup = () => {
  const context = useContext(SetupContext);
  if (context === undefined) {
    throw new Error("useSetup must be used within a SetupProvider");
  }
  return context;
};
