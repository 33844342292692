import React, { useState } from "react";
import { Copy, Download, ChevronDown, ChevronUp, X } from "lucide-react";
import { Table, Button } from "@/components/Common";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/Common/Tooltip";
import { motion, AnimatePresence } from "framer-motion";

const CSVViewer = ({ data, fileName, onClose }) => {
  const [expanded, setExpanded] = useState(false);

  const columns = Object.keys(data[0] || {}).map((key) => ({
    title: key,
    dataIndex: key,
    key,
    render: (value) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="max-w-[150px] truncate">{String(value)}</div>
        </TooltipTrigger>
        <TooltipContent>{String(value)}</TooltipContent>
      </Tooltip>
    ),
  }));

  const visibleRows = expanded ? data : data.slice(0, 5);

  const copyAsCSV = () => {
    const csvContent =
      Object.keys(data[0]).join(",") +
      "\n" +
      data.map((row) => Object.values(row).join(",")).join("\n");
    navigator.clipboard.writeText(csvContent);
  };

  const downloadCSV = () => {
    const csvContent =
      Object.keys(data[0]).join(",") +
      "\n" +
      data.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.csv`;
    link.click();
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.3 }}
        className="flex items-start space-x-4 overflow-hidden"
      >
        <div
          className="relative border rounded-lg shadow-sm overflow-hidden"
          style={{ width: "600px" }}
          >
          {/* Header */}
          <div className="flex items-center justify-between px-4 py-2 border-b bg-gray-50 dark:bg-gray-800">
            <div className="text-sm font-medium text-gray-700 dark:text-gray-200 flex items-center space-x-2">
              <span className="truncate max-w-[400px]" title={fileName}>
                {fileName}
              </span>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={onClose}
              className="h-8 w-8 p-0 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>

          <div className="max-h-[300px] overflow-auto">
            <Table
              columns={columns}
              dataSource={visibleRows}
              pagination={false}
              size="small"
            />
          </div>

          {data.length > 5 && (
            <div className="text-center py-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={() => setExpanded(!expanded)}
                    className="text-blue-500 hover:text-blue-700"
                    variant="ghost"
                  >
                    {expanded ? (
                      <>
                        <ChevronUp size={16} className="inline mr-1" />
                        Show Less
                      </>
                    ) : (
                      <>
                        <ChevronDown size={16} className="inline mr-1" />
                        Show More ({data.length - 5} more rows)
                      </>
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  {expanded ? "Show fewer rows" : "Show more rows"}
                </TooltipContent>
              </Tooltip>
            </div>
          )}
        </div>

        <div className="flex flex-col space-y-2">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={copyAsCSV}
                className="p-2 hover:bg-gray-100 rounded"
                variant="ghost"
                size="sm"
              >
                <Copy size={16} />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Copy as CSV</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={downloadCSV}
                className="p-2 hover:bg-gray-100 rounded"
                variant="ghost"
                size="sm"
              >
                <Download size={16} />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Download as CSV</TooltipContent>
          </Tooltip>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CSVViewer;
