import React, { useState } from "react";
import { AlertCircle } from "lucide-react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/Common/Card";
import { Button } from "@/components/Common";

import slackLogo from "@/assets/slack-logo.png";
import teamsLogo from "@/assets/teams-logo.png";

interface IntegrationOptions {
  slack: boolean;
  teams: boolean;
}

const Integrations: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [integrationOptions, setIntegrationOptions] =
    useState<IntegrationOptions>({
      slack: false,
      teams: false,
    });

  const handleOptionToggle = (option: keyof IntegrationOptions) => {
    setIntegrationOptions((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Implement your integration submission logic here
      console.log("Submitting integrations:", integrationOptions);
      // Simulating an API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (err) {
      console.error("Failed to submit integrations:", err);
      setError("Failed to submit integrations. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-2xl font-semibold">Integrations</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Button
              onClick={() => handleOptionToggle("slack")}
              variant="outline"
              className={`w-full h-24 flex flex-col items-center justify-center ${
                integrationOptions.slack ? "bg-blue-100 border-blue-500" : ""
              }`}
            >
              <img
                src={slackLogo}
                alt="Slack"
                className="h-8 w-8 mb-2 object-contain"
              />
              <span>Slack Integration</span>
            </Button>

            <div className="relative">
              <Button
                variant="outline"
                className="w-full h-24 flex flex-col items-center justify-center opacity-50"
                disabled
              >
                <img
                  src={teamsLogo}
                  alt="Microsoft Teams"
                  className="h-8 w-8 mb-2 object-contain"
                />
                <span>Microsoft Teams</span>
              </Button>
              <span className="absolute top-2 right-2 bg-yellow-400 text-yellow-800 text-xs font-semibold px-2 py-1 rounded">
                Coming Soon
              </span>
            </div>
          </div>

          <Button
            onClick={handleSubmit}
            disabled={
              isLoading || !Object.values(integrationOptions).some(Boolean)
            }
            className="w-full mt-6"
          >
            {isLoading ? "Submitting..." : "Save Integrations"}
          </Button>
        </div>

        {error && (
          <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-md flex items-center">
            <AlertCircle className="mr-2 h-5 w-5" />
            <p>{error}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Integrations;
