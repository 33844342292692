import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { motion, AnimatePresence } from "framer-motion";
import {
  CheckCircle2,
  AlertCircle,
  Upload,
  X,
  Eye,
  FileText,
  FileSpreadsheet,
  Table,
  ChevronRight,
} from "lucide-react";

import { Button } from "@/components/Common";
import { ProgressBar } from "@/components/Common/ProgressBar";

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/Common/Tooltip";

import { FILE_TYPE_ICONS } from "./Icons/icons";

const ACCEPTED_FILE_TYPES = {
  "text/csv": [".csv"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  // TODO: To support more files:
  //
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
  //   ".docx",
  // ],
  // "text/plain": [".txt"],
  // "application/pdf": [".pdf"],
};

const FILE_TYPE_LABELS = {
  csv: "Spreadsheet",
  xlsx: "Excel",
  docx: "Word",
  pdf: "PDF",
};

const ICON_SIZE = 32;

const FileImportPanel = ({ onClose, onImport, onPreview }) => {
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) => ({
        file,
        id: Math.random().toString(36).substring(7),
        progress: 0,
        status: "pending",
      }))
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILE_TYPES,
    multiple: false,
  });

  const handlePreview = async (file) => {
    try {
      const text = await file.text();
      const rows = text.split("\n").map((row) => row.split(","));
      const headers = rows[0];
      let data = rows.slice(1).map((row) => {
        const obj = {};
        headers.forEach((header, i) => {
          obj[header.trim()] = row[i]?.trim();
        });
        return obj;
      });

      if (data.length > 250) {
        data = data.slice(0, 250);
        // Notify user about truncation

        // TODO: Show the dialog
        //
        // notifyUser("File was truncated to 250 rows for processing");
      }

      onPreview({ data, fileName: file.name });
    } catch (err) {
      setError(err.message);
    }
  };

  // At this point we just pass it to the chat conversation
  // In future we might actually have an endpoint!
  const handleUpload = async () => {
    setUploadStatus("uploading");

    try {
      if (files.length > 0) {
        const text = await files[0]?.file.text();
        const rows = text.split("\n").map((row) => row.split(","));
        const headers = rows[0];
        let data = rows.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, i) => {
            obj[header.trim()] = row[i]?.trim();
          });
          return obj;
        });

        if (data.length > 250) {
          data = data.slice(0, 250);
          // Notify user about truncation

          // TODO: Show the dialog
          //
          // notifyUser("File was truncated to 250 rows for processing");
        }

        onImport({ data, fileName: files[0].file.name });
        onPreview({ data, fileName: files[0].file.name });
        // Handle actual file upload here
        setUploadProgress(100);
        setUploadStatus("success");
      }
    } catch (err) {
      setError(err.message);
      setUploadStatus("error");
    }
  };

  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-900">
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b dark:border-gray-800">
        <div className="flex items-center space-x-2">
          <Upload className="h-5 w-5 text-blue-500" />
          <h2 className="font-semibold">Import Files</h2>
        </div>
        <Button
          variant="ghost"
          size="sm"
          className="h-8 w-8 p-0 rounded-full"
          onClick={onClose}
        >
          <X className="h-4 w-4" />
        </Button>
      </div>

      {/* Drop Zone */}
      <div className="flex-1 p-4 overflow-y-auto">
        <div
          {...getRootProps()}
          className={`
                  relative border-2 border-dashed rounded-lg p-4 transition-all
                  ${
                    isDragActive
                      ? "border-blue-500 bg-blue-50 dark:bg-blue-900/20"
                      : "border-gray-300 dark:border-gray-700 hover:border-gray-300"
                  }
                `}
        >
          <input {...getInputProps()} />

          {/* File Type Icons */}
          <div className="flex justify-center items-center gap-6 mb-4">
            {Object.entries(FILE_TYPE_ICONS).map(([type, icon]) => (
              <motion.div
                key={type}
                whileHover={{ scale: 1.05 }}
                className="flex flex-col items-center"
              >
                <div className="w-10 h-10 mb-1">{icon}</div>
                <span className="text-xs font-medium text-gray-500 dark:text-gray-400">
                  .{type}
                </span>
              </motion.div>
            ))}
          </div>

          <div className="text-center">
            <p className="font-medium text-gray-700 dark:text-gray-200">
              {isDragActive ? "Drop your files here" : "Drag & drop files here"}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
              or{" "}
              <span className="text-blue-500 hover:text-blue-600 cursor-pointer">
                browse
              </span>{" "}
              your files
            </p>
          </div>
        </div>

        {/* File List */}
        {files.length > 0 && (
          <div className="mt-4 space-y-2">
            {files.map(({ file, id, type }) => (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                key={id}
                className="flex items-center p-3 border rounded-lg bg-gray-50 dark:bg-gray-800"
              >
                <div className="w-8 h-8">{FILE_TYPE_ICONS[type]}</div>
                <div className="ml-3 flex-1 truncate">
                  <div className="font-medium text-sm text-gray-900 dark:text-gray-100 truncate">
                    {file.name}
                  </div>
                  <div className="text-xs text-gray-500">
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                  </div>
                </div>
                <div className="flex space-x-2">
                  {/* {file.name.endsWith(".csv") && (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handlePreview(file)}
                          className="h-8 w-8 p-0"
                        >
                          <Eye className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Preview in chat</TooltipContent>
                    </Tooltip>
                  )} */}
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setFiles(files.filter((f) => f.id !== id))}
                    className="h-8 w-8 p-0 hover:bg-red-50 hover:text-red-500 dark:hover:bg-red-900/20"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              </motion.div>
            ))}
          </div>
        )}

        {/* Upload Progress */}
        {uploadStatus === "uploading" && (
          <div className="mt-4 space-y-2">
            {/* <Progress value={uploadProgress} /> */}
            <p className="text-sm text-center text-gray-500">
              Uploading... {uploadProgress}%
            </p>
          </div>
        )}

        {/* Error State */}
        {error && (
          <div className="mt-4 p-3 bg-red-50 text-red-500 rounded-lg flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}
      </div>

      {/* Footer */}
      <div className="border-t dark:border-gray-800 p-4">
        <div className="flex justify-end space-x-2">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="outline"
            disabled={files.length === 0 || uploadStatus === "uploading"}
            onClick={handleUpload}
          >
            {uploadStatus === "uploading" ? (
              "Uploading..."
            ) : uploadStatus === "success" ? (
              <span className="flex items-center">
                <CheckCircle2 className="h-4 w-4 mr-2" />
                Done
              </span>
            ) : (
              "Attach to the chat"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FileImportPanel;
