import React, { createContext, useContext, useState, useEffect } from "react";

import { Table } from "@/types/types";

interface DatasetDetails {
  tables: Table[];
  clusters: string[];
  datasetId: string;
}

interface WarehouseDetails {
  projectId: string;
  datasetId: string;
  teamId: string;
  warehouse: string;
}

interface TeamContextType {
  teamId: string | null;
  userId: string | null;
  userRole: string | null;
  warehouseDetails: WarehouseDetails | null;
  setTeamId: (id: string | null) => void;
  setUserId: (id: string | null) => void;
  setUserRole: (role: string) => void;
  clearUserDataFromStore: () => void;
  updateWarehouseDetails: (details: Partial<WarehouseDetails>) => void;
  datasetDetails: DatasetDetails | null;
  updateDatasetDetails: (details: DatasetDetails) => void;
  updateUserDetails: (details: {id: number; role: string;}) => void;
}

const TeamContext = createContext<TeamContextType | undefined>(undefined);

export const TeamProvider: React.FC = ({ children }) => {
  const [teamId, setTeamId] = useState<string | null>(() =>
    localStorage.getItem("teamId")
  );

  const [userId, setUserId] = useState<string | null>(() =>
    localStorage.getItem("userId")
  );

  const [userRole, setUserRole] = useState<string | null>();

  const [datasetDetails, setDatasetDetails] = useState<DatasetDetails | null>(
    () => {
      const stored = localStorage.getItem("datasetDetails");
      return stored ? JSON.parse(stored) : null;
    }
  );

  const [warehouseDetails, setWarehouseDetails] =
    useState<WarehouseDetails | null>(() => {
      // Try to get from localStorage first
      const stored = localStorage.getItem("warehouseDetails");
      if (stored) return JSON.parse(stored);

      // Fall back to URL params
      const searchParams = new URLSearchParams(window.location.search);
      const projectId = searchParams.get("projectId");
      const datasetId = searchParams.get("datasetId");

      if (projectId && datasetId) {
        return {
          projectId,
          datasetId,
          teamId: localStorage.getItem("teamId") || "",
          warehouse: localStorage.getItem("warehouse") || "",
        };
      }

      return null;
    });

  // Up on landing to /dashboard from google auth
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userDataParam = params.get("userData");

    if (userDataParam) {
      try {
        const userData = JSON.parse(decodeURIComponent(userDataParam));

        // Set user data
        setUserId(userData.user.id);
        setUserRole(userData.user.role);

        // Team Id
        setTeamId(userData.client.teamId);

        // Set warehouse details
        setWarehouseDetails({
          datasetId: userData.client.datasetId,
          projectId: userData.client.projectId,
          warehouse: userData.client.warehouse,
          teamId: userData.client.teamId,
        });

        // Clean URL
        window.history.replaceState({}, "", "/dashboard");
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (teamId) {
      localStorage.setItem("teamId", teamId);
    } else {
      localStorage.removeItem("teamId");
    }
  }, [teamId]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem("userId", userId);
    } else {
      localStorage.removeItem("userId");
    }
  }, [userId]);

  useEffect(() => {
    if (userRole) {
      localStorage.setItem("userRole", userRole);
    } else {
      localStorage.removeItem("userRole");
    }
  }, [userRole]);

  useEffect(() => {
    if (datasetDetails) {
      localStorage.setItem("datasetDetails", JSON.stringify(datasetDetails));
    } else {
      localStorage.removeItem("datasetDetails");
    }
  }, [datasetDetails]);

  useEffect(() => {
    if (warehouseDetails) {
      localStorage.setItem(
        "warehouseDetails",
        JSON.stringify(warehouseDetails)
      );
    } else {
      localStorage.removeItem("warehouseDetails");
    }
  }, [warehouseDetails]);

  const updateDatasetDetails = (details: DatasetDetails) => {
    setDatasetDetails(details);
  };

  const updateWarehouseDetails = (details: Partial<WarehouseDetails>) => {
    setWarehouseDetails((prev) => {
      if (!prev) return details as WarehouseDetails;
      return { ...prev, ...details };
    });
  };

  const updateUserDetails = (user: {id: string; role: string;}) => {
    setUserId(user.id)
    setUserRole(user.role);
  };

  const clearUserDataFromStore = () => {
    updateWarehouseDetails({});
    setTeamId(null);
    setUserId(null);
  };

  return (
    <TeamContext.Provider
      value={{
        teamId,
        userId,
        datasetDetails,
        setTeamId,
        setUserId,
        userRole,
        setUserRole,
        warehouseDetails,
        clearUserDataFromStore,
        updateWarehouseDetails,
        updateUserDetails,
        updateDatasetDetails,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => {
  const context = useContext(TeamContext);
  if (context === undefined) {
    throw new Error("useTeam must be used within a TeamProvider");
  }
  return context;
};
