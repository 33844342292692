import React, { useState, useMemo } from "react";

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../Common/Card";
import { Input } from "../Common/Input";
import { Button } from "../Common/Button";
import {
  Database,
  Search,
  Table,
  Columns,
  ChevronRight,
  ChevronDown,
} from "lucide-react";

import { AnalyzedTableData } from "../../state/types/graphTypes";

interface TableOverviewProps {
  tables: AnalyzedTableData[];
  onStartWizard: () => void;
}

const TableOverview: React.FC<TableOverviewProps> = ({
  tables,
  onStartWizard,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTable, setSelectedTable] = useState<AnalyzedTableData | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const tablesPerPage = 5;

  const filteredTables = useMemo(() => {
    return tables.filter((table) =>
      table.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [tables, searchTerm]);

  const pageCount = Math.ceil(filteredTables.length / tablesPerPage);
  const currentTables = filteredTables.slice(
    (currentPage - 1) * tablesPerPage,
    currentPage * tablesPerPage
  );

  return (
    <Card className="w-full max-w-4xl mx-auto">
      <CardHeader>
        <CardTitle className="flex items-center text-2xl text-gray-600 dark:text-white">
          <Database className="mr-2 h-8 w-8" />
          Your Selected Data source Landscape
        </CardTitle>
        <div className="mt-5 relative">
          <Input
            type="text"
            placeholder="Search tables..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 mt-5"
          />
          <Search className="absolute mt-2 left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid md:grid-cols-2 gap-4">
          <div className="space-y-2 border-r pr-4">
            {currentTables.map((table) => (
              <div
                key={table.id}
                className={`p-2 rounded-md cursor-pointer transition-colors duration-150 ${
                  selectedTable?.id === table.id
                    ? "bg-blue-100 dark:bg-blue-900"
                    : "hover:bg-gray-100 dark:hover:bg-gray-700"
                }`}
                onClick={() => setSelectedTable(table)}
              >
                <div className="flex items-center justify-between">
                  <span className="font-medium truncate">{table.name}</span>
                  <span className="text-sm text-gray-500">
                    {table?.columns.length}
                  </span>
                </div>
              </div>
            ))}
            <div className="flex justify-between items-center mt-4">
              <Button
                onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
                disabled={currentPage === 1}
                variant="outline"
                size="sm"
              >
                Previous
              </Button>
              <span>
                {currentPage} / {pageCount}
              </span>
              <Button
                onClick={() =>
                  setCurrentPage((p) => Math.min(pageCount, p + 1))
                }
                disabled={currentPage === pageCount}
                variant="outline"
                size="sm"
              >
                Next
              </Button>
            </div>
          </div>
          <div>
            {selectedTable ? (
              <div>
                <h3 className="text-lg font-semibold mb-2 flex items-center">
                  <Table className="mr-2 h-5 w-5" />
                  {selectedTable.name}
                </h3>
                <div className="space-y-1">
                  {selectedTable.columns.map((column, index) => (
                    <div key={index} className="flex items-center text-sm">
                      <Columns className="mr-2 h-4 w-4 text-gray-400" />
                      {column.name}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center h-full text-gray-500">
                Select a table to view details
              </div>
            )}
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button onClick={onStartWizard} className="w-full">
          Start Setup Wizard
        </Button>
      </CardFooter>
    </Card>
  );
};

export default TableOverview;
