import React, { useRef, useEffect, useState } from "react";
import { Copy, BarChart2, Table } from "lucide-react";

import ChartWrapper from "@/components/Common/chart/ChartWrapper";
import { Button } from "@/components/Common";

import BigQueryResultTable from "./ResultTable";
import CSVViewer from "./CSVViewer";

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/Common/Tooltip";

interface MessageListProps {
  messages: Array<{ role: string; content: string }>;
  queryResult: any;
  isTableVisible: boolean;
  previewData: boolean;
  onClosePreview: () => void;
}

const MessageItem: React.FC<{ message: { role: string; content: string } }> = ({
  message,
}) => (
  <div
    className={`flex ${
      message.role === "user" ? "justify-end" : "justify-start"
    }`}
  >
    <div
      className={`max-w-3/4 p-3 rounded-lg ${
        message.role === "user"
          ? "bg-blue-100 text-blue-900"
          : "bg-white text-gray-900 dark:bg-gray-800 dark:text-gray-100"
      }`}
    >
      <p className="text-sm">{message?.content || "-"}</p>
    </div>
  </div>
);

const ToggleButton: React.FC<{
  isActive: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
}> = ({ isActive, onClick, icon, label }) => (
  <Tooltip>
    <TooltipTrigger asChild>
      <Button
        variant={isActive ? "outline" : "default"}
        size="sm"
        onClick={onClick}
      >
        {icon}
        {label}
      </Button>
    </TooltipTrigger>
    <TooltipContent>{`View as ${label}`}</TooltipContent>
  </Tooltip>
);

const CopyChartButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Tooltip>
    <TooltipTrigger asChild>
      <Button variant="ghost" size="sm" onClick={onClick}>
        <Copy className="w-4 h-4 mr-2" />
      </Button>
    </TooltipTrigger>
    <TooltipContent>Copy chart as image</TooltipContent>
  </Tooltip>
);

const QueryResultView: React.FC<{
  queryResult: any;
  showChart: boolean;
  setShowChart: (show: boolean) => void;
  copyChartImage: () => void;
  chartRef: React.RefObject<HTMLDivElement>;
}> = ({ queryResult, showChart, setShowChart, copyChartImage, chartRef }) => (
  <div className="border rounded-lg p-4">
    <div className="flex justify-between items-center mb-4">
      <div className="space-x-2">
        <ToggleButton
          isActive={showChart}
          onClick={() => setShowChart(true)}
          icon={<BarChart2 className="w-4 h-4 mr-2" />}
          label="Chart"
        />
        <ToggleButton
          isActive={!showChart}
          onClick={() => setShowChart(false)}
          icon={<Table className="w-4 h-4 mr-2" />}
          label="Table"
        />
      </div>
      {showChart && <CopyChartButton onClick={copyChartImage} />}
    </div>

    {showChart ? (
      <div ref={chartRef}>
        <ChartWrapper
          data={queryResult.result}
          chartType={queryResult.chartType}
        />
      </div>
    ) : (
      <BigQueryResultTable data={queryResult} isVisible={true} />
    )}
  </div>
);

const MessageList: React.FC<MessageListProps> = ({
  messages,
  queryResult,
  isTableVisible,
  previewData,
  onClosePreview,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const [showChart, setShowChart] = useState(true);
  const isChartAvailable = queryResult?.chartType;

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const copyChartImage = () => {
    if (chartRef.current) {
      const svg = chartRef.current.querySelector("svg");
      if (svg) {
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx?.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            if (blob) {
              navigator.clipboard.write([
                new ClipboardItem({ "image/png": blob }),
              ]);
            }
          });
        };
        img.src = "data:image/svg+xml;base64," + btoa(svgData);
      }
    }
  };

  return (
    <div className="h-full overflow-y-auto">
      <div className="p-4 space-y-4">
        {messages.map((message, index) => (
          <MessageItem key={index} message={message} />
        ))}

        {queryResult && isChartAvailable && (
          <QueryResultView
            queryResult={queryResult}
            showChart={showChart}
            setShowChart={setShowChart}
            copyChartImage={copyChartImage}
            chartRef={chartRef}
          />
        )}

        {queryResult && !queryResult.chartType && (
          <BigQueryResultTable data={queryResult} isVisible={isTableVisible} />
        )}

        {previewData && (
          <div className="flex items-start space-x-4">
            <CSVViewer
              data={previewData.data}
              fileName={previewData.fileName}
              onClose={onClosePreview}
              isPreview={true}
            />
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default MessageList;
