import {
    FaGoogle,
    FaSnowflake,
    FaAmazon,
    FaMicrosoft,
    FaDatabase,
  } from "react-icons/fa";

export const warehouses = [
    {
      id: "bigquery",
      name: "Google BigQuery",
      icon: FaGoogle,
      available: true,
    },
    { id: "snowflake", name: "Snowflake", icon: FaSnowflake, available: false },
    {
      id: "redshift",
      name: "Amazon Redshift",
      icon: FaAmazon,
      available: false,
    },
    { id: "azure", name: "Azure Synapse", icon: FaMicrosoft, available: false },
    {
      id: "databricks",
      name: "Databricks",
      icon: FaDatabase,
      available: false,
    },
  ];