import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertCircle } from "lucide-react";


import { Card, CardHeader, CardTitle, CardContent } from "../components/Common/Card";
import { Button } from "../components/Common/Button";

import { useSetup } from "../context/SetupContext";
import { useTeam } from "../context/TeamContext";

import { setupService } from "../services/setupService";

import { warehouses as providers } from "../constants/warehouses";

const ConnectWarehouse: React.FC = () => {
  const navigate = useNavigate();
  const { currentStep } = useSetup();
  const { teamId, userId } = useTeam();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);

  useEffect(() => {
    if (currentStep !== "connect_warehouse") {
      navigate(`/setup/${currentStep}`);
    }
  }, [currentStep, navigate]);

  const handleConnect = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const authUrl = await setupService.initiateGoogleAuth(teamId as string, userId as string);
      window.location.href = authUrl;
    } catch (err) {
      console.error("Failed to initiate Google authentication:", err);
      setError("Failed to start Google authentication. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="max-w-4xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-semibold">Connect Your Account</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {providers.map((provider) => (
            <div
              key={provider.id}
              onClick={() => provider.available && setSelectedProvider(provider.id)}
              className={`rounded-lg p-4 transition-all duration-200 ${
                selectedProvider === provider.id
                  ? "border-2 border-blue-500 bg-blue-50"
                  : provider.available
                  ? "border border-gray-200 hover:border-blue-300 hover:bg-blue-50 cursor-pointer"
                  : "border border-gray-200 opacity-50"
              }`}
            >
              <div className="flex items-center mb-3">
                <provider.icon className="text-2xl mr-3" />
                <h3 className="font-semibold">{provider.name}</h3>
              </div>
              {!provider.available && (
                <span className="text-sm text-gray-500">Coming Soon</span>
              )}
              {selectedProvider === provider.id && provider.available && (
                <Button
                  onClick={handleConnect}
                  disabled={isLoading}
                  className="w-full mt-3 bg-blue-500 hover:bg-blue-600 text-white text-sm font-bold py-2 px-4 rounded "
                >
                  {isLoading ? "Connecting..." : `Connect ${provider.name}`}
                </Button>
              )}
            </div>
          ))}
        </div>
        {error && (
          <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-md flex items-center">
            <AlertCircle className="mr-2 h-5 w-5" />
            <p>{error}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ConnectWarehouse;