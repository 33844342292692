import React from "react";
import { TextArea } from "@/components/Common";
import ChatActions from "./ChatActions";
import {
  Database,
  FileSpreadsheet,
  FileText,
  Plus,
  HelpCircle,
} from "lucide-react";
import { Button } from "@/components/Common";

interface InputAreaProps {
  input: string;
  isLoading: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: () => void;
  onToggleQueryPanel: () => void;
  onToggleTablesPanel: () => void;
  onOpenCsvImport: () => void;
  isQueryPanelOpen: boolean;
  isTablesPanelOpen: boolean;
  isCustomDataMode: boolean;
}

const InputArea: React.FC<InputAreaProps> = ({
  input,
  isLoading,
  onInputChange,
  onSend,
  onToggleQueryPanel,
  onToggleTablesPanel,
  onOpenCsvImport,
  isQueryPanelOpen,
  isTablesPanelOpen,
  isCustomDataMode,
}) => {
  return (
    <div className="border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 p-4">
      <div className="w-full max-w-3xl mx-auto flex flex-col gap-3">
        {isCustomDataMode && !input && (
          <div className="flex items-center gap-2 text-sm text-blue-500 bg-blue-50 dark:bg-blue-900/20 rounded-lg p-2">
            <FileSpreadsheet className="w-4 h-4" />
            <span>Upload a file to start analyzing your data</span>
            <Button
              variant="ghost"
              size="sm"
              className="ml-auto"
              onClick={onOpenCsvImport}
            >
              Upload File
            </Button>
          </div>
        )}

        <div className="flex items-end space-x-2 px-4">
          <TextArea
            value={input}
            onChange={onInputChange}
            placeholder={
              isCustomDataMode
                ? "Upload a file to start chatting..."
                : "Ask about your data..."
            }
            disabled={isCustomDataMode && !input}
            className="flex-1 min-h-[40px] max-h-[120px] p-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 resize-none"
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                onSend();
              }
            }}
          />
          <ChatActions
            isLoading={isLoading}
            onSend={onSend}
            isQueryPanelOpen={isQueryPanelOpen}
            isTablesPanelOpen={isTablesPanelOpen}
            onToggleQueryPanel={onToggleQueryPanel}
            onToggleTablesPanel={onToggleTablesPanel}
            onOpenCsvImport={onOpenCsvImport}
            isCustomDataMode={isCustomDataMode}
          />
        </div>
      </div>
    </div>
  );
};

export default InputArea;
