import React, { useState } from "react";
import {
  X,
  Save,
  AlertCircle,
  Slack,
  Calendar,
  Bell,
  SaveIcon,
  ChevronDown,
  Code,
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Common/Dialog";
import { Input } from "@/components/Common/Input";
import { Button } from "@/components/Common/Button";
import { Badge } from "@/components/Common/Badge";

const SaveQueryDialog = ({
  isOpen,
  onClose,
  onSave,
  query,
  relatedTables = [],
}) => {
  const [queryName, setQueryName] = useState("");
  const [error, setError] = useState("");
  const [showQuery, setShowQuery] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState("");

  const handleTagInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newTag = tagInput.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
        setTagInput("");
      }
    } else if (e.key === "Backspace" && !tagInput && tags.length > 0) {
      setTags(tags.slice(0, -1));
    }
  };

  // Add this handler for removing tags
  const removeTag = (tagToRemove: string) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleSave = () => {
    if (!queryName.trim()) {
      setError("Please enter a query name");
      return;
    }
    onSave({
      name: queryName,
      query,
      tags,
      relatedTables,
    });
    onClose();
  };

  const automationOptions = [
    {
      icon: <Slack />,
      label: "Report to Slack daily",
      description: "Send results to a Slack channel",
    },
    {
      icon: <Calendar />,
      label: "Schedule runs",
      description: "Run on a custom schedule",
    },
    {
      icon: <Bell />,
      label: "Alert on conditions",
      description: "Get notified on conditions",
    },
  ];

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-md p-0">
        <DialogHeader className="p-4 border-b">
          <div className="flex items-center justify-between">
            <DialogTitle className="flex flex-row gap-3 text-lg font-semibold">
              <SaveIcon />
              Save Query
            </DialogTitle>
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8 rounded-full"
              onClick={onClose}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </DialogHeader>

        <div className="divide-y">
          {/* Name Input Section */}
          <div className="p-4">
            <div className="flex gap-2">
              <Input
                value={queryName}
                onChange={(e) => {
                  setQueryName(e.target.value);
                  setError("");
                }}
                placeholder="Enter query name..."
                className={error ? "border-red-500" : ""}
                autoFocus
              />
            </div>
            {error && (
              <div className="flex items-center space-x-1 mt-2 text-red-500 text-sm">
                <AlertCircle className="w-4 h-4" />
                <span>{error}</span>
              </div>
            )}
            <div className="mt-4">
              <div className="flex flex-wrap gap-2 mb-2">
                {tags.map((tag) => (
                  <Badge
                    key={tag}
                    className="bg-blue-50 text-blue-700 dark:bg-blue-900/30 flex items-center gap-1"
                  >
                    {tag}
                    <X
                      className="h-3 w-3 cursor-pointer hover:text-blue-900"
                      onClick={() => removeTag(tag)}
                    />
                  </Badge>
                ))}
              </div>
              <Input
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                onKeyDown={handleTagInput}
                placeholder="Add tags (press Enter or comma to add)"
                className="mt-1"
              />
              <p className="text-xs text-gray-500 mt-1">
                Press Enter or comma to add tags
              </p>
            </div>
            <div className="mt-4">
            <Button onClick={handleSave} className="pointer-curosr" disabled={!queryName.trim()}>
              <Save className="w-4 h-4 pr-1" />
              Save
            </Button>
            </div>
          </div>

          {/* Tables Section */}
          {relatedTables.length > 0 && (
            <div className="p-4">
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <span>Tables:</span>
                <div className="flex flex-wrap gap-1">
                  {relatedTables.map((table) => (
                    <Badge
                      key={table}
                      variant="secondary"
                      className="bg-blue-50 text-blue-700 dark:bg-blue-900/30"
                    >
                      {table}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Query Preview Section */}
          <div className="p-4">
            <button
              onClick={() => setShowQuery(!showQuery)}
              className="flex items-center justify-between w-full text-sm text-gray-600 hover:text-gray-900"
            >
              <div className="flex items-center gap-2">
                <Code className="w-4 h-4" />
                <span>Preview Query</span>
              </div>
              <ChevronDown
                className={`w-4 h-4 transform transition-transform ${
                  showQuery ? "rotate-180" : ""
                }`}
              />
            </button>
            {showQuery && (
              <div className="mt-2 bg-gray-50 rounded-md border">
                <p className="p-3 text-xs overflow-x-auto max-h-32">{query}</p>
              </div>
            )}
          </div>

          {/* Automation Options */}
          {automationOptions.map((option, index) => (
            <div key={index} className="p-4">
              <button
                disabled
                className="w-full flex items-center justify-between opacity-60 cursor-not-allowed"
              >
                <div className="flex items-center gap-3">
                  <div className="text-gray-400">{option.icon}</div>
                  <div className="text-left">
                    <p className="text-sm font-medium">{option.label}</p>
                    <p className="text-xs text-gray-500">
                      {option.description}
                    </p>
                  </div>
                </div>
                <Badge variant="outline" className="ml-2">
                  Soon
                </Badge>
              </button>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SaveQueryDialog;
