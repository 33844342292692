import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import SetupProgress from "../components/SetupProgress";

import { useSetup } from "../context/SetupContext";
import { useTeam } from "../context/TeamContext";

import RegisterOrg from "./RegisterOrg";
import ConnectWarehouse from "./ConnectWarehouse";
import DatasetInfo from "./DatasetInfo";
import ModelSelection from "./ModelSelection";
import CompletedSetup from "./CompletedSetup";
import Usageformation from './UsageInformation';
import DescribeData from "./DefineData";

const pageVariants = {
  initial: { opacity: 0, x: "-100%" },
  in: { opacity: 1, x: 0 },
  out: { opacity: 0, x: "100%" },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5,
};

const SetupWizard: React.FC = () => {
  const { currentStep } = useSetup();
  const { teamId, setTeamId } = useTeam();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlTeamId = params.get("teamId");
    if (urlTeamId && urlTeamId !== teamId) {
      setTeamId(urlTeamId);
    }
  }, [location, setTeamId, teamId]);

  if (location.pathname === "/setup" && !teamId) {
    return <div>Loading...</div>;
  }

  if (location.pathname === "/setup" && teamId) {
    return <Navigate to={`/setup/${currentStep}`} replace />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Setup</h1>
      <SetupProgress />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="register"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <RegisterOrg />
              </motion.div>
            }
          />
          <Route
            path="in_progress"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <RegisterOrg />
              </motion.div>
            }
          />
           <Route
              path="usage"
              element={
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Usageformation/> 
                </motion.div>
              }
            />
          <Route
            path="connect_warehouse"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <ConnectWarehouse />
              </motion.div>
            }
          />
          <Route
            path="dataset_info"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <DatasetInfo />
              </motion.div>
            }
          />
          <Route
            caseSensitive
            path="model_selection"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <ModelSelection />
              </motion.div>
            }
          />
          {/* 
            // TODO:
            // This is going to be passed to the dashbaord and they can do the addiitonal setup there!
            //
            <Route
              path="describe_data"
              element={
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <DescribeData />
                </motion.div>
              }
            /> */}

          <Route
            path="completed"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <CompletedSetup />
              </motion.div>
            }
          />
          <Route
            path="*"
            element={<Navigate to={`/setup/${currentStep}`} replace />}
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default SetupWizard;
