import React, { useState, useEffect } from "react";
import { Settings } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Common/Dialog";
import { CodeEditor } from "@/components/Common/CodeEditor";
import { toast } from "@/components/Common/Toast";

import { AutomationConfig } from "./AutomationConfig";
import { DialogFooter } from "./DialogFooter";
import { QueryMetrics } from "./QueryMetrics";
import { validateSlackConfig } from "./Configs/SlackConfig";
import { validateEmailConfig } from "./Configs/EmailConfig";

import { automationService } from "@/services/automationService";
import { useTheme } from "@/hooks/useTheme";
import { useTeam } from "@/context/TeamContext";

interface QueryAutomationDialogProps {
  query: any; // Replace with proper type
  isOpen: boolean;
  onClose: () => void;
}

const QueryAutomationDialog: React.FC<QueryAutomationDialogProps> = ({
  query,
  isOpen,
  onClose,
}) => {
  const theme = useTheme();
  const { teamId, warehouseDetails } = useTeam();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [config, setConfig] = useState({
    bigquery: {
      scheduleType: "daily",
      startDate: "",
      timeZone: "UTC",
      retryCount: 0,
      timeout: "30m",
      destination: "",
    },
    slack: {
      channelName: "",
      messageTemplate: "",
      frequency: "daily",
      mentionUsers: [],
      threadBehavior: "new",
      errorNotification: true,
      useCustomWebhook: false,
      webhookUrl: "",
    },
    fineTuning: {
      description: "",
      toBeUsed: false,
    },
    email: {
      recipients: [],
      subject: "",
      template: "",
      frequency: "daily",
      attachCsv: false,
      errorNotification: true,
    },
  });

  const [enabledSections, setEnabledSections] = useState({
    bigquery: false,
    slack: false,
    fineTuning: false,
    email: false,
  });

  useEffect(() => {
    if (isOpen && query) {
      fetchAutomationConfig();
    }
  }, [isOpen, query]);

  const fetchAutomationConfig = async () => {
    try {
      setIsLoading(true);
      const automation = await automationService.getQueryAutomation({
        teamId,
        datasetId: warehouseDetails.datasetId,
        queryId: query.id,
      });

      if (automation) {
        // Update section toggles
        setEnabledSections({
          bigquery: automation.isBigQueryEnabled,
          slack: automation.isSlackEnabled,
          fineTuning: automation.isFineTuningEnabled,
          email: automation.isEmailEnabled,
        });

        // Update configurations
        setConfig({
          bigquery: automation.bigQueryConfig || config.bigquery,
          slack: {
            ...config.slack,
            ...(automation.slackChannels?.[0] || {}),
            useCustomWebhook: !!automation.slackChannels?.[0]?.webhookUrl,
          },
          email: {
            ...config.email,
            ...(automation.emailConfigs?.[0] || {}), // Take first email config for now
          },
          fineTuning: automation.fineTuningConfig || config.fineTuning,
        });
      }
    } catch (error) {
      toast.error("Failed to fetch automation configuration");
      console.error("Error fetching automation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);

      const validationErrors: Record<string, any> = {};

      if (enabledSections.email) {
        const emailErrors = validateEmailConfig(config.email);
        if (Object.keys(emailErrors).length > 0) {
          validationErrors.email = emailErrors;
        }
      }

      if (enabledSections.slack) {
        const slackErrors = validateSlackConfig(config.slack);
        if (Object.keys(slackErrors).length > 0) {
          validationErrors.slack = slackErrors;
        }
      }

      if (Object.keys(validationErrors).length > 0) {
        let errorMessage = "Please fix the following validation errors:\n";
        if (validationErrors.email) {
          errorMessage += "- Email Configuration\n";
        }
        if (validationErrors.slack) {
          errorMessage += "- Slack Configuration\n";
        }
        toast.error(errorMessage);
        return;
      }

      const automationConfig = {
        isBigQueryEnabled: enabledSections.bigquery,
        isSlackEnabled: enabledSections.slack,
        isFineTuningEnabled: enabledSections.fineTuning,
        isEmailEnabled: enabledSections.email,
        bigQueryConfig: enabledSections.bigquery ? config.bigquery : null,
        slackChannels: enabledSections.slack
          ? [
              {
                channelName: config.slack.channelName,
                messageTemplate: config.slack.messageTemplate,
                frequency: config.slack.frequency,
                threadBehavior: config.slack.threadBehavior,
                errorNotification: config.slack.errorNotification,
                useCustomWebhook: config.slack.useCustomWebhook,
                webhookUrl: config.slack.useCustomWebhook
                  ? config.slack.webhookUrl
                  : null,
              },
            ]
          : [],
        emailConfigs: enabledSections.email
          ? [
              {
                recipients: config.email.recipients,
                subject: config.email.subject,
                template: config.email.template,
                frequency: config.email.frequency,
                attachCsv: config.email.attachCsv,
                errorNotification: config.email.errorNotification,
              },
            ]
          : [],
        fineTuningConfig: enabledSections.fineTuning ? config.fineTuning : null,
      };

      await automationService.updateQueryAutomation({
        teamId,
        datasetId: warehouseDetails.datasetId,
        queryId: query.id,
        config: automationConfig,
      });
      toast.success("Automation configuration saved successfully");
      onClose();
    } catch (error) {
      toast.error("Failed to save automation configuration");
      console.error("Error saving automation:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[920px] h-[85vh] w-[90vw]">
        <DialogHeader className="border-b pb-4">
          <DialogTitle className="text-xl font-semibold flex items-center gap-2">
            <Settings className="w-5 h-5" />
            Query Automation Settings
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col h-full overflow-hidden">
          <div className="flex-1 overflow-y-auto px-1 py-4 space-y-6">
            {isLoading ? (
              <div className="flex items-center justify-center h-full">
                <div className="loading-spinner" />
              </div>
            ) : (
              <>
                {/* Query Editor */}
                <div className="rounded-lg border border-gray-200 dark:border-gray-700 p-4">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-sm font-medium">Query Configuration</h3>
                  </div>
                  <CodeEditor
                    value={query.query}
                    language="sql"
                    sqlDialect="bigquery"
                    theme={theme.isDarkMode ? "vs-dark" : "light"}
                    readOnly={true}
                    height="200px"
                  />
                </div>

                {/* Metrics */}
                <QueryMetrics query={query} />

                {/* Configuration Sections */}
                <AutomationConfig
                  query={query}
                  config={config}
                  setConfig={setConfig}
                  enabledSections={enabledSections}
                  setEnabledSections={setEnabledSections}
                />
              </>
            )}
          </div>

          <DialogFooter
            enabled={Object.entries(enabledSections)
              .filter(([, enabled]) => enabled)
              .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))}
            onSave={handleSave}
            onClose={onClose}
            isLoading={isSaving}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default QueryAutomationDialog;
