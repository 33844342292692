import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Button,
  Input,
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from "../../Common";

interface ClusterConfirmationProps {
  initialClusters: { [key: string]: string[] };
  onConfirm: (clusters: { [key: string]: string[] }) => void;
  onBack: () => void;
}

const ClusterConfirmation: React.FC<ClusterConfirmationProps> = ({
  initialClusters,
  onConfirm,
  onBack,
}) => {
  const [clusters, setClusters] = useState(initialClusters);
  const [labels, setLabels] = useState({});

  useEffect(() => {
    setLabels(
      Object.keys(clusters).reduce((acc, key) => {
        acc[key] = key;
        return acc;
      }, {})
    );
  }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const sourceClusterId = source.droppableId;
    const destClusterId = destination.droppableId;

    const newClusters = { ...clusters };

    // Remove from the source cluster
    const [movedItem] = newClusters[sourceClusterId].splice(source.index, 1);

    // Add to the destination cluster
    newClusters[destClusterId].splice(destination.index, 0, movedItem);

    // Remove empty clusters
    Object.keys(newClusters).forEach((key) => {
      if (newClusters[key].length === 0) {
        delete newClusters[key];
        const newLabels = { ...labels };
        delete newLabels[key];
        setLabels(newLabels);
      }
    });

    setClusters(newClusters);
  };

  const handleLabelChange = (clusterId: string, label: string) => {
    setLabels((prev) => ({ ...prev, [clusterId]: label }));
  };

  const handleConfirm = () => {
    const labeledClusters = Object.entries(clusters).reduce(
      (acc, [key, value]) => {
        acc[labels[key] || key] = value;
        return acc;
      },
      {}
    );
    onConfirm(labeledClusters);
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Confirm Table Clusters</h2>
      <p className="mb-6">
        Review and adjust the automatically generated clusters. You can drag and
        drop tables between clusters and rename the clusters as needed.
      </p>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.entries(clusters).map(([clusterId, tables]) => (
            <Droppable droppableId={clusterId} key={clusterId}>
              {(provided) => (
                <Card
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="bg-white shadow-md"
                >
                  <CardHeader className="bg-gray-100 p-4">
                    <Input
                      value={labels[clusterId] || ""}
                      onChange={(e) =>
                        handleLabelChange(clusterId, e.target.value)
                      }
                      placeholder={`Label for ${clusterId}`}
                      className="font-semibold"
                    />
                  </CardHeader>
                  <CardContent className="p-4">
                    {tables.map((table, index) => (
                      <Draggable key={table} draggableId={table} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="bg-blue-100 p-2 mb-2 rounded"
                          >
                            {table}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </CardContent>
                </Card>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
      <div className="mt-8 flex justify-between">
        <Button onClick={onBack} variant="outline">
          Back
        </Button>
        <Button onClick={handleConfirm}>Confirm Clusters</Button>
      </div>
    </div>
  );
};

export default ClusterConfirmation;
