import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetup, SetupStep } from "../context/SetupContext";
import { useTeam } from "../context/TeamContext";
import { setupService } from "../services/setupService";
import { FaRobot, FaBrain, FaGoogle, FaEye, FaEyeSlash } from "react-icons/fa";
import { SiOpenai } from "react-icons/si";

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../components/Common/Card";
import { Button } from "../components/Common/Button";
import { AlertCircle } from "lucide-react";
import { useAuth } from "@/context/AuthContext";

enum ModelType {
  OpenAI = "openai",
  WaveQuery = "wavequery",
  Anthropic = "anthropic",
  Gemini = "gemini",
}

const models = [
  { id: ModelType.OpenAI, name: "OpenAI", icon: SiOpenai, available: true },
  {
    id: ModelType.WaveQuery,
    name: "WaveQuery (powered by OpenAI)",
    icon: FaRobot,
    available: false,
  },
  {
    id: ModelType.Anthropic,
    name: "Anthropic",
    icon: FaBrain,
    available: false,
  },
  { id: ModelType.Gemini, name: "Gemini", icon: FaGoogle, available: false },
];

const ModelSelection: React.FC = () => {
  const navigate = useNavigate();
  const { currentStep, updateStep } = useSetup();
  const { updateAuthStatus } = useAuth();
  const { teamId, warehouseDetails, updateDatasetDetails } = useTeam();

  const [selectedModel, setSelectedModel] = useState<ModelType | null>(null);
  const [apiKey, setApiKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showApiKey, setShowApiKey] = useState(false);

  useEffect(() => {
    if (currentStep !== "model_selection") {
      navigate(`/setup/${currentStep}`);
    }
  }, [currentStep, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedModel) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await setupService.submitModelSelection(
        teamId as string,
        {
          modelType: selectedModel,
          apiKey,
        }
      );
      const discovery = await setupService.getDataDiscovery(
        teamId as string,
        warehouseDetails?.datasetId ?? ""
      );
      console.log("discovery", discovery);
      updateDatasetDetails({
        datasetId: discovery.datasetId,
        tables: discovery.tables,
        clusters: discovery.clusters,
      });
      await updateStep(response.nextStep as SetupStep, {
        modelType: selectedModel,
        projectId: warehouseDetails?.projectId,
        datasetId: warehouseDetails?.datasetId,
      });
      await updateAuthStatus(response.nextStep);
    } catch (err) {
      setError("Failed to submit model selection. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-semibold">
            Select LLM Model
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            {models.map((model) => (
              <div
                key={model.id}
                onClick={() => model.available && setSelectedModel(model.id)}
                className={`rounded-lg p-4 transition-all duration-200 ${
                  selectedModel === model.id
                    ? "border-2 border-blue-500 bg-blue-50"
                    : model.available
                    ? "border border-gray-200 hover:border-blue-300 hover:bg-blue-50 cursor-pointer"
                    : "border border-gray-200 opacity-50"
                }`}
              >
                <div className="flex items-center mb-3">
                  <model.icon className="text-2xl mr-3" />
                  <h3 className="font-semibold">{model.name}</h3>
                  {/* {!model.available && (
                    <span className="absolute top-2 right-2 bg-yellow-400 text-yellow-800 text-xs font-semibold px-2 py-1 rounded">
                      Coming Soon
                    </span>
                  )} */}
                </div>
              </div>
            ))}
          </div>

          {selectedModel && (
            <form onSubmit={handleSubmit} className="mt-6">
              <div className="mb-4">
                <label
                  htmlFor="apiKey"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  API Key for {models.find((m) => m.id === selectedModel)?.name}
                </label>
                <div className="relative">
                  <input
                    type={showApiKey ? "text" : "password"}
                    id="apiKey"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    className="block w-full pr-10 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    placeholder="Enter your API key"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowApiKey(!showApiKey)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400 hover:text-gray-500"
                  >
                    {showApiKey ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Your API key is securely stored and never shared.
                </p>
              </div>
              <Button type="submit" disabled={isLoading} className="w-full">
                {isLoading ? (
                  <span className="flex items-center justify-center">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Submitting...
                  </span>
                ) : (
                  "Submit Model Selection"
                )}
              </Button>
            </form>
          )}
        </CardContent>
        {error && (
          <CardFooter>
            <div className="w-full p-3 bg-red-100 border border-red-400 text-red-700 rounded-md flex items-center">
              <AlertCircle className="mr-2 h-5 w-5" />
              <p>{error}</p>
            </div>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};

export default ModelSelection;
