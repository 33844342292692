import React from "react";
import { BigQueryResultTableProps } from "@/types/bigquery";

const TableInfo: React.FC<BigQueryResultTableProps> = ({ data }) => (
  <div>
    <p className="text-sm text-gray-500 dark:text-gray-400">
      Job ID: {data.result.jobId}
    </p>
    <p className="text-sm text-gray-500 dark:text-gray-400">
      Bytes Processed: {data.result.bytesProcessed}
    </p>
    <p className="text-sm text-gray-500 dark:text-gray-400">
      Execution Time: {data.result.executionTime}ms
    </p>
    <p className="text-sm text-gray-500 dark:text-gray-400">
      Estimated Cost: ${data.dryJob.estimatedCost.toFixed(10)}
    </p>
  </div>
);

export default TableInfo;