import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import { cn } from "../utils";

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  indeterminate?: boolean;
  className?: string;
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, indeterminate, ...props }, ref) => {
  const checkboxRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (checkboxRef.current) {
      if (indeterminate !== undefined) {
        (checkboxRef.current as any).indeterminate = indeterminate;
      }
    }
  }, [indeterminate]);

  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        "peer h-5 w-5 shrink-0 rounded-[4px] border border-gray-300 shadow-sm",
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2",
        "disabled:cursor-not-allowed disabled:opacity-50",
        "data-[state=checked]:bg-blue-500 data-[state=checked]:border-blue-500",
        "data-[state=checked]:dark:bg-blue-400 data-[state=checked]:dark:border-blue-400",
        "dark:border-gray-600 dark:bg-gray-800",
        "transition-all duration-200",
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn(
          "flex items-center justify-center text-white dark:text-white",
          indeterminate ? "opacity-50" : "opacity-100"
        )}
      >
        <Check className="h-3.5 w-3.5" strokeWidth={3} />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});

Checkbox.displayName = "Checkbox";

// WithLabel variant for convenience
interface CheckboxWithLabelProps extends CheckboxProps {
  label: React.ReactNode;
  description?: string;
  error?: string;
  labelClassName?: string;
}

const CheckboxWithLabel = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxWithLabelProps
>(({ label, description, error, labelClassName, className, ...props }, ref) => {
  const id = React.useId();

  return (
    <div className="flex items-start space-x-2">
      <Checkbox
        id={id}
        ref={ref}
        className={className}
        {...props}
      />
      <div className="grid gap-1.5 leading-none">
        <label
          htmlFor={id}
          className={cn(
            "text-sm font-medium leading-none",
            props.disabled && "opacity-50",
            labelClassName
          )}
        >
          {label}
        </label>
        {description && (
          <p className={cn("text-sm text-gray-500", props.disabled && "opacity-50")}>
            {description}
          </p>
        )}
        {error && (
          <p className="text-sm text-red-500">{error}</p>
        )}
      </div>
    </div>
  );
});

CheckboxWithLabel.displayName = "CheckboxWithLabel";

// Group component for handling multiple checkboxes
interface CheckboxGroupProps {
  children: React.ReactNode;
  className?: string;
  orientation?: "horizontal" | "vertical";
}

const CheckboxGroup = React.forwardRef<
  HTMLDivElement,
  CheckboxGroupProps
>(({ children, className, orientation = "vertical" }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "flex gap-4",
        orientation === "vertical" ? "flex-col" : "flex-row flex-wrap",
        className
      )}
    >
      {children}
    </div>
  );
});

CheckboxGroup.displayName = "CheckboxGroup";

export { Checkbox, CheckboxWithLabel, CheckboxGroup };

// Usage examples (as a comment):
/*
// Basic checkbox
<Checkbox />

// Checkbox with label
<CheckboxWithLabel
  label="Accept terms"
  description="I agree to the terms of service"
  error="Please accept the terms"
/>

// Checkbox group
<CheckboxGroup>
  <CheckboxWithLabel label="Option 1" />
  <CheckboxWithLabel label="Option 2" />
  <CheckboxWithLabel label="Option 3" />
</CheckboxGroup>

// Horizontal checkbox group
<CheckboxGroup orientation="horizontal">
  <CheckboxWithLabel label="Option 1" />
  <CheckboxWithLabel label="Option 2" />
</CheckboxGroup>

// Indeterminate state
<Checkbox indeterminate />

// Disabled state
<Checkbox disabled />
*/