import { BigQueryValue } from "@/types/bigquery";

export const formatValue = (value: BigQueryValue): string => {
  if (value === null) return "NULL";
  if (typeof value === "object" && "value" in value) {
    return new Date(value.value).toLocaleString();
  }
  return String(value);
};

/**
 * Formats bytes into human readable string
 *
 * @param bytes - Number of bytes to format
 * @param decimals - Number of decimal places to show
 * @returns Formatted string (e.g., "1.5 KB", "2.3 MB", etc.)
 */
export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Formats a duration in milliseconds into a human readable string
 *
 * @param ms - Number of milliseconds
 * @returns Formatted string (e.g., "2.3s", "1.5min", etc.)
 */
export const formatDuration = (ms: number): string => {
  if (ms < 1000) return `${ms}ms`;
  if (ms < 60000) return `${(ms / 1000).toFixed(1)}s`;
  return `${(ms / 60000).toFixed(1)}min`;
};

/**
 * Formats currency values
 *
 * @param value - Number to format
 * @param decimals - Number of decimal places
 * @returns Formatted string (e.g., "$0.00000123")
 */
export const formatCurrency = (value: number, decimals: number = 8): string => {
  return `$${value.toFixed(decimals)}`;
};


/**
 * Generates an array of page numbers to display in pagination
 * 
 * For example, if we're on page 6 of 20 pages, it might return [4,5,6,7,8]
 * If we're on page 1 of 20 pages, it might return [1,2,3,4,5]
 */
export const getPaginationRange = (
  currentPage: number,
  totalPages: number,
  maxVisible: number = 5
): number[] => {
  // If we have fewer pages than the max we want to show
  if (totalPages <= maxVisible) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  // Calculate the midpoint
  const halfWay = Math.ceil(maxVisible / 2);

  // Are we in the first segment?
  if (currentPage <= halfWay) {
    return Array.from({ length: maxVisible }, (_, i) => i + 1);
  }

  // Are we in the last segment?
  if (currentPage > totalPages - halfWay) {
    return Array.from(
      { length: maxVisible }, 
      (_, i) => totalPages - maxVisible + i + 1
    );
  }

  // We're in the middle
  return Array.from(
    { length: maxVisible },
    (_, i) => currentPage - halfWay + i + 1
  );
};