import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { cn } from "../utils";

interface SeparatorProps extends React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> {
  orientation?: "horizontal" | "vertical";
  size?: "sm" | "md" | "lg";
  decorative?: boolean;
}

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  SeparatorProps
>(({ 
  className, 
  orientation = "horizontal", 
  size = "md",
  decorative = true,
  ...props 
}, ref) => {
  const sizes = {
    sm: "1px",
    md: "2px",
    lg: "4px"
  };

  return (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(
        "shrink-0 bg-border",
        orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
        {
          "my-2": orientation === "horizontal" && size === "sm",
          "my-4": orientation === "horizontal" && size === "md",
          "my-6": orientation === "horizontal" && size === "lg",
          "mx-2": orientation === "vertical" && size === "sm",
          "mx-4": orientation === "vertical" && size === "md",
          "mx-6": orientation === "vertical" && size === "lg",
        },
        className
      )}
      style={{
        [orientation === "horizontal" ? "height" : "width"]: sizes[size]
      }}
      {...props}
    />
  );
});

Separator.displayName = "Separator";

export { Separator };