import { useCallback } from "react";
import { BigQueryRow, TableColumn } from "@/types/bigquery";
import { formatValue } from "../utils/formatters";

export const useCSVOperations = (
  columns: TableColumn[],
  rows: BigQueryRow[]
) => {
  const generateCSV = useCallback(() => {
    return [
      columns.map((col) => col.title).join(","),
      ...rows.map((row) =>
        columns
          .map((col) => {
            const value = row[col.dataIndex];
            return `"${formatValue(value)}"`;
          })
          .join(",")
      ),
    ].join("\n");
  }, [columns, rows]);

  const copyAsCSV = useCallback(() => {
    navigator.clipboard.writeText(generateCSV());
  }, [generateCSV]);

  const downloadCSV = useCallback(() => {
    const csv = generateCSV();
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "query_result.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [generateCSV]);

  return { copyAsCSV, downloadCSV };
};
