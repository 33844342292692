import React from 'react';
import { useTheme } from '../../hooks/useTheme';

const ThemeToggle: React.FC = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button
      onClick={toggleTheme}
      className="p-2 rounded dark:bg-gray-700 text-gray-800 dark:text-white"
    >
      {theme.mode === 'light' ? '🌙' : '☀️'}
    </button>
  );
};

export default ThemeToggle;