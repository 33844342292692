import * as React from "react"
import { cn } from "../utils"

interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  columns: { title: string; dataIndex: string; key: string; render?: (text: any, record: any) => React.ReactNode }[];
  dataSource: any[];
  loading?: boolean;
  error?: string | null;
}

const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ className, columns, dataSource, loading, error, ...props }, ref) => {
    if (loading) {
      return <div>Loading...</div>
    }

    if (error) {
      return <div>Error: {error}</div>
    }

    return (
      <div className="overflow-x-auto">
        <table
          ref={ref}
          className={cn("w-full caption-bottom text-sm", className)}
          {...props}
        >
          <thead>
            <tr className="border-b bg-muted">
              {columns.map((column) => (
                <th key={column.key} className="h-12 px-4 text-left align-middle font-medium text-muted-foreground">
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataSource.map((record, index) => (
              <tr key={index} className="border-b">
                {columns.map((column) => (
                  <td key={column.key} className="p-4 align-middle">
                    {column.render ? column.render(record[column.dataIndex], record) : record[column.dataIndex]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
)
Table.displayName = "Table"

export { Table }