import React, { useMemo } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  Node,
  Edge,
} from "reactflow";
import "reactflow/dist/style.css";
import TableNode from "./TableNode";
import RelationshipEdge from "./RelationshipEdge";

const nodeTypes = { tableNode: TableNode };
const edgeTypes = { relationshipEdge: RelationshipEdge };

interface RelationshipGraphProps {
  nodes: Node[];
  edges: Edge[];
}

export const RelationshipGraph: React.FC<RelationshipGraphProps> = ({
  nodes,
  edges,
}) => {
  const styledNodes = useMemo(() => 
    nodes.map(node => ({
      ...node,
      style: {
        opacity: node.data.isCurrent ? 1 : node.data.isRelated ? 0.7 : 0.4,
        background: node.data.isCurrent ? '#3b82f6' : node.data.isRelated ? '#93c5fd' : '#e5e7eb',
      },
    })),
    [nodes]
  );

  const styledEdges = useMemo(() => 
    edges.map(edge => ({
      ...edge,
      style: {
        stroke: edge.data.isAccepted ? '#22c55e' : '#ef4444',
        strokeWidth: edge.data.isCurrent ? 2 : 1,
        opacity: edge.data.isCurrent ? 1 : 0.3,
      },
      animated: !edge.data.isAccepted && edge.data.isCurrent,
    })),
    [edges]
  );

  return (
    <div className="h-full w-full p-4">
      <div className="h-full w-full border border-gray-200 rounded-lg overflow-hidden shadow-inner">
        <ReactFlow
          nodes={styledNodes}
          edges={styledEdges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
        >
          <Background color="#f0f0f0" variant={"dots"} />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </div>
    </div>
  );
};