import React, { useState, useCallback, useMemo, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Database,
  Server,
  CircleSlash,
  CheckCircle2,
  AlertCircle,
  Loader2,
  Globe,
  Lock,
  KeyRound,
  TableProperties,
  X,
  Search,
  Info,
  RefreshCw,
  Eye,
  EyeOff,
  Table,
  History,
} from "lucide-react";
import { usePlayground } from "@/context/PlaygroundContext";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@/components/Common/Tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Common/Select";
import { Input } from "@/components/Common/Input";
import { Button } from "@/components/Common/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/Common/Card";
import { Badge } from "@/components/Common/Badge";
import { Alert, AlertDescription } from "@/components/Common/Alert";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@/components/Common/Tooltip";
import PostgresImage from "@/assets/postgres.png";
import { cn } from "@/components/utils";

// Enhanced interface for connection status with more detailed states
interface ConnectionStatus {
  status:
    | "disconnected"
    | "connecting"
    | "connected"
    | "error"
    | "reconnecting";
  message?: string;
  details?: string;
  timestamp?: Date;
}

export interface SampleDatabase {
  id: string;
  name: string;
  description: string;
  schema: string;
  stats: {
    tables: number;
    // relationships: number;
    // rowCount: number; // Total number of rows across all tables
    // sampleQueries: number; // Number of example queries provided
  };
  complexity: "beginner" | "intermediate" | "advanced";
  category: "entertainment" | "business" | "media";
  features: string[];
  origin: string;
  maintainer: string;
  documentation: string;
  icon: React.ReactNode;
  previewImage?: string;
  connectionConfig: {
    type: "postgres";
    host: string;
    port: number;
    database: string;
    schema: string;
  };
}

export const SAMPLE_DATABASES: SampleDatabase[] = [
  {
    id: "pagila",
    name: "Pagila DVD Rental",
    description:
      "A comprehensive DVD rental store database showcasing complex relationships, temporal data handling, and inventory management. Originally adapted from MySQL's Sakila database.",
    schema: "pagila",
    stats: {
      tables: 22,
      // relationships: ,
      // rowCount: 46_018,
      // sampleQueries: 15,
    },
    complexity: "intermediate",
    category: "entertainment",
    features: [
      "Complex many-to-many relationships",
      "Temporal data tracking",
      "Inventory management",
      "Financial transactions",
      "Location-based operations",
    ],
    origin: "Inspired by MySQL's Sakila database",
    maintainer: "PostgreSQL Community",
    documentation: "https://github.com/devrimgunduz/pagila",
    icon: <Database className="h-5 w-5 text-orange-500" />,
    connectionConfig: {
      type: "postgres",
      host: import.meta.env.VITE_PUBLIC_SUPABASE_PAGILA_HOST!,
      port: 6543,
      database: "postgres",
      schema: "public",
    },
  },
  // {
  //   id: "northwind",
  //   name: "Northwind Traders",
  //   description:
  //     "The classic business database showcasing product management, order processing, and customer relationships. A PostgreSQL adaptation of Microsoft's original sample database.",
  //   schema: "northwind",
  //   stats: {
  //     tables: 13,
  //     relationships: 17,
  //     rowCount: 3_357,
  //     sampleQueries: 12,
  //   },
  //   complexity: "beginner",
  //   category: "business",
  //   features: [
  //     "Order processing workflow",
  //     "Product categorization",
  //     "Customer relationship management",
  //     "Employee territories",
  //     "Shipping logistics",
  //   ],
  //   origin: "Originally by Microsoft for MS Access",
  //   maintainer: "PostgreSQL Community",
  //   documentation: "https://github.com/pthom/northwind_psql",
  //   icon: <Database className="h-5 w-5 text-blue-500" />,
  //   connectionConfig: {
  //     type: "postgres",
  //     host: process.env.NEXT_PUBLIC_SUPABASE_HOST!,
  //     port: 5432,
  //     database: "pg-tests",
  //     schema: "northwind",
  //   },
  // },
  // {
  //   id: "chinook",
  //   name: "Chinook Digital Media",
  //   description:
  //     "A modern digital media store database demonstrating multimedia content management, playlist creation, and digital sales tracking.",
  //   schema: "chinook",
  //   stats: {
  //     tables: 11,
  //     relationships: 14,
  //     rowCount: 12_832,
  //     sampleQueries: 10,
  //   },
  //   complexity: "intermediate",
  //   category: "media",
  //   features: [
  //     "Digital content management",
  //     "Playlist handling",
  //     "Invoice processing",
  //     "Customer accounts",
  //     "Media type categorization",
  //   ],
  //   origin: "Inspired by Apple iTunes",
  //   maintainer: "Chinook Database Project",
  //   documentation: "https://github.com/lerocha/chinook-database",
  //   icon: <Database className="h-5 w-5 text-purple-500" />,
  //   connectionConfig: {
  //     type: "postgres",
  //     host: process.env.NEXT_PUBLIC_SUPABASE_HOST!,
  //     port: 5432,
  //     database: "pg-tests",
  //     schema: "chinook",
  //   },
  // },
];

export const DatabasePanel = () => {
  // Access playground context for state management
  const { panels, setPanels, lastSuccessfulConnection } = usePlayground();
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>({
    status: "disconnected",
  });
  const [showRecentConnections, setShowRecentConnections] = useState(false);

  // Load recent connections from localStorage on mount
  useEffect(() => {
    const recentConnections = localStorage.getItem("recent-connections");
    if (recentConnections) {
      setShowRecentConnections(true);
    }
  }, []);

  // Panel animation variants for smooth transitions
  const panelVariants = {
    hidden: { x: -300, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", damping: 20, stiffness: 300 },
    },
    exit: {
      x: -300,
      opacity: 0,
      transition: { type: "spring", damping: 25 },
    },
  };

  return (
    <AnimatePresence>
      {panels.isLeftPanelOpen && (
        <motion.div
          variants={panelVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="absolute left-4 top-4 w-80 bg-white rounded-lg shadow-lg border overflow-hidden"
        >
          {/* Panel Header */}
          <PanelHeader
            connectionStatus={connectionStatus}
            onClose={() =>
              setPanels((prev) => ({ ...prev, isLeftPanelOpen: false }))
            }
          />

          {/* Main Content */}
          <Tabs
            defaultValue={panels.activeLeftTab}
            onValueChange={(value) =>
              setPanels((prev) => ({
                ...prev,
                activeLeftTab: value as "connect" | "samples",
              }))
            }
            className="p-4"
          >
            <TabsList className="grid grid-cols-2 gap-4 bg-muted p-1">
              <TabsTrigger value="connect" className="flex items-center gap-2">
                <Globe className="h-4 w-4" />
                Connect
              </TabsTrigger>
              <TabsTrigger value="samples" className="flex items-center gap-2">
                <Database className="h-4 w-4" />
                Samples
              </TabsTrigger>
            </TabsList>

            {/* Connection Form */}
            <TabsContent value="connect" className="space-y-4 mt-4">
              {showRecentConnections && lastSuccessfulConnection && (
                <RecentConnections
                  lastConnection={lastSuccessfulConnection}
                  onHide={() => setShowRecentConnections(false)}
                />
              )}
              <ConnectionForm
                onStatusChange={setConnectionStatus}
                initialValues={lastSuccessfulConnection}
              />
            </TabsContent>

            {/* Sample Databases */}
            <TabsContent value="samples" className="mt-4">
              <SampleDatabases onStatusChange={setConnectionStatus} />
            </TabsContent>
          </Tabs>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const PanelHeader: React.FC<{
  connectionStatus: ConnectionStatus;
  onClose: () => void;
}> = ({ connectionStatus, onClose }) => (
  <div className="p-4 border-b flex items-center justify-between bg-muted/50">
    <div className="flex items-center gap-2">
      <Database className="h-5 w-5 text-primary" />
      <div>
        <h3 className="font-semibold">Data Sources</h3>
        <ConnectionStatusIndicator status={connectionStatus} />
      </div>
    </div>
    <Button
      variant="ghost"
      size="sm"
      onClick={onClose}
      className="hover:bg-muted"
    >
      <X className="h-4 w-4" />
    </Button>
  </div>
);

const ConnectionStatusIndicator: React.FC<{ status: ConnectionStatus }> = ({
  status,
}) => {
  const statusConfig = {
    disconnected: {
      icon: CircleSlash,
      color: "text-muted-foreground",
      text: "Not connected",
    },
    connecting: {
      icon: Loader2,
      color: "text-blue-500",
      text: "Connecting...",
    },
    connected: {
      icon: CheckCircle2,
      color: "text-green-500",
      text: "Connected",
    },
    reconnecting: {
      icon: RefreshCw,
      color: "text-yellow-500",
      text: "Reconnecting...",
    },
    error: {
      icon: AlertCircle,
      color: "text-red-500",
      text: status.message || "Connection error",
    },
  }[status.status];

  const Icon = statusConfig.icon;

  return (
    <div className={`flex items-center gap-1.5 text-xs ${statusConfig.color}`}>
      <Icon
        className={`h-3 w-3 ${
          ["connecting", "reconnecting"].includes(status.status)
            ? "animate-spin"
            : ""
        }`}
      />
      <span>{statusConfig.text}</span>
      {status.details && (
        <Tooltip>
          <TooltipTrigger>
            <Info className="h-3 w-3" />
          </TooltipTrigger>
          <TooltipContent>{status.details}</TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};

const RecentConnections: React.FC<{
  lastConnection: any;
  onHide: () => void;
}> = ({ lastConnection, onHide }) => (
  <Card className="bg-muted/50">
    <CardHeader className="p-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <History className="h-4 w-4 text-muted-foreground" />
          <CardTitle className="text-sm">Recent Connection</CardTitle>
        </div>
        <Button variant="ghost" size="sm" onClick={onHide}>
          <X className="h-3 w-3" />
        </Button>
      </div>
    </CardHeader>
    <CardContent className="p-3 pt-0">
      <div className="text-xs space-y-1">
        <div className="flex justify-between text-muted-foreground">
          <span>Host:</span>
          <span>{lastConnection.host}</span>
        </div>
        <div className="flex justify-between text-muted-foreground">
          <span>Database:</span>
          <span>{lastConnection.database}</span>
        </div>
      </div>
    </CardContent>
  </Card>
);

const ConnectionForm: React.FC<{
  onStatusChange: (status: ConnectionStatus) => void;
  initialValues?: any;
}> = ({ onStatusChange, initialValues }) => {
  const { connectToDatabase } = usePlayground();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    type: "postgres",
    host: initialValues?.host || "",
    port: initialValues?.port || "",
    database: initialValues?.database || "",
    username: initialValues?.username || "",
    password: "",
    sslMode: initialValues?.sslMode || "disable",
  });

  // Form validation with detailed error messages
  const validateForm = () => {
    const errors: string[] = [];
    if (!formData.host) errors.push("Host is required");
    if (!formData.port) errors.push("Port is required");
    if (!formData.database) errors.push("Database name is required");
    if (!formData.username) errors.push("Username is required");
    if (!formData.password) errors.push("Password is required");
    return errors;
  };

  const handleConnect = async () => {
    const errors = validateForm();
    if (errors.length > 0) {
      onStatusChange({
        status: "error",
        message: "Validation failed",
        details: errors.join(", "),
      });
      return;
    }

    onStatusChange({ status: "connecting" });
    try {
      await connectToDatabase({
        type: formData.type as any,
        host: formData.host,
        port: parseInt(formData.port),
        database: formData.database,
        username: formData.username,
        password: formData.password,
        sslMode: formData.sslMode,
      });
      onStatusChange({
        status: "connected",
        timestamp: new Date(),
      });
    } catch (error) {
      onStatusChange({
        status: "error",
        message: error instanceof Error ? error.message : "Failed to connect",
        details: "Check your connection details and try again",
      });
    }
  };

  return (
    <div className="space-y-6">
      {/* Database Type Selection */}
      <DatabaseTypeSelector
        value={formData.type}
        onChange={(value) => setFormData((prev) => ({ ...prev, type: value }))}
      />

      {/* Connection Details */}
      <ConnectionDetailsInputs
        formData={formData}
        setFormData={setFormData}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />

      {/* SSL Mode Selection */}
      <SSLModeSelector
        value={formData.sslMode}
        onChange={(value) =>
          setFormData((prev) => ({ ...prev, sslMode: value }))
        }
      />

      {/* Connect Button */}
      <Button
        className="w-full"
        onClick={handleConnect}
        disabled={!formData.host || !formData.database || !formData.username}
      >
        <Database className="h-4 w-4 mr-2" />
        Connect
      </Button>
    </div>
  );
};

const SampleDatabases: React.FC<{
  onStatusChange: (status: ConnectionStatus) => void;
}> = ({ onStatusChange }) => {
  const { loadTestDatabase } = usePlayground();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleLoadSample = async (database: SampleDatabase) => {
    onStatusChange({ status: "connecting" });
    try {
      // Use the database's schema when connecting
      await loadTestDatabase({
        ...database.connectionConfig,
        username: "postgres.trguojfztlaqgkkjqhhy", 
        password: import.meta.env.VITE_PRIVATE_SUPABASE_PASSWORD,
      });
      
      onStatusChange({
        status: "connected",
        message: `Connected to ${database.name}`,
        timestamp: new Date(),
      });
    } catch (error) {
      onStatusChange({
        status: "error",
        message: error instanceof Error ? error.message : "Failed to load sample",
        details: "Please try again or choose a different sample",
      });
    }
  };

  const filteredDatabases = useMemo(() => {
    // Start with all databases
    return SAMPLE_DATABASES.filter(db => {
      // Apply search term filter
      const matchesSearch = searchTerm.trim() === "" || (
        db.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        db.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        db.features.some(feature => 
          feature.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
  
      // Apply category filter
      const matchesCategory = !selectedCategory || db.category === selectedCategory;
  
      // Return true only if both conditions are met
      return matchesSearch && matchesCategory;
    })
    .sort((a, b) => {
      // Sort by complexity first
      const complexityOrder = { beginner: 0, intermediate: 1, advanced: 2 };
      const complexityDiff = 
        complexityOrder[a.complexity] - complexityOrder[b.complexity];
      
      if (complexityDiff !== 0) return complexityDiff;
      
      // If same complexity, sort alphabetically
      return a.name.localeCompare(b.name);
    });
  }, [searchTerm, selectedCategory]);


  return (
    <div className="space-y-4">
      {/* Search and Filter */}
      <div className="space-y-2">
        <div className="relative">
          <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            className="pl-8"
            placeholder="Search samples..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Category Filter */}
        <div className="flex gap-2 flex-wrap">
          {["e-commerce", "education", "entertainment", "business"].map(
            (category) => (
              <Badge
                key={category}
                variant={
                  selectedCategory === category ? "default" : "secondary"
                }
                className="cursor-pointer"
                onClick={() =>
                  setSelectedCategory(
                    selectedCategory === category ? null : category
                  )
                }
              >
                {category}
              </Badge>
            )
          )}
        </div>
      </div>

      {/* Database List */}
      <div className="space-y-3 overflow-auto">
        {filteredDatabases.map((db) => (
          <DatabaseCard
            key={db.id}
            database={db}
            onLoad={() => handleLoadSample(db)}
          />
        ))}

        {filteredDatabases.length === 0 && (
          <EmptyState searchTerm={searchTerm} />
        )}
      </div>
    </div>
  );
};

const DatabaseCard: React.FC<{
  database: SampleDatabase;
  onLoad: () => void;
}> = ({ database, onLoad }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      className={cn(
        "transition-colors hover:border-primary cursor-pointer",
        database.complexity === "beginner" && "border-l-4 border-l-green-500",
        database.complexity === "intermediate" && "border-l-4 border-l-yellow-500",
        database.complexity === "advanced" && "border-l-4 border-l-red-500"
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardHeader className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {database.icon}
            <CardTitle className="text-sm font-medium">
              {database.name}
            </CardTitle>
          </div>
          <Badge variant="secondary" className="capitalize">
            {database.complexity}
          </Badge>
        </div>
        <CardDescription className="text-xs mt-1">
          {database.description}
        </CardDescription>
      </CardHeader>

      <CardContent className="p-4 pt-0 space-y-3">
        {/* Database Stats */}
        <div className="grid grid-cols-2 gap-2 text-xs text-muted-foreground">
          <div className="flex items-center gap-1">
            <Table className="h-3 w-3" />
            {database.stats.tables} Tables
          </div>
        </div>

        {/* Features list */}
        {isHovered && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xs space-y-1"
          >
            <p className="font-medium text-muted-foreground">Key Features:</p>
            <ul className="list-disc list-inside space-y-0.5">
              {database.features.slice(0, 3).map((feature, index) => (
                <li key={index} className="text-muted-foreground">
                  {feature}
                </li>
              ))}
            </ul>
          </motion.div>
        )}

        {/* Load Button */}
        <Button 
          size="sm" 
          className="w-full" 
          onClick={onLoad}
        >
          <Database className="h-4 w-4 mr-2" />
          Load Sample
        </Button>
      </CardContent>
    </Card>
  );
};

const DatabaseTypeSelector: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => (
  <div className="space-y-2">
    <label className="text-sm font-medium flex items-center gap-2">
      <Database className="h-4 w-4 text-muted-foreground" />
      Database Type
    </label>
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger>
        <SelectValue placeholder="Select database type" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="postgres" className="flex items-center gap-3">
          <img src={PostgresImage} alt="PostgreSQL" className="h-4 w-4" />
          {/* PostgreSQL */}
        </SelectItem>
        <SelectItem
          value="mysql"
          disabled
          className="flex items-center gap-2 opacity-50"
        >
          MySQL (Coming Soon)
        </SelectItem>
        <SelectItem
          value="mariadb"
          disabled
          className="flex items-center gap-2 opacity-50"
        >
          MariaDB (Coming Soon)
        </SelectItem>
      </SelectContent>
    </Select>
  </div>
);

const ConnectionDetailsInputs: React.FC<{
  formData: any;
  setFormData: (data: any) => void;
  showPassword: boolean;
  setShowPassword: (show: boolean) => void;
}> = ({ formData, setFormData, showPassword, setShowPassword }) => (
  <div className="space-y-4">
    <div className="space-y-2">
      <label className="text-sm font-medium flex items-center gap-2">
        <Globe className="h-4 w-4 text-muted-foreground" />
        Connection Details
      </label>
      <div className="grid gap-3">
        <div className="flex gap-3">
          <Input
            className="flex-1"
            placeholder="Host"
            value={formData.host}
            onChange={(e) => setFormData({ ...formData, host: e.target.value })}
          />
          <Input
            className="w-24"
            placeholder="Port"
            value={formData.port}
            onChange={(e) => setFormData({ ...formData, port: e.target.value })}
          />
        </div>
        <Input
          placeholder="Database"
          value={formData.database}
          onChange={(e) =>
            setFormData({ ...formData, database: e.target.value })
          }
        />
      </div>
    </div>

    <div className="space-y-2">
      <label className="text-sm font-medium flex items-center gap-2">
        <KeyRound className="h-4 w-4 text-muted-foreground" />
        Authentication
      </label>
      <div className="space-y-3">
        <Input
          placeholder="Username"
          value={formData.username}
          onChange={(e) =>
            setFormData({ ...formData, username: e.target.value })
          }
        />
        <div className="relative">
          <Input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
          <Button
            variant="ghost"
            size="sm"
            className="absolute right-0 top-0 h-full px-3"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeOff className="h-4 w-4 text-muted-foreground" />
            ) : (
              <Eye className="h-4 w-4 text-muted-foreground" />
            )}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const SSLModeSelector: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => (
  <div className="space-y-2">
    <label className="text-sm font-medium flex items-center gap-2">
      <Lock className="h-4 w-4 text-muted-foreground" />
      SSL Mode
    </label>
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger>
        <SelectValue placeholder="SSL Mode" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="disable">Disable</SelectItem>
        <SelectItem value="require">Require</SelectItem>
        <SelectItem value="verify-full">Verify Full</SelectItem>
      </SelectContent>
    </Select>
  </div>
);

const EmptyState: React.FC<{ searchTerm: string }> = ({ searchTerm }) => (
  <div className="text-center p-6 bg-muted/50 rounded-lg">
    <Info className="h-8 w-8 mx-auto mb-2 text-muted-foreground" />
    <p className="text-sm text-muted-foreground">
      {searchTerm 
        ? `No databases found matching "${searchTerm}"`
        : "No sample databases available"}
    </p>
    {searchTerm && (
      <p className="text-xs text-muted-foreground mt-2">
        Try adjusting your search or removing filters
      </p>
    )}
  </div>
);

export default DatabasePanel;
