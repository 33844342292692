import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useSetup } from "../context/SetupContext";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../components/Common/Card.tsx';
import { Button } from '../components/Common/Button';
import { CheckCircle, ArrowRight, Book, Code, Puzzle } from 'lucide-react';

const CompletedSetup: React.FC = () => {

  const navigate = useNavigate();
  const { currentStep } = useSetup();

  useEffect(() => {
    if (currentStep !== "model_selection") {
      navigate(`/setup/${currentStep}`);
    }
  }, [currentStep, navigate]);

  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="text-3xl font-bold text-center flex items-center justify-center">
            <CheckCircle className="mr-2 text-green-500" />
            Setup Completed!
          </CardTitle>
        </CardHeader>
        <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Button asChild className="w-full">
            <Link to="/slack">Return to Slack</Link>
          </Button>
          <Button asChild variant="outline" className="w-full">
            <Link to="/dashboard">Go to Dashboard</Link>
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-semibold">Next Steps</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-4">
            {[
              { href: "/docs", icon: Book, text: "Read our documentation" },
              { href: "/api-docs", icon: Code, text: "Learn how to use our API" },
              { href: "/integration-guide", icon: Puzzle, text: "Integrate with your product" },
            ].map((item, index) => (
              <li key={index}>
                <Link
                  to={item.href}
                  className="flex items-center p-3 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <item.icon className="mr-3 text-blue-500" />
                  <span className="flex-grow">{item.text}</span>
                  <ArrowRight className="text-gray-400" />
                </Link>
              </li>
            ))}
          </ul>
        </CardContent>
        <CardFooter className="justify-end">
          <Button variant="link" asChild>
            <Link to="/help">Need help?</Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default CompletedSetup;