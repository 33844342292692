import { API_URL, axiosInstance } from "../config";

import { TokenService } from "./utils";

export const authService = {
  getAuthProgress: async (teamId: string | null, userId: string | null) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/api/v0/auth/teams/${teamId}/users/${userId}/status`
      );
      return response.data;
    } catch (error) {
      // window.location.href = "/login";
      console.error("Error getting setup progress:", error);
      throw error;
    }
  },
  refreshToken: async () => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/auth/refresh`
      );
      const { status } = response.data;
      return { status };
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw error;
    }
  },
  logout: async () => {
    try {
      await axiosInstance.post(`${API_URL}/api/v0/auth/logout`, {});
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      const itemsToClear = [
        "teamId",
        "userId",
        "datasetDetails",
        "warehouseDetails",
        "setupProgress",
        "warehouse",
        "user",
      ];

      itemsToClear.forEach((item) => localStorage.removeItem(item));
      TokenService.clearTokens();
    }
  },
  login: async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/api/v0/auth/google/login`
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error initiating login:", error);
      throw error;
    }
  },
  getTeamUsers: async (teamId: string) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/api/v0/auth/teams/${teamId}/users`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching team users:', error);
      throw error;
    }
  },
  inviteUser: async (teamId: string, inviteData: { email: string; role: string }) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/auth/teams/${teamId}/users/invite`,
        inviteData
      );
      return response.data;
    } catch (error) {
      console.error('Error inviting user:', error);
      throw error;
    }
  },
  acceptInvitation: async (token: string, username: string) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/auth/invite/accept`,
        { token, username }
      );
        
      return response.data;
    } catch (error) {
      console.error('Error accepting invitation:', error);
      throw error;
    }
  },
  validateInvitation: async (token: string) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/api/v0/auth/invite/validate/${token}`
      );
      return response.data;
    } catch (error) {
      console.error('Error validating invitation:', error);
      throw error;
    }
  },
  // Method to resend invitation
  resendInvitation: async (teamId: string, email: string) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/api/v0/auth/teams/${teamId}/users/invite/resend`,
        { email }
      );
      return response.data;
    } catch (error) {
      console.error('Error resending invitation:', error);
      throw error;
    }
  },
};
