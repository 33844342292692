import React from "react";
import { Check, X, ArrowRight } from "lucide-react";
import { Button } from "../Common/Button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../Common/Accordion";
import { Table, Relationship } from "../../types/types";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../Common/Dialog";

interface RelationshipPanelProps {
  currentTable: Table;
  relationships: Relationship[];
  reviewedRelationships: Record<string, boolean>;
  onAccept: (relationship: Relationship) => void;
  onReject: (relationship: Relationship) => void;
}

export const RelationshipPanel: React.FC<RelationshipPanelProps> = ({
  relationships,
  reviewedRelationships,
  onAccept,
  onReject,
}) => {
  const getRelationshipKey = (rel: Relationship) => `${rel.sourceTable}-${rel.targetTable}`;

  return (
    <div className="space-y-4">
      {relationships.map((relationship, index) => {
        const relationshipKey = getRelationshipKey(relationship);
        const isAccepted = reviewedRelationships[relationshipKey] !== false;

        return (
          <Accordion type="single" collapsible key={index}>
            <AccordionItem value={`item-${index}`}>
              <AccordionTrigger className={`p-4 rounded-lg shadow-md transition duration-200 ${
                isAccepted
                  ? "bg-green-50 border border-green-200"
                  : "bg-red-50 border border-red-200"
              }`}>
                <div className="flex justify-between items-center w-full">
                  <div>
                    <p className="font-medium text-gray-700">
                      {relationship.sourceTable} → {relationship.targetTable}
                    </p>
                    <p className="text-sm text-gray-500">
                      {relationship.sourceColumn} → {relationship.targetColumn}
                    </p>
                  </div>
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    isAccepted ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"
                  }`}>
                    {isAccepted ? "Accepted" : "Rejected"}
                  </span>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="p-4 space-y-4">
                  <div className="flex justify-end space-x-2">
                    {!isAccepted && (
                      <Button
                        onClick={() => onAccept(relationship)}
                        variant="outline"
                        size="sm"
                      >
                        <Check className="mr-2 h-4 w-4" />
                        Accept
                      </Button>
                    )}
                    {isAccepted && (
                      <Button
                        onClick={() => onReject(relationship)}
                        variant="outline"
                        size="sm"
                      >
                        <X className="mr-2 h-4 w-4" />
                        Reject
                      </Button>
                    )}
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button variant="outline" size="sm">
                          <ArrowRight className="mr-2 h-4 w-4" />
                          Details
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Relationship Details</DialogTitle>
                        </DialogHeader>
                        <div className="py-4">
                          <p className="text-sm text-gray-500">
                            <strong>Source:</strong> {relationship.sourceTable}.{relationship.sourceColumn}
                          </p>
                          <p className="text-sm text-gray-500">
                            <strong>Target:</strong> {relationship.targetTable}.{relationship.targetColumn}
                          </p>
                          {relationship.type && (
                            <p className="text-sm text-gray-500">
                              <strong>Type:</strong> {relationship.type}
                            </p>
                          )}
                          {relationship.cardinality && (
                            <p className="text-sm text-gray-500">
                              <strong>Cardinality:</strong> {relationship.cardinality}
                            </p>
                          )}
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        );
      })}
    </div>
  );
};