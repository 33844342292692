import React, { useState, useEffect } from 'react';
import { Mail, UserPlus, Check, X, Loader2 } from 'lucide-react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '@/components/Common/Card';
import { Input } from '@/components/Common/Input';
import { Button } from '@/components/Common/Button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/Common/Select';
import { Badge } from '@/components/Common/Badge';
import { authService } from '@/services/authService';
import { useTeam } from '@/context/TeamContext';
import { toast } from '@/components/Common/Toast';

interface User {
  id: string;
  email: string;
  role: string;
  isActive: boolean;
  invitePending?: boolean;
  avatar?: string;
}

const UserRoles = {
  ADMIN: { label: 'Admin', value: 'admin' },
  OPERATIONS: { label: 'Operations', value: 'operations' },
  MARKETING: { label: 'Marketing', value: 'marketing' },
  ANALYST: { label: 'Analyst', value: 'analyst' },
  MEMBER: { label: 'Member', value: 'member' },
};

const UserManagement = () => {
  const { teamId, userRole = '' } = useTeam();
  console.log('userRole', userRole);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newInvite, setNewInvite] = useState({ email: '', role: 'member' });
  const [inviting, setInviting] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await authService.getTeamUsers(teamId);
      setUsers(response);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    setInviting(true);
    try {
      await authService.inviteUser(teamId, newInvite);
      toast.success('Invitation sent successfully');
      setNewInvite({ email: '', role: 'member' });
      fetchUsers(); // Refresh user list
    } catch (error) {
      toast.error('Failed to send invitation');
    } finally {
      setInviting(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-2xl font-semibold">Team Members</CardTitle>
      </CardHeader>
      <CardContent>
        {userRole === 'admin' && (
          <form onSubmit={handleInvite} className="mb-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="col-span-2">
                <Input
                  placeholder="Email address"
                  value={newInvite.email}
                  onChange={(e) => setNewInvite({ ...newInvite, email: e.target.value })}
                  className="w-full"
                />
              </div>
              <div className="flex gap-2">
                <Select
                  value={newInvite.role}
                  onValueChange={(value) => setNewInvite({ ...newInvite, role: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select role" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(UserRoles).map(([key, { label, value }]) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button type="submit" disabled={inviting}>
                  {inviting ? (
                    <Loader2 className="w-4 h-4 animate-spin mr-2" />
                  ) : (
                    <UserPlus className="w-4 h-4 mr-2" />
                  )}
                  Invite
                </Button>
              </div>
            </div>
          </form>
        )}

        <div className="space-y-4">
          {isLoading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="w-8 h-8 animate-spin" />
            </div>
          ) : (
            users.map((user) => (
              <div
                key={user.id}
                className="flex items-center justify-between p-4 border rounded-lg"
              >
                <div className="flex items-center gap-3">
                  {user.avatar ? (
                    <img
                      src={user.avatar}
                      alt={user.email}
                      className="w-8 h-8 rounded-full"
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                      <Mail className="w-4 h-4 text-gray-500" />
                    </div>
                  )}
                  <div>
                    <div className="font-medium">{user.email}</div>
                    <div className="text-sm text-gray-500">
                      {UserRoles[user.role.toUpperCase()]?.label || user.role}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {user.invitePending && (
                    <Badge variant="secondary" className="bg-yellow-100 text-yellow-800">
                      Pending
                    </Badge>
                  )}
                  <Badge
                    variant={user.isActive ? "success" : "destructive"}
                    className={user.isActive ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"}
                  >
                    {user.isActive ? "Active" : "Inactive"}
                  </Badge>
                </div>
              </div>
            ))
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default UserManagement;