import React, { useState, useMemo, useEffect } from "react";
import {
  Database,
  Table2,
  ChevronRight,
  Edit2,
  Search,
  Filter,
  CheckSquare,
  Square,
  MinusSquare,
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/Common/Dialog";
import { Input } from "@/components/Common/Input";
import { Button } from "@/components/Common/Button";
import { Badge } from "@/components/Common/Badge";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/Common/Tooltip";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@/components/Common/Tabs";
import { useFetchTables } from "@/hooks/useFetchTables";
import { motion, AnimatePresence } from "framer-motion";
import { setupService } from "@/services/setupService";

interface TablesPanelProps {
  teamId: string;
  datasetId: string;
  onTableSelectionChange?: (selectedTables: string[]) => void;
  hasError?: boolean;
}

const TablesPanel: React.FC<TablesPanelProps> = ({
  teamId,
  datasetId,
  onTableSelectionChange,
  hasError = false,
}) => {
  const { data, isLoading } = useFetchTables(teamId, datasetId);
  const [selectedTable, setSelectedTable] = useState(null);
  const [descriptions, setDescriptions] = useState({});
  const [tableSearchTerm, setTableSearchTerm] = useState("");
  const [columnSearchTerm, setColumnSearchTerm] = useState("");
  const [selectedTables, setSelectedTables] = useState<Set<string>>(new Set());

  const [showErrorTooltip, setShowErrorTooltip] = useState(false);

  const { tables = [] } = data || {};

  const handleDescriptionUpdate = async (
    tableId: string,
    columnName: string | null,
    value: string
  ) => {
    try {
      if (!columnName) {
        await setupService.updateTableDescription(
          teamId,
          datasetId,
          tableId,
          value
        );
      } else {
        await setupService.updateColumnDescription(
          teamId,
          datasetId,
          tableId,
          columnName,
          value
        );
      }

      setDescriptions((prev) => ({
        ...prev,
        [tableId]: {
          ...(prev[tableId] || {}),
          [columnName || "table"]: value,
        },
      }));
    } catch (error) {
      console.error("Error updating description:", error);
      // Could add toast notification here for error feedback
    }
  };

  useEffect(() => {
    if (data?.tables) {
      const initialDescriptions = {};
      data.tables.forEach((table) => {
        initialDescriptions[table.id] = {
          table: table.description || "",
          ...table.columns?.reduce(
            (acc, column) => ({
              ...acc,
              [column.name]: column.description || "",
            }),
            {}
          ),
        };
      });
      setDescriptions(initialDescriptions);
    }
  }, [data?.tables]);

  const columnGroups = useMemo(() => {
    if (!selectedTable) return {};
    return selectedTable.columns.reduce((acc, column) => {
      if (!acc[column.type]) {
        acc[column.type] = [];
      }
      acc[column.type].push(column);
      return acc;
    }, {});
  }, [selectedTable]);

  const filteredTables = useMemo(() => {
    return tables.filter((table) =>
      table.name.toLowerCase().includes(tableSearchTerm.toLowerCase())
    );
  }, [tables, tableSearchTerm]);

  const filteredColumns = useMemo(() => {
    if (!selectedTable) return [];
    return selectedTable.columns.filter((column) =>
      column.name.toLowerCase().includes(columnSearchTerm.toLowerCase())
    );
  }, [selectedTable, columnSearchTerm]);

  const selectionStatus = useMemo(() => {
    if (filteredTables.length === 0) return "none";
    const selectedCount = filteredTables.filter((table) =>
      selectedTables.has(table.name)
    ).length;

    if (selectedCount === 0) return "none";
    if (selectedCount === filteredTables.length) return "all";
    return "partial";
  }, [filteredTables, selectedTables]);

  useEffect(() => {
    if (hasError) {
      setShowErrorTooltip(true);
      // Hide tooltip after 3 seconds
      const timeout = setTimeout(() => setShowErrorTooltip(false), 3000);
      return () => clearTimeout(timeout);
    }
  }, [hasError]);

  const handleSelectAll = () => {
    if (selectionStatus === "all") {
      // Deselect all filtered tables
      const newSelection = new Set(selectedTables);
      filteredTables.forEach((table) => newSelection.delete(table.name));
      setSelectedTables(newSelection);
      onTableSelectionChange?.([...newSelection]);
    } else {
      // Select all filtered tables
      const newSelection = new Set(selectedTables);
      filteredTables.forEach((table) => newSelection.add(table.name));
      setSelectedTables(newSelection);
      onTableSelectionChange?.([...newSelection]);
    }
  };

  const handleTableSelect = (tableName: string) => {
    const newSelection = new Set(selectedTables);
    if (newSelection.has(tableName)) {
      newSelection.delete(tableName);
    } else {
      newSelection.add(tableName);
    }
    setSelectedTables(newSelection);
    onTableSelectionChange?.([...newSelection]);
  };

  const TableRow = ({ table }) => (
    <div className="flex items-center p-3 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors group">
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleTableSelect(table.name);
        }}
        className="mr-3 cursor-pointer hover:scale-105 transition-transform"
      >
        {selectedTables.has(table.name) ? (
          <CheckSquare className="w-5 h-5 text-blue-500" />
        ) : (
          <Square className="w-5 h-5 text-gray-400" />
        )}
      </div>

      <div
        onClick={() => setSelectedTable(table)}
        className="flex items-center flex-1 cursor-pointer"
      >
        <Table2 className="w-5 h-5 mr-3 text-blue-500" />
        <div className="flex-1">
          <div className="font-medium">{table.name}</div>
          <div className="text-sm text-gray-500">
            {table.columns?.length || 0} columns
          </div>
        </div>
        <ChevronRight className="w-5 h-5 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
      </div>
    </div>
  );

  const DescriptionField = ({
    tableId,
    columnName = null,
    initialValue = "",
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(
      descriptions[tableId]?.[columnName || "table"] || initialValue
    );

    useEffect(() => {
      setValue(descriptions[tableId]?.[columnName || "table"] || initialValue);
    }, [tableId, columnName, initialValue]);

    if (isEditing) {
      return (
        <div className="flex gap-2">
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter description..."
            className="flex-1"
          />
          <Button
            onClick={async () => {
              await handleDescriptionUpdate(tableId, columnName, value);
              setIsEditing(false);
            }}
          >
            Save
          </Button>
        </div>
      );
    }

    return (
      <div className="flex items-center justify-between group">
        <span className="text-sm text-gray-600 dark:text-gray-300">
          {value || "No description"}
        </span>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsEditing(true)}
          className="opacity-0 group-hover:opacity-100"
        >
          <Edit2 className="w-4 h-4" />
        </Button>
      </div>
    );
  };

  const ColumnCard = ({ column }) => (
    <div className="border rounded-lg p-4 bg-white dark:bg-gray-800">
      <div className="flex items-start justify-between mb-3">
        <div className="flex-1">
          <h4 className="font-medium text-base">{column.name}</h4>
          <div className="flex items-center gap-2 mt-1">
            <Badge variant="outline">{column.type}</Badge>
            {column.isPrimaryKey && (
              <Badge className="bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100">
                Primary Key
              </Badge>
            )}
            {column.isNullable && (
              <Badge variant="outline" className="text-gray-500">
                Nullable
              </Badge>
            )}
          </div>
        </div>
      </div>
      <div className="mt-3 border-t pt-3">
        <DescriptionField
          tableId={selectedTable?.id}
          columnName={column.name}
          initialValue={column.description}
        />
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex flex-col h-full">
        <div className="flex-shrink-0 p-4 border-b dark:border-gray-800">
          <div className="h-6 w-48 bg-gray-200 dark:bg-gray-700 rounded animate-pulse mb-2" />
          <div className="h-4 w-24 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
        </div>
        <div className="flex-1 p-4">
          <div className="space-y-4">
            {[1, 2, 3, 4, 5].map((i) => (
              <div
                key={i}
                className="h-16 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`flex flex-col h-full relative ${hasError ? "shake" : ""}`}>
      <AnimatePresence>
        {showErrorTooltip && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="absolute top-0 left-0 right-0 z-50 mx-4 mt-4"
          >
            <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg shadow-lg">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-red-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    Please select 10 or fewer tables for better query
                    performance
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Header */}
      <div
        className={`flex-shrink-0 p-4 border-b dark:border-gray-800 bg-white dark:bg-gray-900 transition-colors ${
          hasError ? "border-red-400" : ""
        }`}
      >
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold">Database Tables</h2>
          <Badge
            variant={selectedTables.size > 10 ? "destructive" : "outline"}
            className={`transition-all ${
              selectedTables.size > 10 ? "animate-pulse" : ""
            }`}
          >
            {selectedTables.size} / {tables.length} selected
          </Badge>
        </div>

        {/* Search and Select All Controls */}
        <div className="space-y-3">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
            <Input
              value={tableSearchTerm}
              onChange={(e) => setTableSearchTerm(e.target.value)}
              placeholder="Search tables..."
              className="pl-9 w-full"
            />
          </div>

          <div className="flex items-center justify-between">
            <Button
              variant="ghost"
              size="sm"
              onClick={handleSelectAll}
              className="flex items-center gap-2"
            >
              {selectionStatus === "all" && <CheckSquare className="w-4 h-4" />}
              {selectionStatus === "none" && <Square className="w-4 h-4" />}
              {selectionStatus === "partial" && (
                <MinusSquare className="w-4 h-4" />
              )}
              {selectionStatus === "all" ? "Deselect All" : "Select All"}
              {tableSearchTerm && " Filtered"}
            </Button>

            {tableSearchTerm && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setTableSearchTerm("")}
              >
                Clear Search
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* Table List */}
      <div className="flex-1 overflow-y-auto">
        <div className="p-4 space-y-2">
          {filteredTables.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              No tables match your search
            </div>
          ) : (
            filteredTables.map((table) => (
              <TableRow key={table.id} table={table} />
            ))
          )}
        </div>
      </div>

      <Dialog
        open={!!selectedTable}
        onOpenChange={() => {
          setSelectedTable(null);
          setColumnSearchTerm("");
        }}
      >
        <DialogContent className="max-w-5xl max-h-[85vh]">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <span>{selectedTable?.name}</span>
              <Badge variant="outline">
                {selectedTable?.columns?.length} columns
              </Badge>
            </DialogTitle>
            <DialogDescription>
              <DescriptionField
                tableId={selectedTable?.id}
                initialValue={selectedTable?.description}
              />
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <div className="flex-1 relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                <Input
                  value={columnSearchTerm}
                  onChange={(e) => setColumnSearchTerm(e.target.value)}
                  placeholder="Search columns..."
                  className="pl-9 w-full"
                />
              </div>
            </div>

            {columnSearchTerm ? (
              // Search Results
              <div className="space-y-4 overflow-y-auto max-h-[60vh] pr-2">
                {filteredColumns.map((column) => (
                  <ColumnCard key={column.name} column={column} />
                ))}
              </div>
            ) : (
              // Grouped View
              <Tabs
                defaultValue={Object.keys(columnGroups)[0]}
                className="w-full"
              >
                <TabsList className="mb-4">
                  {Object.entries(columnGroups).map(([type, columns]) => (
                    <TabsTrigger key={type} value={type}>
                      {type} ({columns.length})
                    </TabsTrigger>
                  ))}
                </TabsList>

                {Object.entries(columnGroups).map(([type, columns]) => (
                  <TabsContent key={type} value={type}>
                    <div className="space-y-4 overflow-y-auto max-h-[60vh] pr-2">
                      {columns.map((column) => (
                        <ColumnCard key={column.name} column={column} />
                      ))}
                    </div>
                  </TabsContent>
                ))}
              </Tabs>
            )}

            {columnSearchTerm && filteredColumns.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No columns match your search
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {/* Info Footer */}
      <div className="flex-shrink-0 p-4 border-t dark:border-gray-800 bg-white dark:bg-gray-900">
        <div className="flex items-center justify-between">
          <p className="text-sm text-gray-500">
            Selected tables will be used for query generation
          </p>
          {/* {selectedTables.size > 10 && (
            <span className="text-sm text-red-500">
              Max 10 tables recommended
            </span>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default TablesPanel;
