import React, { useRef, useState } from "react";
import {
  Database,
  Settings as IconSettings,
  Activity,
  BarChart2,
  Bot,
  Brain,
  MessageSquare,
  FileText,
  Plug,
} from "lucide-react";
import { motion } from "framer-motion";

import { useTeam } from "@/context/TeamContext";

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "@/components/Common/Tooltip";
import Settings from "@/components/Dashboard/Settings/Settings";
import QueryLogs from "@/components/Dashboard/Logs/QueryLogs";
import DefineData from "@/components/DefineData/Setup";
import Chatbot from "@/components/Dashboard/Chat";
import Insights from "@/components/Dashboard/Insights/Insights";
import {
  GoogleLogo,
  SnowflakeLogo,
  DefaultDatabaseLogo,
} from "@/components/Logos";
import DashboardHeader from "@/components/Dashboard/Chat/Header";
// import QueryGenerator from "@/components/Dashboard/QueryGenerator";
// import DataSourceManager from "@/components/Dashboard/DataSourceManager";
// import Integrations from "@/components/Dashboard/Settings/Integrations";
// import { useGuidedTour } from "@/hooks/useGuidedTour";

const warehouseLogos = {
  gcp: GoogleLogo,
  snowflake: SnowflakeLogo,
  default: DefaultDatabaseLogo,
};

const Dashboard = () => {
  const { teamId, warehouseDetails, updateWarehouseDetails } = useTeam();

  const [customDataSource, setCustomDataSource] = useState(false);
  const [activeTab, setActiveTab] = useState("chat");
  const [expandedPanel, setExpandedPanel] = useState(null);

  const chatRef = useRef();

  // const { showIntro, startTour } = useGuidedTour();

  // const [isFileImportOpen, setIsFileImportOpen] = useState(false);

  const toggleExpand = (panel) => {
    setExpandedPanel(expandedPanel === panel ? null : panel);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "insights":
        return (
          <Insights teamId={teamId} datasetId={warehouseDetails?.datasetId} />
        );
      case "chat":
        return (
          <Chatbot
            ref={chatRef}
            teamId={teamId}
            datasetId={warehouseDetails?.datasetId}
            hasDataSource={!!warehouseDetails || customDataSource}
            isCustomDataMode={customDataSource}
            onFileImportComplete={(fileDetails) => {
              // Update warehouse details with file information
              updateWarehouseDetails({
                ...fileDetails,
                type: "custom-file",
              });
              setCustomDataSource(true);
            }}
          />
        );
      case "settings":
        return <Settings />;
      case "logs":
        return <QueryLogs />;
      case "data":
          return <DefineData />;
      case "finetuning":
        return <h1>COMING SOON</h1>;
      default:
        return null;
      // case "query":
      //   return (
      //     <QueryGenerator
      //       teamId={teamId}
      //       datasetId={warehouseDetails?.datasetId}
      //       onToggleExpand={() => toggleExpand("query")}
      //       isExpanded={expandedPanel === "query"}
      //     />
      //   );
    }
  };

  const handleOpenFileImport = () => {
    // Switch to chat tab if not already there
    setActiveTab("chat");
    // Set custom data source mode
    setCustomDataSource(true);

    // // Clear warehouse details temporarily
    // updateWarehouseDetails(null);

    // Trigger file import in chat component
    if (chatRef.current) {
      chatRef.current.openFileImport();
    }
  };

  const WarehouseLogo =
    warehouseLogos[warehouseDetails?.warehouse] || warehouseLogos.default;

  return (
    <TooltipProvider delayDuration={100}>
      <div className="flex">
        {/* Sidebar */}
        <div className="w-20 bg-white/20 dark:bg-black/20 backdrop-blur-sm flex flex-col items-center justify-center space-y-8 py-4">
          <NavItem
            icon={<BarChart2 size={24} />}
            label="Insights"
            tooltip="View saved queries and insights"
            active={activeTab === "insights"}
            onClick={() => setActiveTab("insights")}
          />
          <NavItem
            icon={<Bot size={24} />}
            label="Chat"
            tooltip="Chat with AI assistant"
            active={activeTab === "chat"}
            onClick={() => setActiveTab("chat")}
            data-tour="chat"
          />
          <NavItem
            icon={<Database size={24} />}
            label="Data"
            tooltip="Manage and define data sources"
            active={activeTab === "data"}
            onClick={() => setActiveTab("data")}
          />
          <NavItem
            icon={<Brain size={24} />}
            label="Finetuning"
            tooltip="Fine tune your dataset"
            active={activeTab === "finetuning"}
            onClick={() => setActiveTab("finetuning")}
          />
          <NavItem
            icon={<IconSettings size={24} />}
            label="Settings"
            tooltip="Adjust application settings"
            active={activeTab === "settings"}
            onClick={() => setActiveTab("settings")}
          />
          <NavItem
            icon={<Activity size={24} />}
            label="Logs"
            tooltip="View query logs and activity"
            active={activeTab === "logs"}
            onClick={() => setActiveTab("logs")}
          />
           {/* <NavItem
            icon={<FileText size={24} />}
            label="Define Data"
            tooltip="Define and structure your data"
            active={activeTab === "defineData"}
            onClick={() => setActiveTab("defineData")}
          /> */}
           {/* <NavItem
            icon={<MessageSquare size={24} />}
            label="Query"
            tooltip="Generate and run queries"
            active={activeTab === "query"}
            onClick={() => setActiveTab("query")}
          /> */}
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-hidden">
          <DashboardHeader
            teamId={teamId}
            activeTab={activeTab}
            warehouseDetails={warehouseDetails}
            customDataSource={customDataSource}
            onOpenFilePanel={handleOpenFileImport}
            WarehouseLogo={WarehouseLogo}
          />
          <main className="p-6 h-[calc(80vh)] overflow-auto">
            <motion.div
              layout
              transition={{ duration: 0.3 }}
              className={expandedPanel === "query" ? "w-full" : "w-4/4 h-full overflow-hidden"}
            >
              {renderContent()}
            </motion.div>
          </main>
        </div>
      </div>
    </TooltipProvider>
  );
};

const NavItem = ({ icon, label, tooltip, active, onClick }) => (
  <Tooltip>
    <TooltipTrigger asChild>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
        className={`p-3 rounded-full transition-colors duration-200 ${
          active
            ? "bg-blue-500 text-white"
            : "bg-white/50 dark:bg-gray-700/50 text-gray-600 dark:text-gray-300 hover:bg-blue-100 dark:hover:bg-blue-900"
        }`}
        aria-label={label}
      >
        {icon}
      </motion.button>
    </TooltipTrigger>
    <TooltipContent side="right" sideOffset={5} className="z-50">
      <p>{tooltip}</p>
    </TooltipContent>
  </Tooltip>
);

export default Dashboard;
