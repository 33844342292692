import React from "react";
import { Label } from "@/components/Common/Label";
import { Input } from "@/components/Common/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Common/Select";
import { Info, ShieldCheck } from "lucide-react";

interface BigQueryConfigProps {
  config: {
    scheduleType: string;
    startDate: string;
    timeZone: string;
    retryCount: number;
    timeout: string;
    destination: string;
  };
  setConfig: (config: any) => void;
}

export const BigQueryConfig: React.FC<BigQueryConfigProps> = ({
  config,
  setConfig,
}) => {
  const timeZones = [
    { value: "UTC", label: "UTC" },
    { value: "America/New_York", label: "Eastern Time" },
    { value: "America/Chicago", label: "Central Time" },
    { value: "America/Denver", label: "Mountain Time" },
    { value: "America/Los_Angeles", label: "Pacific Time" },
  ];

  return (
    <div className="relative">
      {/* Coming Soon Overlay */}
      <div className="absolute inset-0 bg-white/60 dark:bg-gray-800/60 backdrop-blur-[1px] z-20 rounded-lg flex flex-col items-center justify-center">
        <div className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-4 py-2 rounded-full text-sm font-medium flex items-center gap-2 mb-2 shadow-lg">
          <ShieldCheck className="w-4 h-4" />
          Coming Soon
        </div>
        <p className="text-sm text-gray-600 dark:text-gray-300 max-w-md text-center px-4">
          Automated queries will be available in the next update
        </p>
      </div>

      <div className="space-y-6">
        {/* Info Banner */}
        <div className="rounded-lg bg-blue-50 dark:bg-blue-900/20 p-4">
          <div className="flex items-start gap-3">
            <Info className="w-5 h-5 text-blue-500 mt-0.5" />
            <div className="flex-1">
              <p className="text-sm text-blue-900 dark:text-blue-200">
                This query will be scheduled in BigQuery and run automatically
                based on your configuration. Results will be saved to the
                specified destination table.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-8 gap-y-6">
          {/* Left Column */}
          <div className="space-y-6">
            <div className="space-y-2">
              <Label>Schedule Type</Label>
              <Select
                value={config.scheduleType}
                onValueChange={(val) =>
                  setConfig({ ...config, scheduleType: val })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select frequency" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="daily">Daily</SelectItem>
                  <SelectItem value="weekly">Weekly</SelectItem>
                  <SelectItem value="monthly">Monthly</SelectItem>
                  <SelectItem value="hourly">Hourly</SelectItem>
                  <SelectItem value="custom">Custom (CRON)</SelectItem>
                </SelectContent>
              </Select>
              <p className="text-xs text-gray-500">
                How often the query should run
              </p>
            </div>

            <div className="space-y-2">
              <Label>Start Date & Time</Label>
              <Input
                type="datetime-local"
                value={config.startDate}
                onChange={(e) =>
                  setConfig({ ...config, startDate: e.target.value })
                }
              />
              <p className="text-xs text-gray-500">
                When to start running this schedule
              </p>
            </div>

            <div className="space-y-2">
              <Label>Time Zone</Label>
              <Select
                value={config.timeZone}
                onValueChange={(val) => setConfig({ ...config, timeZone: val })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select timezone" />
                </SelectTrigger>
                <SelectContent>
                  {timeZones.map((tz) => (
                    <SelectItem key={tz.value} value={tz.value}>
                      {tz.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <div className="space-y-2">
              <Label>Destination Table</Label>
              <Input
                placeholder="project.dataset.table"
                value={config.destination}
                onChange={(e) =>
                  setConfig({ ...config, destination: e.target.value })
                }
              />
              <p className="text-xs text-gray-500">
                Where query results will be stored
              </p>
            </div>

            <div className="space-y-2">
              <Label>Retry Count</Label>
              <Input
                type="number"
                min="0"
                max="5"
                value={config.retryCount}
                onChange={(e) =>
                  setConfig({ ...config, retryCount: parseInt(e.target.value) })
                }
              />
              <p className="text-xs text-gray-500">
                Number of retry attempts (0-5)
              </p>
            </div>

            <div className="space-y-2">
              <Label>Query Timeout</Label>
              <Input
                placeholder="30m"
                value={config.timeout}
                onChange={(e) =>
                  setConfig({ ...config, timeout: e.target.value })
                }
              />
              <p className="text-xs text-gray-500">
                Maximum execution time (e.g., 30m, 2h)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
