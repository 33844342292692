import React from "react";
import {
  BarChart,
  LineChart,
  PieChart,
  ScatterChart,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  Line,
  Pie,
  Scatter,
  Area,
} from "recharts";

// Define a type for the chart data
type ChartData = Array<Record<string, string | number>>;

// Define props for each chart type
interface ChartProps {
  data: ChartData;
  xKey: string;
  yKey: string;
  width?: number;
  height?: number;
}

// Error Boundary component
class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center h-64 bg-gray-100 rounded-lg">
          <p className="text-gray-500 text-lg">
            Sorry, there was an error rendering the chart.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

// Base chart component
const BaseChart: React.FC<ChartProps & { children: React.ReactNode }> = ({
  data,
  width = 600,
  height = 300,
  children,
}) => (
  <ErrorBoundary>
    <ResponsiveContainer width={width} height={height}>
      {children}
    </ResponsiveContainer>
  </ErrorBoundary>
);

// Bar Chart
export const BarChartComponent: React.FC<ChartProps> = ({
  data,
  xKey,
  yKey,
  ...rest
}) => (
  <BaseChart data={data} {...rest}>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xKey} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey={yKey} fill="#8884d8" />
    </BarChart>
  </BaseChart>
);

// Line Chart
export const LineChartComponent: React.FC<ChartProps> = ({
  data,
  xKey,
  yKey,
  ...rest
}) => (
  <BaseChart data={data} {...rest}>
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xKey} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey={yKey} stroke="#8884d8" />
    </LineChart>
  </BaseChart>
);

// Pie Chart
export const PieChartComponent: React.FC<ChartProps> = ({
  data,
  xKey,
  yKey,
  ...rest
}) => (
  <BaseChart data={data} {...rest}>
    <PieChart>
      <Pie
        data={data}
        dataKey={yKey}
        nameKey={xKey}
        cx="50%"
        cy="50%"
        outerRadius={80}
        fill="#8884d8"
        label
      />
      <Tooltip />
      <Legend />
    </PieChart>
  </BaseChart>
);

// Scatter Chart
export const ScatterChartComponent: React.FC<ChartProps> = ({
  data,
  xKey,
  yKey,
  ...rest
}) => (
  <BaseChart data={data} {...rest}>
    <ScatterChart>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xKey} />
      <YAxis dataKey={yKey} />
      <Tooltip cursor={{ strokeDasharray: "3 3" }} />
      <Legend />
      <Scatter name="Data" data={data} fill="#8884d8" />
    </ScatterChart>
  </BaseChart>
);

// Area Chart
export const AreaChartComponent: React.FC<ChartProps> = ({
  data,
  xKey,
  yKey,
  ...rest
}) => (
  <BaseChart data={data} {...rest}>
    <AreaChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xKey} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Area type="monotone" dataKey={yKey} stroke="#8884d8" fill="#8884d8" />
    </AreaChart>
  </BaseChart>
);

// Column Chart (Vertical Bar Chart)
export const ColumnChartComponent: React.FC<ChartProps> = ({
  data,
  xKey,
  yKey,
  ...rest
}) => (
  <BaseChart data={data} {...rest}>
    <BarChart data={data} layout="vertical">
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis dataKey={xKey} type="category" />
      <Tooltip />
      <Legend />
      <Bar dataKey={yKey} fill="#8884d8" />
    </BarChart>
  </BaseChart>
);

// Chart type mapping
const chartComponents = {
  bar: BarChartComponent,
  line: LineChartComponent,
  pie: PieChartComponent,
  scatter: ScatterChartComponent,
  area: AreaChartComponent,
  column: ColumnChartComponent,
};

// Main Chart component
interface ChartComponentProps extends ChartProps {
  chartType: keyof typeof chartComponents;
}

export const ChartComponent: React.FC<ChartComponentProps> = ({
  chartType,
  ...props
}) => {
  const SpecificChart = chartComponents[chartType];

  if (!SpecificChart) {
    return (
      <div className="flex items-center justify-center h-64 bg-gray-100 rounded-lg">
        <p className="text-gray-500 text-lg">
          Sorry, the specified chart type is not supported.
        </p>
      </div>
    );
  }

  return <SpecificChart {...props} />;
};
