import React, { useState, useEffect } from "react";
import Editor, { EditorProps } from "@monaco-editor/react";
import { format as sqlFormat, SqlLanguage } from "sql-formatter";

interface CodeEditorProps extends Omit<EditorProps, "onMount"> {
  value: string;
  language: string;
  theme?: "vs-dark" | "light";
  readOnly?: boolean;
  editorDidMount?: (editor: any) => void;
  onChange?: (value: string | undefined) => void;
  sqlDialect?: SqlLanguage;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  value,
  language,
  theme = "vs-dark",
  readOnly = false,
  editorDidMount,
  onChange,
  sqlDialect = "sql",
  ...props
}) => {
  const [editorValue, setEditorValue] = useState(value);

  const formatSQL = (sql: string): string => {
    try {
      return sqlFormat(sql, {
        language: sqlDialect,
        indent: "  ",
        uppercase: true,
        linesBetweenQueries: 2,
      });
    } catch (error) {
      console.error("Error formatting SQL:", error);
      return sql; // Return original SQL if formatting fails
    }
  };

  useEffect(() => {
    if (language.toLowerCase() === "sql") {
      const formattedValue = formatSQL(value);
      setEditorValue(formattedValue);
      if (onChange) {
        onChange(formattedValue);
      }
    } else {
      setEditorValue(value);
    }
  }, [value, language, sqlDialect]);

  const handleEditorChange = (value: string | undefined) => {
    setEditorValue(value || "");
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="border rounded overflow-hidden">

      <Editor
        height="300px"
        language={language}
        value={editorValue}
        theme={theme}
        options={{
          minimap: { enabled: false },
          fontSize: 14,
          lineNumbers: "on",
          readOnly: readOnly,
          scrollBeyondLastLine: false,
          automaticLayout: true,
          wordWrap: "on",
          wrappingIndent: "indent",
        }}
        onChange={handleEditorChange}
        onMount={(editor) => {
          if (editorDidMount) editorDidMount(editor);
        }}
        {...props}
      />
    </div>
  );
};

export { CodeEditor };
