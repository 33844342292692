// src/components/Settings/ModelSettings/ModelCard.tsx

import React from 'react';
import { CheckCircle2, Lock } from 'lucide-react';
import { FaCrown } from 'react-icons/fa';
import { cn } from '@/components/utils';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/Common/Card';
import { Badge } from '@/components/Common/Badge';

interface ModelCardProps {
  model: {
    id: string;
    name: string;
    icon: React.ElementType;
    description: string;
    features: string[];
    available: boolean;
    premium: boolean;
    requiresApiKey?: boolean;
  };
  selected: boolean;
  current: boolean;
  onSelect: () => void;
  disabled?: boolean;
}

const ModelCard: React.FC<ModelCardProps> = ({
  model,
  selected,
  current,
  onSelect,
  disabled,
}) => {
  const Icon = model.icon;

  return (
    <Card
      className={cn(
        'relative transition-all duration-200 cursor-pointer hover:border-blue-200',
        selected && 'border-2 border-blue-500 bg-blue-50/50 dark:bg-blue-950/20',
        disabled && 'opacity-60 cursor-not-allowed hover:border-gray-200',
        'overflow-hidden'
      )}
      onClick={() => !disabled && onSelect()}
    >
      {/* Status Badges */}
      <div className="absolute top-3 right-3 flex gap-2">
        {current && (
          <Badge variant="default" className="bg-green-500">
            Current
          </Badge>
        )}
        {!model.available && (
          <Badge variant="secondary">Coming Soon</Badge>
        )}
        {model.premium && (
          <Badge variant="secondary" className="bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
            <FaCrown className="w-3 h-3 mr-1" />
            Premium
          </Badge>
        )}
      </div>

      <CardHeader>
        <div className="flex items-center gap-3">
          <div className="p-2 rounded-lg bg-primary/10">
            <Icon className="w-5 h-5 text-primary" />
          </div>
          <div>
            <CardTitle className="text-lg">{model.name}</CardTitle>
            <CardDescription>{model.description}</CardDescription>
          </div>
        </div>
      </CardHeader>
      
      <CardContent>
        <ul className="space-y-2">
          {model.features.map((feature, index) => (
            <li key={index} className="flex items-center gap-2 text-sm">
              <CheckCircle2 className="w-4 h-4 text-green-500" />
              {feature}
            </li>
          ))}
        </ul>

        {disabled && model.premium && (
          <div className="mt-4 flex items-center gap-2 text-sm text-muted-foreground">
            <Lock className="w-4 h-4" />
            Requires premium subscription
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ModelCard;