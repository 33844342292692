import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { usePlayground } from "@/context/PlaygroundContext";
import { ResizablePanel } from "@/components/Common/ResizablePanel";

import { DatabasePanel } from "./DatabasePanel";
import { LLMConfigPanel } from "./LLMPanel";

export const PanelContainer = () => {
  const { panels, setPanels } = usePlayground();

  return (
    <>
      <AnimatePresence>
        {panels.isLeftPanelOpen && (
          <motion.div
            initial={{ x: -320, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -320, opacity: 0 }}
            transition={{ type: "spring", damping: 20 }}
            className="absolute left-4 top-4 w-80 bg-card rounded-lg shadow-lg z-50"
          >
            <DatabasePanel />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {panels.isRightPanelOpen && (
          <motion.div
            initial={{ x: 320, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 320, opacity: 0 }}
            transition={{ type: "spring", damping: 20 }}
            className="absolute right-4 top-4 w-80 bg-card rounded-lg shadow-lg border z-50"
          >
            <LLMConfigPanel />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
