import axios from "axios";

import { TokenService } from "@/services/utils";

const API_URL = import.meta.env.VITE_REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json, text/plain, */*",
    "Cache-Control": "no-cache",  
    "Pragma": "no-cache",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // const refreshToken = TokenService.getRefreshToken();
        // if (!refreshToken) {
        //   throw new Error("No refresh token available");
        // }

        // const response = await axiosInstance.post("/api/v0/auth/refresh", {
        //   refreshToken,
        // });

        // The backend should set the new cookies
        // We don't need to manually set them here

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        TokenService.clearTokens();
        // Redirect to login or handle as needed
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

export { API_URL, axiosInstance };
