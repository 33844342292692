import * as React from "react"
import { cn } from "../utils"

interface ListProps extends React.OlHTMLAttributes<HTMLOListElement> {
  items: React.ReactNode[];
  ordered?: boolean;
}

const List = React.forwardRef<HTMLOListElement, ListProps>(
  ({ className, items, ordered = false, ...props }, ref) => {
    const ListComponent = ordered ? 'ol' : 'ul'
    return (
      <ListComponent
        ref={ref}
        className={cn(
          "list-inside",
          ordered ? "list-decimal" : "list-disc",
          className
        )}
        {...props}
      >
        {items.map((item, index) => (
          <li key={index} className="mb-2">{item}</li>
        ))}
      </ListComponent>
    )
  }
)
List.displayName = "List"

export { List }