import React from "react";
import { BigQueryResultTableProps } from "@/types/bigquery";
import { ChartComponent } from "./Chart";

interface ChartWrapperProps {
  data: BigQueryResultTableProps["data"];
  chartType: string;
}

const ChartWrapper: React.FC<ChartWrapperProps> = ({ data, chartType }) => {
  // Process the BigQuery result into a format suitable for charts
  const processData = (rows: any[]): Array<Record<string, string | number>> => {
    if (!Array.isArray(rows) || rows.length === 0) {
      return [];
    }
    return rows.map((row) => {
      const processedRow: Record<string, string | number> = {};
      Object.entries(row).forEach(([key, value]) => {
        processedRow[key] =
          typeof value === "object" && value !== null ? value.value : value;
      });
      return processedRow;
    });
  };

  const chartData = processData(data?.rows || []);

  // Determine x and y keys (you might want to make this more sophisticated based on data types)
  const keys = chartData.length > 0 ? Object.keys(chartData[0]) : [];
  const xKey = keys[0] || "";
  const yKey = keys[1] || "";

  if (chartData.length === 0 || !xKey || !yKey) {
    return (
      <div className="flex items-center justify-center h-64 bg-gray-100 rounded-lg">
        <p className="text-gray-500 text-lg">
          Sorry, I couldn't generate a chart with the provided data.
        </p>
      </div>
    );
  }

  return (
    <ChartComponent
      chartType={(chartType as keyof typeof ChartComponent) || "bar"}
      data={chartData}
      xKey={xKey}
      yKey={yKey}
    />
  );
};

export default ChartWrapper;
