import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Common/Dialog";
import { Button } from "@/components/Common/Button";
import { Badge } from "@/components/Common/Badge";
import { Database, ArrowRight, Bot, Boxes, Settings, Lock } from "lucide-react";

interface SetupDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const SetupDialog: React.FC<SetupDialogProps> = ({ isOpen, onClose }) => {
  const steps = [
    {
      icon: <Database className="w-6 h-6" />,
      title: "Connect Your Warehouse",
      description: "Link your data warehouse to start analyzing your data",
      status: "coming-soon",
    },
    {
      icon: <Bot className="w-6 h-6" />,
      title: "Select AI Model",
      description: "Choose the right AI model for your specific needs",
      status: "coming-soon",
    },
    {
      icon: <Boxes className="w-6 h-6" />,
      title: "Configure Schema",
      description: "Define your data structure and relationships",
      status: "coming-soon",
    },
    {
      icon: <Settings className="w-6 h-6" />,
      title: "Advanced Settings",
      description: "Fine-tune your configuration and permissions",
      status: "coming-soon",
    },
  ];

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>Connect New Data Source</DialogTitle>
        </DialogHeader>

        <div className="relative">
          {/* Coming Soon Banner */}
          {/* <div className="absolute inset-0 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm z-10 flex items-center justify-center">
            <div className="text-center px-6 py-4 rounded-lg border-2 border-blue-500/20 bg-blue-50 dark:bg-blue-900/20">
              <Lock className="w-8 h-8 text-blue-500 mx-auto mb-2" />
              <h3 className="text-lg font-semibold mb-1">Coming Soon</h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">
                Direct warehouse connection will be available soon. For now, you
                can import your data using files.
              </p>
            </div>
          </div> */}

          {/* Setup Steps */}
          <div className="space-y-4">
            {steps.map((step, index) => (
              <div
                key={step.title}
                className="flex items-start gap-4 p-4 rounded-lg border border-gray-200 dark:border-gray-700"
              >
                <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900 text-blue-600">
                  {step.icon}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2">
                    <h3 className="font-medium">{step.title}</h3>
                    <Badge variant="outline" className="text-xs">
                      Coming Soon
                    </Badge>
                  </div>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    {step.description}
                  </p>
                </div>
                <ArrowRight className="w-5 h-5 text-gray-400" />
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end gap-3 mt-6">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onClose}>Got it</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SetupDialog;
