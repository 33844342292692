import React from "react";
import { Button } from "@/components/Common/Button";
import { Badge } from "@/components/Common/Badge";

interface DialogFooterProps {
  enabled: string[];
  onSave: () => void;
  onClose: () => void;
  isLoading: boolean;
}

export const DialogFooter: React.FC<DialogFooterProps> = ({ enabled, onSave, onClose, isLoading }) => {
  return (
    <div className="border-t mt-6 py-4 bg-gradient-to-r from-gray-50 to-gray-100 dark:from-gray-800/50 dark:to-gray-800/30 sticky bottom-0">
      <div className="flex justify-between items-center px-2">
        <div className="flex items-center gap-2">
          {enabled.length > 0 && (
            <div className="flex gap-2">
              {enabled.map((item) => (
                <Badge
                  key={item}
                  variant="outline"
                  className="bg-blue-50 text-blue-600 border-blue-200 dark:bg-blue-900/20 dark:border-blue-800 dark:text-blue-400"
                >
                  {item}
                </Badge>
              ))}
            </div>
          )}
        </div>
        <div className="flex gap-2">
          <Button
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="outline"
            onClick={onSave}
            disabled={enabled.length === 0 || isLoading}
            className="hover:bg-blue-100 disabled:bg-gray-300 dark:disabled:bg-gray-700"
          >
            {isLoading ? (
              <>
                <div className="loading-spinner mr-2" />
                Saving...
              </>
            ) : (
              "Save Configuration"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};