import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Bot,
  Settings2,
  AlertCircle,
  CheckCircle2,
  Loader2,
  Lock,
  ExternalLink,
  InfoIcon,
} from 'lucide-react';
import { FaCrown } from 'react-icons/fa';
import { SiOpenai } from 'react-icons/si';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/Common/Card';
import { Button } from '@/components/Common/Button';
import { Input } from '@/components/Common/Input';
import { Label } from '@/components/Common/Label';
import { Switch } from '@/components/Common/Switch';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/Common/Tooltip';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/components/Common/Alert';
import { Badge } from '@/components/Common/Badge';
import { Separator } from '@/components/Common/Separator';
import { setupService } from '@/services/setupService';
import { useTeam } from '@/context/TeamContext';
import {toast} from '@/components/Common/Toast';

import ModelCard from './ModelCard';
import KeyInput from './KeyInput';

export enum ModelType {
  WaveQuery = 'wavequery',
  OpenAI = 'openai',
  Anthropic = 'anthropic',
  Gemini = 'gemini',
  Llama = 'llama',
}

interface Model {
  id: ModelType;
  name: string;
  icon: React.ElementType;
  description: string;
  features: string[];
  available: boolean;
  premium?: boolean;
  requiresApiKey?: boolean;
  docsUrl?: string;
}

const MODELS: Model[] = [
  {
    id: ModelType.WaveQuery,
    name: 'WaveQuery AI',
    icon: Bot,
    description: 'Our managed solution powered by latest LLM models',
    features: [
      'Optimized for data analysis',
      'No setup required',
      'Auto-scaling infrastructure',
      'Priority support',
    ],
    available: true,
    premium: true,
  },
  {
    id: ModelType.OpenAI,
    name: 'OpenAI',
    icon: SiOpenai,
    description: 'Use your own OpenAI API key',
    features: [
      'GPT-4 support',
      'Cost control',
      'Custom model selection',
      'Direct billing with OpenAI',
    ],
    available: true,
    requiresApiKey: true,
    docsUrl: 'https://platform.openai.com/docs/api-reference',
  },
  {
    id: ModelType.Anthropic,
    name: 'Anthropic Claude',
    icon: Bot,
    description: 'Advanced AI with enhanced reasoning',
    features: [
      'Long context windows',
      'Advanced reasoning',
      'Improved accuracy',
      'Ethical AI focus',
    ],
    available: false,
    requiresApiKey: true,
  },
  {
    id: ModelType.Gemini,
    name: 'Google Gemini',
    icon: Bot,
    description: 'Google\'s latest AI model',
    features: [
      'Multimodal capabilities',
      'Google Cloud integration',
      'Enterprise-grade security',
    ],
    available: false,
    requiresApiKey: true,
  },
];

const ModelSettings = () => {
  const { teamId, isPremiumUser = false } = useTeam();
  
  const [selectedModel, setSelectedModel] = useState<ModelType | null>(null);
  const [currentModel, setCurrentModel] = useState<ModelType | null>(null);
  const [apiKey, setApiKey] = useState('');
  const [isTestingKey, setIsTestingKey] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [autoUpdate, setAutoUpdate] = useState(true);

  useEffect(() => {
    // fetchCurrentSettings();
  }, []);

  const fetchCurrentSettings = async () => {
    try {
      const settings = await setupService.getModelSettings(teamId);
      setCurrentModel(settings.modelType);
      setSelectedModel(settings.modelType);
      setAutoUpdate(settings.autoUpdate);
    } catch (error) {
      toast({
        title: 'Error fetching settings',
        description: 'Failed to load current model settings.',
        variant: 'destructive',
      });
    }
  };

  const handleTestKey = async () => {
    setIsTestingKey(true);
    try {
      await setupService.testApiKey(teamId, {
        modelType: selectedModel!,
        apiKey,
      });
      toast({
        title: 'API Key Valid',
        description: 'Successfully verified your API key.',
        variant: 'success',
      });
    } catch (error) {
      toast({
        title: 'Invalid API Key',
        description: 'Failed to verify your API key. Please check and try again.',
        variant: 'destructive',
      });
    } finally {
      setIsTestingKey(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedModel) return;

    setIsSaving(true);
    try {
      await setupService.updateModelSettings(teamId, {
        modelType: selectedModel,
        apiKey: selectedModel === ModelType.WaveQuery ? undefined : apiKey,
        autoUpdate,
      });
      
      setCurrentModel(selectedModel);
      toast({
        title: 'Settings Updated',
        description: 'Model settings have been successfully updated.',
        variant: 'success',
      });
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: 'Failed to update model settings. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-semibold">Model Settings</h1>
          <p className="text-muted-foreground mt-1">
            Configure the AI model used for query generation
          </p>
        </div>
        {currentModel && (
          <Badge variant="outline" className="flex items-center gap-2">
            <Bot className="w-4 h-4" />
            Current: {MODELS.find(m => m.id === currentModel)?.name}
          </Badge>
        )}
      </div>

      <Separator />

      {/* Info Alert */}
      <Alert>
        <InfoIcon className="w-4 h-4" />
        <AlertTitle>Model Selection</AlertTitle>
        <AlertDescription>
          Choose between our managed solution or connect your own model provider.
          Some features may require a premium subscription.
        </AlertDescription>
      </Alert>

      {/* Models Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {MODELS.map((model) => (
          <ModelCard
            key={model.id}
            model={model}
            selected={selectedModel === model.id}
            current={currentModel === model.id}
            onSelect={() => {
              if (model.available && (!model.premium || isPremiumUser)) {
                setSelectedModel(model.id);
              }
            }}
            disabled={!model.available || (model.premium && !isPremiumUser)}
          />
        ))}
      </div>

      {/* Configuration Section */}
      <AnimatePresence mode="wait">
        {selectedModel && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
          >
            <Card>
              <CardHeader>
                <CardTitle>Configuration</CardTitle>
                <CardDescription>
                  Configure settings for {MODELS.find(m => m.id === selectedModel)?.name}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleSubmit} className="space-y-6">
                  {/* API Key Input */}
                  {selectedModel !== ModelType.WaveQuery && (
                    <div className="space-y-4">
                      <KeyInput
                        value={apiKey}
                        onChange={setApiKey}
                        onTest={handleTestKey}
                        isLoading={isTestingKey}
                      />
                      
                      {/* Docs Link */}
                      {MODELS.find(m => m.id === selectedModel)?.docsUrl && (
                        <div className="flex items-center gap-2 text-sm text-muted-foreground">
                          <ExternalLink className="w-4 h-4" />
                          <a 
                            href={MODELS.find(m => m.id === selectedModel)?.docsUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:underline"
                          >
                            View API documentation
                          </a>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Auto-update Toggle */}
                  <div className="flex items-center justify-between">
                    <div className="space-y-0.5">
                      <Label>Auto-update</Label>
                      <div className="text-sm text-muted-foreground">
                        Automatically update to the latest model version when available
                      </div>
                    </div>
                    <Switch
                      checked={autoUpdate}
                      onCheckedChange={setAutoUpdate}
                    />
                  </div>

                  {/* Action Buttons */}
                  <div className="flex justify-end gap-3">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setSelectedModel(currentModel);
                        setApiKey('');
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={
                        isSaving ||
                        (selectedModel !== ModelType.WaveQuery && !apiKey)
                      }
                    >
                      {isSaving && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
                      Save Changes
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ModelSettings;