import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { cn } from "../utils";


const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => {
  // Track the active state for enhanced visual feedback
  const [isActive, setIsActive] = React.useState(false);

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        "relative flex w-full touch-none select-none items-center",
        className
      )}
      onPointerDown={() => setIsActive(true)}
      onPointerUp={() => setIsActive(false)}
      onPointerLeave={() => setIsActive(false)}
      {...props}
    >
      {/* Track Background */}
      <SliderPrimitive.Track
        className={cn(
          "relative h-1.5 w-full grow overflow-hidden rounded-full bg-secondary",
          "transition-colors duration-100",
          isActive && "bg-secondary/80"
        )}
      >
        {/* Active Range Indicator */}
        <SliderPrimitive.Range className="absolute h-full bg-primary" />
      </SliderPrimitive.Track>

      {/* Render a thumb for each value */}
      {props.value?.map((_, index) => (
        <SliderPrimitive.Thumb
          key={index}
          className={cn(
            "block h-4 w-4 rounded-full border border-primary/50 bg-background shadow",
            "transition-colors focus-visible:outline-none focus-visible:ring-1",
            "focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
            "hover:border-primary hover:bg-background",
            isActive && "scale-110"
          )}
        />
      ))}
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };