import { useState, useEffect, useCallback } from "react";

type ThemeMode = "light" | "dark";

interface ThemeColors {
  primary: string;
  secondary: string;
  background: string;
  text: string;
}

interface ThemeConfig {
  mode: ThemeMode;
  colors: ThemeColors;
}

const defaultLightTheme: ThemeConfig = {
  mode: "light",
  colors: {
    primary: "blue-600",
    secondary: "purple-600",
    background: "white",
    text: "gray-900",
  },
};

const defaultDarkTheme: ThemeConfig = {
  mode: "dark",
  colors: {
    primary: "blue-400",
    secondary: "purple-400",
    background: "gray-900",
    text: "gray-100",
  },
};

export const useTheme = () => {
  const [theme, setTheme] = useState<ThemeConfig>(() => {
    const savedTheme = localStorage.getItem("theme-mode") as ThemeMode | null;
    return savedTheme === "dark" ? defaultDarkTheme : defaultLightTheme;
  });

  const applyTheme = useCallback((newTheme: ThemeConfig) => {
    const root = document.documentElement;
    Object.entries(newTheme.colors).forEach(([key, value]) => {
      root.style.setProperty(`--color-${key}`, `var(--tw-${value})`);
    });
    root.classList.toggle("dark", newTheme.mode === "dark");
  }, []);

  useEffect(() => {
    applyTheme(theme);
    localStorage.setItem("theme-mode", theme.mode);
  }, [theme, applyTheme]);

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) =>
      prevTheme.mode === "light" ? defaultDarkTheme : defaultLightTheme
    );
  }, []);

  const isDarkMode = theme.mode === "dark";
  return { theme, toggleTheme, isDarkMode };
};
